import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getAllBankList, getLeadProfile, updateBankDetails } from '../../../action/leadjourney';
import LeadSingleDocument from './LeadSingleDocument';
import sheildIcon from "../../../images/shield.svg"
import bankErrIcon from "../../../images/bankerr.png"

                    
const LeadBankDetails = ({bankDetails,getLeadApplicationDetail,handleSuccEroorToastMessage,bankStatementDoc,bankPassbookDoc,bankCancelCheckDoc,handleDocUpdate,getKycDocument,redirectScreen,setApiLoader,id}) => {
    const [leadBankDetailsData,setLeadBankDetailsData] = useState({
        showBankDropdown:false,
        bankSelected:"",
        bankList:[],
        selectedBank:{},
        bankName:"",
        ifsc:"",
        AccHolderName:"",
        accNumber:"",
        isValid: false,
        errorMsg: "",
        isValidAccount: false,
        Errmessage:"",
        showApiError:false,
        showDocUploadUI :false,
        kycDoc :"Bank Statement",
        apiCount:0,
    })

    useEffect(() => {
        const {accNumber,ifsc,bankName,AccHolderName} = bankDetails;
        setLeadBankDetailsData(prev => {return {...prev,
            ifsc,
            bankName,
            AccHolderName,
            accNumber,
            selectedBank:{value:bankName,label:bankName},
            isValid :ifsc ? true:false,
            isValidAccount :accNumber ? true:false
            }})

            getBankList();

            if(leadBankDetailsData.showDocUploadUI){
                getKycDocument();
              }
    },[])
    
    const getBankList = async () => {
      const response = await getAllBankList();
            if(response.status === "success"){
                 const data = response.data.map(ele => {return {...ele,value:ele.Name,label:ele.Name}})
                 setLeadBankDetailsData(prev => { return {...prev,bankList:data}})
            }else{
              setLeadBankDetailsData(prev => {return{...prev,bankList:[]}})
            }
        
    }
  
useEffect(() => {
if(leadBankDetailsData.showDocUploadUI){
    getKycDocument();
}
},[leadBankDetailsData.showDocUploadUI])

const bankChange = (val) => {
    setLeadBankDetailsData(prev => {return{...prev, bankName:val.value,ifsc: val.Bank_Code__c,selectedBank:val }});
  };

const handleIfscChange = async (event) => {
    event.persist();
    let ifsc = event.target.value;
    var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    if(ifsc.length < 4){
      return;
    }
    if (ifsc.length == 11) {
      if (ifsc.match(reg)) {
        setLeadBankDetailsData(prev => {return{...prev,
          isValid: true,
          errorMsg: "",
          ifsc: event.target.value,
        }});
      } else {
        setLeadBankDetailsData(prev => {return {...prev,
          isValid: false,
          errorMsg: "Enter valid ifsc code",
        }});
      }
    } else {
      setLeadBankDetailsData(prev => { return {...prev, isValid: false, errorMsg: "", ifsc: event.target.value }});
    }
  };

 const handleBankAccount = (e) => {
    setLeadBankDetailsData(prev => {return {...prev, accNumber: e.target.value }});
    var pattern = new RegExp(/^[a-zA-Z0-9]+$/);

    if (e.target.value !== "") {
      if (!pattern.test(e.target.value)) {
        // setLeadBankDetailsData({...leadBankDetailsData, accNumber: e.target.value });
        setLeadBankDetailsData(prev => {return {...prev, isValidAccount: false,Errmessage: "Please enter valid account number." }});
      } else if (e.target.value.length < 9) {
        setLeadBankDetailsData(prev => {return {...prev, isValidAccount: false,Errmessage: "Please enter valid account number." }});
      } else if (e.target.value.length >= 9) {
        setLeadBankDetailsData(prev => {return {...prev, isValidAccount: true, Errmessage: "" }});
      }
    } else {
      setLeadBankDetailsData(prev => {return {...prev, isValidAccount: true, Errmessage: "" }});
    }
  };

  const handleAccountName = (e) => {
      var hasNumber = /\d/;
      if (!hasNumber.test(e.target.value)) {
        setLeadBankDetailsData(prev => {return{...prev,AccHolderName: e.target.value,isErrorName: false, errorname: "" }});
      } else {
        setLeadBankDetailsData(prev => {return {...prev,
            AccHolderName:e.target.value,
          isErrorName: true,
          errorname: "Please enter valid name",
        }});
      }
  };

 const  updateBank = async(e) => {
    e.preventDefault();
//  if allready updated then 
// const {bankName,AccHolderName} = bankDetails;
// if(bankDetails.accNumber  && bankDetails.ifsc && bankName && AccHolderName ){
//      getLeadApplicationDetail();
// return
// }

    const {accNumber,ifsc} = leadBankDetailsData;
    const data = {
      account_no:accNumber,
      ifsc
    }
    setApiLoader(true)
   const response = await updateBankDetails(id,data);
   setApiLoader(false)
      if(response.status === "success"){
        setApiLoader(false);
        handleSuccEroorToastMessage({succ:response.message});
        setApiLoader(true);
        setTimeout(() => {
          handleRedirectScreenPolling(0)
        }, 2000);
      }else{
        // handleSuccEroorToastMessage({err:response.message})
        setLeadBankDetailsData({...leadBankDetailsData,showApiError:true})
      }
    
  }

const handleShowDocUi = () => {
    setLeadBankDetailsData({...leadBankDetailsData,
        showApiError:false,
    showDocUploadUI : true,
      }
    )
  }

 const handleShowNormalUi = () => {
    setLeadBankDetailsData(prev => {return {...prev,
      showApiError:false,
      showDocUploadUI : false,
    }})
  }

const  handleDocRadioBtn = (val) => {
    setLeadBankDetailsData(prev => {return{...leadBankDetailsData,kycDoc:val}})
  }

 const  handleRedirectScreenPolling = async (count) => {
     if(count === 4){
        // stage updation after 3 attempts
        setApiLoader(false);
     }else{
      let data = {
        leadId:id,
      };

     const response = await getLeadProfile(data);
        setApiLoader(true)
        if(response.status === "success"){
          const leadDetail = response?.data[0] ? response.data[0] : {};
          
          if(leadDetail.StageName==="Bank Account Details/ Penny Drop Pending"){
            setTimeout(() => {
              handleRedirectScreenPolling(count+1);
            }, 2000);
          }else{
            // setTimeout(() => {
            //   handleRedirectScreenPolling();
            // }, 2000);
            setApiLoader(false);
                redirectScreen({
        stageName:leadDetail.StageName,
        isQde2FormDone:leadDetail.Is_QDE_2_form_done__c,
        isPanPulled:leadDetail.PAN__c ? true : false,
      })
          }
        }else{
          setTimeout(() => {
            handleRedirectScreenPolling(count+1);
          }, 2000);
        }
      
     }
  }

  const isBankDetailsBtnDisable = () => {
   return (leadBankDetailsData.ifsc &&
        leadBankDetailsData.AccHolderName &&
        leadBankDetailsData.accNumber &&
        leadBankDetailsData.bankName &&
        leadBankDetailsData.isValid &&
        leadBankDetailsData.isValidAccount ) || (
          leadBankDetailsData.showDocUploadUI && 
          (bankStatementDoc.fileContent || bankStatementDoc.inputBase64) && bankStatementDoc.verified
        ) ? false : true
  }


  const {bankList,accNumber,AccHolderName,bankName,showApiError,showDocUploadUI,kycDoc} = leadBankDetailsData; 

  return (
    <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
    <div className="row mb-3">
          <h5 className="main-header">Bank  Account Details</h5>
        </div>
            <>
         { showApiError ?  
             <div className='row flex-column align-items-center'>
               <img src={bankErrIcon} className='my-3'/>
              <div className='my-1 fs-20 fw-600'>Unable to verify bank details!</div>
              <div className='fznormal font-family-regular text5252 mb-4'>Verification failed Try again</div>
              <div className='text-black fzsubsubheader font-family-regular marginb-2'>XXXXXXX{accNumber.slice(-5)}</div>
              <button className='lead-radio-btn mt-2' onClick={handleShowNormalUi}>Change Bank Details</button>
              <div className='mt-3 cur-pointer' onClick={handleShowDocUi}>Upload Bank Verification</div>
              <div className='mb-3 cur-pointer' onClick={handleShowDocUi}> Documents Manually</div>
           </div>
         
           : showDocUploadUI ?

             <div className='row'>
                <h3 className='col-md-12 mb-3 mt-2 sub-header'>Upload any of the below document</h3>
        <div className='col-md-12 d-flex flex-wrap'>
                        <div onClick={() => handleDocRadioBtn("Bank Statement")} className={kycDoc === "Bank Statement"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Bank Statement</div>
                        <div onClick={() => handleDocRadioBtn("Bank Passbook")} className={kycDoc === "Bank Passbook"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Bank Passbook</div>
                        <div onClick={() => handleDocRadioBtn("Cancelled Cheque")} className={kycDoc === "Cancelled Cheque"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Cancelled Cheque</div>
                     
                    </div>

       {kycDoc === "Bank Statement" &&  <div className='col-md-6 padding0 mt-3 mb-2'>
       < LeadSingleDocument 
                          id={id}
                          setApiLoader = {setApiLoader}
                         btnTitle = {"Upload Bank Statement"}
                         doc_category = {"Income Proof"}
                         doc_type={"Bank Statement"}
                         isPdf ={true}
                         accept={"pdf"}
                         base64 = {bankStatementDoc.fileContent ? bankStatementDoc.type + bankStatementDoc.fileContent : ""}
                         handleSuccEroorToastMessage = {handleSuccEroorToastMessage}
                         handleDocUpdate = {handleDocUpdate}
                        //  OpenDocModel = {OpenDocModel}
                       />
        </div>}

        {kycDoc === "Bank Passbook" &&  <div className='col-md-6 padding0 mt-3 mb-2'>
        < LeadSingleDocument 
                          id={id}
                          setApiLoader = {setApiLoader}
                         btnTitle = {"Upload Bank Passbook"}
                         doc_category = {"Income Proof"}
                         doc_type={"Bank Statement"}
                         isPdf ={true}
                         accept={"pdf"}
                         base64 = {bankStatementDoc.fileContent ? bankStatementDoc.type + bankStatementDoc.fileContent : ""}
                         handleSuccEroorToastMessage = {handleSuccEroorToastMessage}
                         handleDocUpdate = {handleDocUpdate}
                        //  OpenDocModel = {OpenDocModel}
                       />
        </div>}

        {kycDoc === "Cancelled Cheque" &&  <div className='col-md-6 padding0 mt-3 mb-2'>
        < LeadSingleDocument 
                          id={id}
                          setApiLoader = {setApiLoader}
                         btnTitle = {"Upload Cancelled Cheque"}
                         doc_category = {"Income Proof"}
                         doc_type={"Bank Statement"}
                         isPdf ={true}
                         accept={"pdf"}
                         base64 = {bankStatementDoc.fileContent ? bankStatementDoc.type + bankStatementDoc.fileContent : ""}
                         handleSuccEroorToastMessage = {handleSuccEroorToastMessage}
                         handleDocUpdate = {handleDocUpdate}
                        //  OpenDocModel = {OpenDocModel}
                       />

        </div>}
      
             </div>
          
           :
           <div className="row">
            

            <div className="col-md-12 form-group">
                <label className="form-label">Bank Name</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid #c2c2c2",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  name="brand"
                  className="lead-product-select"
                  placeholder="Select bank"
                    options={bankList}
                    onChange={bankChange}
                    value={leadBankDetailsData.selectedBank ? leadBankDetailsData.selectedBank : ""}
                />
              </div>


              
                <div className="col-md-6 form-group mb-3">
                  <label htmlFor="" className="form-label mb-1">
                    Account Number
                  </label>
                  <input
                    type="text"
                    className="form-control inputForAccount"
                    placeholder="Enter Bank Account Number"
                    onChange={handleBankAccount}
                    value={accNumber}
                    minLength="9"
                    maxLength="18"
                  />
                  {leadBankDetailsData.isValidAccount === false &&
                    leadBankDetailsData.accNumber?.length !== 0 ? (
                    <div className="form-group">
                      <div className="err-message" role="alert">
                        {leadBankDetailsData.Errmessage}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 form-group mb-3">
                  <label htmlFor="" className="form-label mb-1">
                    Account Holder Name
                  </label>
                  <input
                    type="text"
                    className="form-control inputForAccount"
                    placeholder="Enter Full Name"
                    value={AccHolderName}
                    onChange={handleAccountName}
                  />
                  {leadBankDetailsData.isErrorName &&
                    leadBankDetailsData.errorname != "" && (
                      <span className='err-message'>
                        {leadBankDetailsData.errorname}
                      </span>
                    )}
                </div>
                <div className="col-md-6 form-group clearfix">
                  <label htmlFor="" className="form-label mb-1">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    className="form-control inputForAccount"
                    placeholder="Enter Bank IFSC Code"
                    maxLength={"11"}
                    name="ifsc"
                    id="ifsc"
                    value={leadBankDetailsData.ifsc ? leadBankDetailsData.ifsc : ""}
                    onChange={handleIfscChange}
                  />
                  {leadBankDetailsData.isValid == false &&
                    leadBankDetailsData.errorMsg != "" && (
                      <span className='err-message'>
                        {leadBankDetailsData.errorMsg}
                      </span>
                    )}
                </div>
            
            </div>
           
           }
              
            <div className="row pt-5">
          <div className="col-md-7 d-flex align-items-center">
                
                  <img
                    src={sheildIcon}
                    alt="tick-mark"
                  />

                <p className="ml-2 fs-12">Re. 1 will be deposited in applicant’s bank account</p>
              
            </div>
              <div className="col-md-5 d-flex justify-content-end">
                {/* prev next arrow*/}
                {/* <button type='button' style={{ cursor: 'pointer' }} onClick={() => onNextBording(6)} className="btn btn-default"><i className="fas fa-arrow-left"></i></button>
                                      <button type="button" style={{ cursor: 'pointer' }} onClick={() => onNextBording(8)} className="btn btn-default"><i className="fas fa-arrow-right"></i></button> */}

                {/* <Button
                  onClick={() => onNextBording(5)}
                  variant="secondary"
                  className="btn cancel border-0"
                >
                  Previous
                </Button> */}
                 {/* <button
                  onClick={() => redirectScreen({stage:4})}
                  className='btn lead-basic-prev-btn-dis mr-2'
                >
                  Previous
                </button> */}
                <button
                  disabled={isBankDetailsBtnDisable()}
                  onClick={updateBank}
                  
                  className="lead-blue-btn"
                >
                  SAVE & NEXT
                </button>
              </div>
            </div>
          </>
    </form>
  )
}

export default LeadBankDetails