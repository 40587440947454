import React, { useEffect, useState } from 'react'
import UploadBankStatement from './UploadBankStatement'
import SendCaseCreditModal from '../../../modals/SendCaseCreditModal/SendCaseCreditModal';


const LeadHigherLimit = ({bankStatementDoc,getKycDocument,getLeadApplicationDetail,handleSuccEroorToastMessage,handleDocUpdate,id,setApiLoader}) => {
    const [showSendCaseToCreditModal,setShowSendCaseCreditToModal] = useState(false);
    useEffect(() => {
        getKycDocument();
    },[])

    const handleKycNext = (e) => {
      e.preventDefault();
        // this.props.redirectScreen({stage:4})
        getLeadApplicationDetail()
      }

      const isNextButtonDisable = () => {
        return (bankStatementDoc.fileContent || bankStatementDoc.inputBase64) && bankStatementDoc.verified ? false : true
      }

     const closeModal = () => {
      setShowSendCaseCreditToModal(false);
     } 

     const continueWithCurrentLimit = () => {
        setShowSendCaseCreditToModal(true);
        getLeadApplicationDetail();
     }
    
     const handleSendCaseToCredit = () => {
        //  handle send case to credit
        alert("popup clickd");
     }

  return (
    <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
      {showSendCaseToCreditModal && <SendCaseCreditModal
       closeModal={closeModal}
       continueWithCurrentLimit={continueWithCurrentLimit}
       limit={100000}
       handleSendCaseToCredit ={handleSendCaseToCredit}
      />}
        <div className="row flex-column">
          <h5 className="main-header">Get Financial Limit</h5>
          <p className='mb-3 fs-11'>Required PDF. Max file size 5 MB</p>
        </div>

                <div className="row"> 
                      <div className="col-md-12 form-group">
                          <h6 className="kyc-sub-head mt-3 fs-14">Bank Statement</h6>
                        </div>
                        <div className='col-md-6 mb-3'>
                      < UploadBankStatement
                         id={id}
                         setApiLoader = {setApiLoader}
                         btnTitle = {"Drop your files here or Browse"}
                         doc_category = {"Income Proof"}
                         doc_type={"Bank Statement"}
                        //  isPdf = {bankStatementDoc.FileType === "PDF" ? true :false}
                         isPdf ={true}
                         accept={"pdf"}
                         base64 = {bankStatementDoc.fileContent ? bankStatementDoc.type + bankStatementDoc.fileContent : ""}
                         handleSuccEroorToastMessage = {handleSuccEroorToastMessage}
                         handleDocUpdate = {handleDocUpdate}
                        //  OpenDocModel = {this.OpenDocModel}
                       />
                </div>
                    </div>
            
            
            <div className="row">
                      <div className="col-md-12 mb-2 d-flex justify-content-end">
              <button
                className="lead-blue-btn"
                disabled={isNextButtonDisable()}
                onClick={handleKycNext}
              >
                SAVE & NEXT
              </button>
            </div>

            </div>
       
        </form>
  )
}

export default LeadHigherLimit