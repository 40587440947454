import React, { useEffect, useRef, useState } from 'react'
import JSZip from 'jszip';
import { getKycDocumentUploadDetail, uploadKycDocument } from '../../../action/leadjourney';
import pdfIcon from "../../../images/pdf.png"
import uploadIcon from "../../../images/uploadpic.svg"
import deleteIcon from "../../../images/docDelete.png"

const UploadBankStatement = ({base64,btnTitle,doc_type,doc_category,isPdf,accept,handleSuccEroorToastMessage,handleDocUpdate,id,setApiLoader}) => {
 
   const [bankStatementData,setBankStatementData] = useState( {
    base64:"",
    apiCount:0,
    showUploadErr:false,
    file:"",
    fileNames:[]
}) 
const fileinput = useRef();

useEffect(() => {
    setBankStatementData(prev => {return {...prev,base64:base64}})
},[base64])

    const handleFileSelect = async(e) => {
        const file = e.target.files[0];
        setBankStatementData(prev => {return {...prev,file}})
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
        //   uploadProfileImage(type, reader.result);
        setBankStatementData(prev => {return {...prev,base64:reader.result}})
        handleDocUpdate({doc_type:doc_type,base64:reader.result})
        }.bind(this);
    
        if(file.type==="application/x-zip-compressed"){
        try {
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(file);
          const fileNames = Object.keys(zipContent.files);
          const data = fileNames.map(ele => {return {name:ele,extension:ele.split('.').pop(),passRequired:false,password:''}})
          setBankStatementData(prev => {return {...prev,fileNames:data}})
          } catch (error) {
            console.error('Error unzipping file:', error);
            handleSuccEroorToastMessage({err:'Error occured in unzipping file'});
            setBankStatementData(prev => {return {...prev,fileNames:[]}})
          }
        }else if(file.type === "application/pdf"){
           setBankStatementData(prev => {return{...prev,fileNames:[{name:file.name,extension:file.name.split('.').pop(),passRequired:false,password:''}]}});
        }else{
        handleSuccEroorToastMessage({err:"Please upload pdf or zip file"});
        setBankStatementData(prev => {return {...prev,fileNames:[]}})
        }
    
      }
    
   const uploadStatement  = async(e) => {
      e.preventDefault();
        let data = {
            doc_category:doc_category,
            doc_type:doc_type
          }
          
          const require = bankStatementData.fileNames.filter(ele => ele.passRequired);
         
          // lead_id
          let docId;
          let formdata = new FormData();
          formdata.append("merchant_id","001C300000Br3W9IAJ")
          formdata.append("files", bankStatementData.file);
          // formdata.append("file_info",JSON.stringify([data]));
          if(require.length > 0) {
           const pass = bankStatementData.fileNames.map(ele => ele.password).join(",");
           data = {...data,is_password_protected:true,password:pass}
             formdata.append("file_info",JSON.stringify([data]));
          }else{
             data = {...data,is_password_protected:false}
             formdata.append("file_info",JSON.stringify([data]));
          }
          setApiLoader(true);
          const response = await uploadKycDocument(formdata,id);
        
            if(response.status === "success"){
                 docId = response?.data[0]?.documentId || "";
            }else{
              setApiLoader(false)
              handleSuccEroorToastMessage({err:response.message});
              setBankStatementData(prev => {return {...prev,showUploadErr:true,base64:"",fileNames:[]}});
              // handleDocUpdate({uploadErr:true});
              
            }
        
           if(docId){
            setTimeout(() => {
                getDocUploadDetails(docId,0);
            },5000)
           }
    }
    

    const getDocUploadDetails = async (docId,count) => {
          if(count === 4){
            setApiLoader(false)
            handleSuccEroorToastMessage({err:"failed to verify document"})
            setBankStatementData(prev => {return {...prev,showUploadErr:true,base64:"",fileNames:[]}});
          }else{
            const response  = await getKycDocumentUploadDetail(id,docId)
              if(response.status === "success"){
                if(response?.data[0]?.Verified_by_Service__c){
                    setApiLoader(false)
                  handleSuccEroorToastMessage({succ:"Document uploaded successfully"});
                  setBankStatementData(prev => {return {...prev,showUploadErr:false,fileNames:[]}});
                  handleDocUpdate({doc_type:doc_type,base64:bankStatementData.base64,isVerified:true})
                }else{
                  if(response.data[0]?.API_Rejection_Reason__c){
                    setApiLoader(false)
                    handleSuccEroorToastMessage({err:response.data[0].API_Rejection_Reason__c})
                    setBankStatementData(prev => {return {...prev,showUploadErr:true,base64:"",fileNames:[]}});
                    return;
                  }
                  if(response?.data[0]?.Verified_by_Service__c === false){  
                 if(count === 4){
                    setApiLoader(false)
                    handleSuccEroorToastMessage({err:"failed to verify document"})
                    setBankStatementData(prev => {return {...prev,showUploadErr:true,base64:"",fileNames:[]}});
                    return;
                  }else{
                    setTimeout(() => {
                      getDocUploadDetails(docId,count+1);
                    }, 2000);
                  }
                  }
                }
              }
              else{
                if(response.message !== "No file corresponds to the provided document ID."){
                  getDocUploadDetails(docId,count+1);
                }else{
                  handleSuccEroorToastMessage({err:"failed to fetch document info"});
                  setBankStatementData(prev => {return {...prev,showUploadErr:true,base64:"",fileNames:[]}});
                  setApiLoader(false)
                }
              }
       
            // .catch(error => {
            // })
          }
       
      }
    
      
      const openPdf = (base64,documentName) => {
        //   dispatch(leadPdfStore(base64.replace("data:application/pdf;base64,",""),documentName));
        //   dispatch(openPreviewPdfModel());
        };
      
      const handleDeleteDoc = () => {
          fileinput.current.value="";
          setBankStatementData(prev => {return {...prev,base64:""}})
        }
    
      const handlePassChange = ({index,password,check}) => {
          
            const newArray = bankStatementData.fileNames.map((item, i) => {
                if (index === i) {
                    let el = {...item};
                    if(password || password === ""){
                      el = {...el,password}
                    }
                    if(check || check===false){
                        el = {...el,passRequired:check}
                    }
                  return el;
                } else {
                  return item;
                }
              });
    
          setBankStatementData(prev => {return {...prev,fileNames:newArray}})
        }
    
        const {fileNames} = bankStatementData;
      
            return (
             <>
             <div className="uploadimgcontainer" >
                            {bankStatementData.base64 &&<div className='doc-delete-icon' onClick={handleDeleteDoc}><img src={deleteIcon}/></div>}
                           {bankStatementData.showUploadErr && !bankStatementData.base64 && <div className='doc-error'>
                               <div className="" style={{textAlign:'end'}}>Failed</div>
                               <div className='err-red-line'></div>
                              </div>}
    
                            {!bankStatementData.base64 ? (
                              <button
                                type="button"
                                onClick={() => { fileinput.current.value="";fileinput.current.click()}}
                                className="btn docupload-btn"
                              >
                                 <img src={uploadIcon}/> <br/> 
                                {!bankStatementData.showUploadErr && <p className="mt-3">
                                  {btnTitle ? btnTitle : '--'}
                                </p>} 
                              </button>
                            ) : (
                             <div className='document-image-cont'>
                             { isPdf ? 
                              <img
                              onClick={() => openPdf(bankStatementData.base64,"Bank Statement")}
                              style={{ cursor: "pointer",height:"100px"}}
                              src={pdfIcon}
                              className='document-image'
                              alt=""
                            />  
                             :<img
                                src={bankStatementData.base64}
                                // style={{ height: "115px" }}
                                className='document-image'
                                // onClick={() => OpenDocModel()}
                              />}
                             </div>
    
                            )}
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept={accept === "pdf" ? "application/x-zip-compressed,application/pdf,zip":"image/x-png,image/gif,image/jpeg,image/jpg"}
                              ref={fileinput}
                              onChange={(e) =>
                                handleFileSelect(e)
                              }
                            //   onClick={(event) => {
                            //     event.target.value = null;
                            //   }}
                            />
                          </div>
    
             {fileNames.length > 0 && bankStatementData.base64 && <div className='file-pass-table-cont mt-4'>
                <table>
                    <thead>
                    <tr>
        <th>File Name</th>
        <th>File Type</th>
        <th>Password Required</th>
        <th>Password</th>
      </tr>
                    </thead>
                    <tbody>
                    {fileNames.map((ele,index) => {
                        return(
                            <tr>
                            <td style={{overflowY:'auto'}}>{ele.name}</td>
                            <td>{ele.extension}</td>
                             <td style={{textAlign:'-webkit-center'}}><input type='checkbox' value={ele.passRequired} checked={ele.passRequired} onChange={(e)=> handlePassChange({index,check:e.target.checked})}/></td>
                             <td><input disabled={ele.passRequired ? false : true} value={ele.password} onChange={(e)=> handlePassChange({index,password:e.target.value})} name="password" type='text' style={{border:'1px solid lightgray',padding:"4px 6px"}}/></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <button className='statement-pass-next-btn' onClick={uploadStatement}>Upload</button>
              </div>}
             </>
            )
        }
    
   
    
   





export default UploadBankStatement