import React, { useEffect, useRef, useState } from 'react'
import { getKycAadharDocumentUploadDetail, getKycDocumentUploadDetail, uploadKycDocument } from '../../../action/leadjourney';
import pdfIcon from "../../../images/pdf.png"
import uploadIcon from "../../../images/uploadpic.svg"
import deleteIcon from "../../../images/docDelete.png"


let interval;
const LeadSingleDocument = ({btnTitle,doc_category,doc_type,isPdf,isTif,base64,handleSuccEroorToastMessage,handleDocUpdate,OpenDocModel,setApiLoader,id,accept}) => {


  const [leadSingleDocumentData,setLeadSingleDocumentData] = useState({
    base64:"",
    apiCount:0,
    showUploadErr:false,
});

const fileinput = useRef();

useEffect(() => {
  setLeadSingleDocumentData(prev => {return {...prev,base64:base64}})
},[base64])

     
 const handleFileSelect = async(e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    let fileBase64 = ""
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
    //   uploadProfileImage(type, reader.result);
    setLeadSingleDocumentData(prev => {return {...prev,base64:reader.result}})
    handleDocUpdate({doc_type:doc_type,base64:reader.result})
    fileBase64 = reader.result;
    }.bind(this);

    const data = {
      doc_category:doc_category,
      doc_type:doc_type === "Aadhar Back" ? "Aadhar Front" : doc_type,
    }
    // lead_id
    let docId;
    var formdata = new FormData();
    formdata.append("merchant_id","001C300000Br3W9IAJ")
    formdata.append("files", file);
    formdata.append("file_info",JSON.stringify([data]));
    setApiLoader(true)
  const response = await uploadKycDocument(formdata,id);
      if(response.status === "success"){
           docId = response?.data[0]?.documentId || "";
      }else{
       
        setApiLoader(false)
        handleSuccEroorToastMessage({err:response.message});
        setLeadSingleDocumentData(prev => {return{...prev,showUploadErr:true,base64:"",apiCount:0}});
        // handleDocUpdate({uploadErr:true});
        
      }
  
     if(docId){
      setTimeout(() => {
        if(doc_type=== "Aadhar Front" || doc_type === "Aadhar Back"){
          getAdharDetails(0,fileBase64);
        }else{
          getDocUploadDetails(docId,0);
        }
      },5000)
     }
  }

  const getDocUploadDetails = async(docId,count) => {
     
      if(count === 4){
        setApiLoader(false);
        handleSuccEroorToastMessage({err:"failed to verify document"})
        setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
      }else{
        setLeadSingleDocumentData(prev => {return {...prev,apiCount:leadSingleDocumentData.apiCount+1}})
       const response = await getKycDocumentUploadDetail(id,docId);
          if(response.status === "success"){
            if(response?.data[0]?.Verified_by_Service__c){
              setApiLoader(false)
              handleSuccEroorToastMessage({succ:"Document uploaded successfully"});
              setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:false,apiCount:0}});
              handleDocUpdate({doc_type:doc_type,base64:leadSingleDocumentData.base64,isVerified:true})
            }else{
              if(response.data[0]?.API_Rejection_Reason__c){
                setApiLoader(false)
                handleSuccEroorToastMessage({err:response.data[0].API_Rejection_Reason__c})
                setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
                return;
              }
              if(response?.data[0]?.Verified_by_Service__c === false){  
             if(count === 4){
              setApiLoader(false)
                handleSuccEroorToastMessage({err:"failed to verify document"})
                setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
                return;
              }else{
                setTimeout(() => {
                  getDocUploadDetails(docId,count+1);
                }, 2000);
              }
              }
            }
          }
          else{
            if(response.message !== "No file corresponds to the provided document ID."){
              getDocUploadDetails(docId,count+1);
            }else{
              handleSuccEroorToastMessage({err:"failed to fetch document info"});
              setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
              setApiLoader(false)
            }
          }
      
        // .catch(error => {
       
        // })
      }
   
  }

  const getAdharDetails = async (count,fileBase64) => {
   
    if(count === 4){

      setApiLoader(false)
      handleSuccEroorToastMessage({err:"failed to verify document"})
      setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
    }else{
     const response = await getKycAadharDocumentUploadDetail(id,doc_type === "Aadhar Back" ? "Back" : doc_type);
        if(response.status === "success"){
          if(response?.data[0]?.Verified_by_Service__c){
            setApiLoader(false)
            handleSuccEroorToastMessage({succ:"Document uploaded successfully"});
            handleDocUpdate({doc_type:doc_type,base64:fileBase64,isVerified:true})
            setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:false,apiCount:0}});
          }else{
            if(response.data[0]?.API_Rejection_Reason__c){
              setApiLoader(false)
              handleSuccEroorToastMessage({err:response.data[0].API_Rejection_Reason__c})
              setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
              return;
            }
            if(response?.data[0]?.Verified_by_Service__c === false){
              if(count === 4){
                setApiLoader(false)
              handleSuccEroorToastMessage({err:"failed to verify document"})
              setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
              return;
              }else{
                setTimeout(() => {
                  getAdharDetails(count+1,fileBase64);
                }, 2000);
              }
            }
          }
        }else{
            handleSuccEroorToastMessage({err:"failed to verify document"});
            setLeadSingleDocumentData(prev => {return {...prev,showUploadErr:true,base64:"",apiCount:0}});
            setApiLoader(false)
          
        }
      // .catch(error => {

      // })
    }
 
}





    const openPdf = (base64,documentName) => {
      // dispatch(leadPdfStore(base64.replace("data:application/pdf;base64,",""),documentName));
      // dispatch(openPreviewPdfModel());
    };
  
    const handleDeleteDoc = () => {
      setLeadSingleDocumentData(prev => {return{...prev,base64:""}})
    }

    
    
  return (
         <div className="uploadimgcontainer">
                        {/* <label htmlFor="" className="form-label mb-2">
                          Front
                        </label> */}
                        {leadSingleDocumentData.base64 &&<div className='doc-delete-icon' onClick={handleDeleteDoc}><img src={deleteIcon}/></div>}
                       {leadSingleDocumentData.showUploadErr && !leadSingleDocumentData.base64 && <div className='doc-error'>
                           <div className="" style={{textAlign:'end'}}>Failed</div>
                           <div className='err-red-line'></div>
                          </div>}

                        {!leadSingleDocumentData.base64 ? (
                          <button
                            type="button"
                            onClick={() => { fileinput.current.value="";fileinput.current.click()}}
                            className="btn docupload-btn"
                          >
                             <img src={uploadIcon}/> <br/> 
                            {!leadSingleDocumentData.showUploadErr && <p className="mt-3">
                              {btnTitle ? btnTitle : '--'}
                            </p>} 
                          </button>
                        ) : (
                         <div className='document-image-cont'>
                         { isPdf ? 
                          <img
                          onClick={() => openPdf(leadSingleDocumentData.base64,"Bank Statement")}
                          style={{ cursor: "pointer",height:"100px"}}
                          src={pdfIcon}
                          className='document-image'
                          alt=""
                        /> 
                        : isTif ? 
                        <a
                        style={{ cursor: "pointer" }}
                         download={"Pan.tif"}
                         href={leadSingleDocumentData.base64}
                        className='document-tif'
                        alt="Pan"
                      >As Image type is tip click here to download</a> 
                         : <img
                            src={leadSingleDocumentData.base64}
                            // style={{ height: "115px" }}
                            className='document-image'
                            onClick={() => OpenDocModel()}
                          />}
                         </div>

                        )}
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept={accept === "pdf" ? "application/pdf":"image/x-png,image/gif,image/jpeg,image/jpg"}
                          ref={fileinput}
                          onChange={(e) =>
                            handleFileSelect(e)
                          }
                        //   onClick={(event) => {
                        //     event.target.value = null;
                        //   }}
                        />
                      </div>
  )
}

export default LeadSingleDocument