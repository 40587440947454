import { useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import CustmerSidebar from "../CustmerSidebar/CustmerSidebar";
import Navbar from "../Navbar/Navbar";
import './LoanDetails.css'
import { FiDownload } from "react-icons/fi";
import { IoIosEye } from "react-icons/io";

function LoanDetails() {
    const [apiLoader,setApiLoader] = useState(false);
    const [activeTab,setActiveTab] = useState("Details") 
    return (
     <>
      <Navbar />
      <div className="side-main-container light-background">
        <CustmerSidebar />

        <div className="main-content">
        
          {apiLoader && <ApiCallLoader />}
          <div className="p-4 w-100 min-h-100 mx-auto white-background">
            <div className="row ld-tab-cont">
                  <span className={activeTab === "Details" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Details")}>
                    Details
                  </span>
                  <span className={activeTab === "Payment Plan" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Payment Plan")}>
                    Payment Plan
                  </span>
                  <span className={activeTab === "Documents" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Documents")}>
                    Documents
                  </span>
                </div>
           { activeTab === "Details" &&
            <div className="row ldt-container" >
                <div className="col-md-4 ld-siglef">
                      <div>Loan Account Number</div>
                      <div>487584758738</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Loan Amount</div>
                      <div>5,00,000</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Amount Paid</div>
                      <div>20,000</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Remaining Balance</div>
                      <div>3,00,000</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Total Installments</div>
                      <div>4</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Remaining Installments</div>
                      <div>2</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>EMI Start Date</div>
                      <div>2</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>EMI End Date</div>
                      <div>2</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>EMI Frequency</div>
                      <div>2</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Next EMI Amount</div>
                      <div>2</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Next EMI Date</div>
                      <div>2</div>
                </div>
                
                </div>}
           { activeTab === "Payment Plan" && 
           <div className="row">
                <div className="col-md-8">
                <div className="col-md-12 lplans-cont">
                    <div className="lplan-item">
                            
                        <p>Loan Amount</p>
                        <p>1,00,000</p>
                    </div>
                    <div className="lplan-item">
                        <p>Start Date</p>
                        <p>01-01-2024</p>
                    </div>
                    <div className="lplan-item">
                        <p>End Date</p>
                        <p>06-06-2024</p>
                    </div>
                    <div className="lplan-item">
                        <p>Amount Paid</p>
                        <p>06-06-2024</p>
                    </div>
                      
                
                 </div>
                  <table className="loans-table">
                    <thead>
                        <th>Due Date</th>
                        <th>EMI Amount</th>
                        <th>Payment Date</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        <tr>
                        <td>29-02-2024</td>
                        <td>60,000</td>
                        <td>03-03-2024</td>
                        <td>Paid</td>
                        </tr>
                        <tr>
                        <td>29-02-2024</td>
                        <td>60,000</td>
                        <td>03-03-2024</td>
                        <td>Paid</td>
                        </tr>
                        <tr>
                        <td>29-02-2024</td>
                        <td>60,000</td>
                        <td>03-03-2024</td>
                        <td>Paid</td>
                        </tr>
                       
                       
                    </tbody>
                  </table>

                </div>
                <div className="col-md-3">
                   <div className="lpayment-box">

                   <div className="lp-instalment">
                    <span className="fs-11 d-block">Instalment</span>
                      <span className="fs-24 fw-600">8</span><span className="fs-14 fw-600 text-gray">/36</span>
                   </div>

                   <div className="lp-nextemi">
                      <div>Next EMI</div>
                      <span>₹ 40,500</span>
                   </div>

                   <div className="lp-schedule">
                      <div className="fs-12">Scheduled For</div>
                      <span className="fw-600">23/02/2024</span>
                   </div>

                   <div className="lp-buttons">
                     <button className="lp-fpay">Pay Full Amount</button>
                     <span className="d-block text-center">OR</span>
                     <input className="lp-cpinput" type="text" placeholder="Enter Amount Here" />
                     <button className="lp-cpay">Pay Custom Amount</button>
                   </div>


                   </div>
                </div>
                
                </div>}
           { activeTab === "Documents" && 
           <div className="row">
                <div className="col-md-8">
                <div className="ldoc-cont">
                    <div className="ld-docr">
                    <div>Available Document</div>
                    <div className="pright35">Actionable</div>
                    </div>


                    <div className="ld-docr">
                    <div className="fw-600">ForeClosure</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12 cur-pointer"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12 cur-pointer"><FiDownload /> Download</div>
                    </div>
                    </div>

                    <div className="ld-docr">
                    <div className="fw-600">No Due Letter</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12"><FiDownload /> Download</div>
                    </div>
                    </div>

                    <div className="ld-docr">
                    <div className="fw-600">Agreement</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12"><FiDownload /> Download</div>
                    </div>
                    </div>

                    <div className="ld-docr">
                    <div className="fw-600">Repayment Schedule</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12"><FiDownload /> Download</div>
                    </div>
                    </div>

                    <div className="ld-docr">
                    <div className="fw-600">SOA</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12"><FiDownload /> Download</div>
                    </div>
                    </div>

                    <div className="ld-docr">
                    <div className="fw-600">Interest Certificate</div>
                    <div className="d-flex gap-20">
                    <div className="d-flex align-items-center gap-3 fs-12"><IoIosEye /> View</div>
                    <div className="d-flex align-items-center gap-5 fs-12"><FiDownload /> Download</div>
                    </div>
                    </div>


                 </div>

                 

                </div> 
                </div>}





                </div>
                </div>
                </div>

    
    </>
    );
  }
  
  export default LoanDetails;
  