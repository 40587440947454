import { useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import CustmerSidebar from "../CustmerSidebar/CustmerSidebar";
import Navbar from "../Navbar/Navbar";
import './Loans.css'

function Loans() {
    const [apiLoader,setApiLoader] = useState(false);
    const [activeTab,setActiveTab] = useState("Active Loans"); 
    return (
     <>
      <Navbar />
      <div className="side-main-container light-background">
        <CustmerSidebar />

        <div className="main-content">
        
          {apiLoader && <ApiCallLoader />}
          <div className="p-4 w-100 min-h-100 mx-auto white-background">
            <div className="row">
                  <span className={activeTab === "Active Loans" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Active Loans")}>
                    Active Loans
                  </span>
                  <span className={activeTab === "Closed Loans" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Closed Loans")}>
                    Closed Loans
                  </span>
                </div>
            <div className="row">
                  <table className="loans-table">
                    <thead>
                       <tr>
                       <th>Application Id</th>
                        <th>Loan Amount</th>
                        <th>EMI Amount</th>
                        <th>EMI Due Date</th>
                       </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>123456</td>
                        <td>60,000</td>
                        <td>5,000</td>
                        <td>05.04.2024</td>
                        </tr>
                        <tr>
                        <td>123456</td>
                        <td>60,000</td>
                        <td>5,000</td>
                        <td>05.04.2024</td>
                        </tr>
                        <tr>
                        <td>123456</td>
                        <td>60,000</td>
                        <td>5,000</td>
                        <td>05.04.2024</td>
                        </tr>
                        <tr>
                        <td>123456</td>
                        <td>60,000</td>
                        <td>5,000</td>
                        <td>05.04.2024</td>
                        </tr>
                        <tr>
                        <td>123456</td>
                        <td>60,000</td>
                        <td>5,000</td>
                        <td>05.04.2024</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                </div>
                </div>
                </div>
    </>
    );
  }
  
  export default Loans;
  