import React, { useEffect, useState } from 'react'
import { panUpdate } from '../../../action/leadjourney';

const LeadVerifyPan = ({panNo,name,getLeadApplicationDetail,redirectScreen,AccountId,handleSuccEroorToastMessage,setApiLoader}) => {
    const [panNum,setPanNum] = useState("");
    const [isValidPan,setIsValidPan] = useState(false);
    const [errorMsg,setErrMessage] = useState("");

    useEffect(() => {
        // this.setState({panNum:this.props.panNo})
        setPanNum(panNo)
        setIsValidPan(panNo?true:false)
    },[panNo])


    const handlePanChange = (e) => {
        e.persist();
        var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/i;
        if (e.target.value !== "") {
          if (regex.test(e.target.value)) {
            setIsValidPan(true);
            setPanNum(e.target.value.toUpperCase());
            setErrMessage("");
          } else {
            setIsValidPan(false);
            setErrMessage("Enter valid pan number!");
            setPanNum(e.target.value.toUpperCase())
          }
        } else {
          setIsValidPan(true);
          setPanNum(e.target.value.toUpperCase())
          setErrMessage("")
        }
      };

    const updatePanStatus = async (e) => {
        e.preventDefault();
        if(panNum && isValidPan){
          const data = {
            application_id:AccountId,
            pan_no: panNum,
            first_name: name.firstname,
            last_name: name.lastname
        }
        setApiLoader(true);
         const response = await panUpdate(data);
         setApiLoader(false)
            if(response.status === "success"){
              // redirectScreen({stage:2});
              handleSuccEroorToastMessage({succ:response?.message})
              getLeadApplicationDetail();
            }else{
                 setIsValidPan(false);
                 setErrMessage(response?.message || "Enter valid pan number!");
                 handleSuccEroorToastMessage({err:response?.message || "Enter valid pan number!"})
            }
        }
      }




  return (
  
      <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
    <div className="row">
          <h5 className="main-header mb-3">Verify Pan</h5>
        </div>
          <div className="row">

          <div className="col-md-6 form-group">
                <label className="form-label">Pan Number</label>
                <input
                  type="text"
                  name="pan"
                  id="pan"
                  maxLength={"10"}
                  value={panNum}
                  onChange={handlePanChange}
                  className={errorMsg ? "err fs-18 fw-500": "fs-18 fw-500"}
                  placeholder="Enter Pan"
                  // onFocus={() => setCreateLeadData({...createLeadData, mobile: '+91' })}
                />
                {errorMsg && (
                  <div className="err-message">
                    {errorMsg}
                  </div>
                )}
              </div>
              <div className="col-md-12 mb-2 d-flex justify-content-end">
              {/* <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                          id="close-create"
                        >
                          Cancel
                        </button> */}
              <button
                className="lead-blue-btn"
                disabled={panNo.lenght < 10 || !isValidPan ? true : false}
                onClick={updatePanStatus}
              >
                SAVE & NEXT
              </button>
            </div>
          
                   
    </div>
    </form>
  )
}

export default LeadVerifyPan