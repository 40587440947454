import React, { useEffect, useState } from 'react'
import { getPaymentPlan, updatePaymentPlanDetails } from '../../../action/leadjourney'
import infoIcon from "../../../images/ppinfo.svg"
import selectedPlanIcon from "../../../images/selectedPlan.svg"

const LeadPaymentPlans = ({id,redirectScreen,getLeadApplicationDetail,handleSuccEroorToastMessage,AccountId,setApiLoader}) => {
    const [paymentPlansData,setPaymentPlansData] = useState({
        plans:[],
       selectedPlan:"",
       showNoPaymentPlanMessage:false,
       isPlanSelectedInitially:""
     })

useEffect(() => {
  getPlans();
},[])

 const getPlans = async () => {
    // const data = {
        //     trans_id:"006C3000009MHxtIAG",
        //     account_id:"001C300000Cw0aGIAR",
        // }
        // no payment plans
        const data = {
            // trans_id:"006C3000009WYB4IAO",
            trans_id:id,
            account_id:AccountId,
            // account_id:"001C300000D2aCnIAJ",
        }
          setApiLoader(true)
      const response = await  getPaymentPlan(id,AccountId);
       setApiLoader(false)
            if(response.status === "success"){
                setPaymentPlansData(prev => {return{...prev,plans:response.data?.payPlanList || [],showNoPaymentPlanMessage : response.data?.payPlanList?.length === 0 ? true:false}})
                if(response.data?.isPlanSelected){
                    setPaymentPlansData(prev => {return {...prev,
                        selectedPlan:response.data?.selectedIdSet[0] || "",
                        isPlanSelectedInitially:response.data?.selectedIdSet[0] || ""
                    }})
                }
            }else{
                setPaymentPlansData({...paymentPlansData,plans:[],showNoPaymentPlanMessage:true})
            }
       
 }
    
   const handleSelectPlan = (e,planId) => {
    e.preventDefault();
        setPaymentPlansData({...paymentPlansData,selectedPlan:planId})
    }

  const  updatePaymentPlan = async(e) => {
        e.preventDefault();
    //   if(paymentPlansData.isPlanSelectedInitially){
    //     redirectScreen({stage:8});
    //     return;
    //   }
        const data = {
            payment_plan_id:paymentPlansData.selectedPlan,
            trans_id:id
        }
         setApiLoader(true)
       const response =  await updatePaymentPlanDetails(data);
       setApiLoader(false)
            if(response.status === "success"){
                handleSuccEroorToastMessage({succ:response.message});
                getLeadApplicationDetail()
            }else{
                handleSuccEroorToastMessage({err:response.message})
            } 
    }
    
   

   
       

    const {plans,showNoPaymentPlanMessage} = paymentPlansData;      
    
    return (
        <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
      <div className="row mb-3">
            <h5 className="main-header">Choose Payment Plan</h5>
          </div>
            <>
         
             
                 <div className="row plans-container">
                {
                  plans?.length > 0  ?
                  plans.map((item, index) => (
                    <div className='col-md-4 mb-3'>
                    <div
                      className={`plan-box ${paymentPlansData.selectedPlan === item.Id ? "selected" : ""
                        } `}
                      key={`item-${index}`}
                    >
                      <div className="d-flex justify-content-between">
                      <h4 className="plansmonth fs-18 fw-600">{item.Net_Tenure__c ? item.Net_Tenure__c :"--" } Months
                        
                      </h4>
                      <div className="month mb-3 fs-18 fw-600"><span>₹</span>{" "}
                        {item.EMI_Amount__c || item.EMI_Amount__c === 0 ? item.EMI_Amount__c.toLocaleString('en-IN') : "--"}
                        <span>/Mo</span> </div>
                        </div>
                        <div className='hor-line'></div>
                      <div className="d-flex justify-content-between mt-3">
                          <div>
                          <p className="payemntsTitle">Processing Fee</p>
                          <h5 className="paymentsContent mt-1">
                            <span>₹</span>{" "}
                            {item.Processing_Fee__c || item.Processing_Fee__c === 0
                              ? item.Processing_Fee__c.toLocaleString('en-IN')
                              : "--"}
                          </h5>
                          </div>
                          <div>
                          <p className="payemntsTitle">Interest ({item.Fixed_Rate__c?item.Fixed_Rate__c.toFixed(2):"-"}% p.a)</p>
                          <h5 className="paymentsContent text-right mt-1">
                            <span>₹</span>{" "}
                            { item.Interest_During_Moratorium__c || item.Interest_During_Moratorium__c === 0 ? item.Interest_During_Moratorium__c.toLocaleString('en-IN') : "--"}
                          </h5>
                          </div>
                      </div>
                      <div className="d-flex justify-content-between mt-4">
                          <div>
                          <p className="payemntsTitle">Down Payment</p>
                          <h5 className="paymentsContent mt-1">
                            <span>₹</span>{" "}
                            {item.Down_Payment__c || item.Down_Payment__c === 0
                              ? item.Down_Payment__c.toLocaleString('en-IN')
                              : "--"}
                          </h5>
                          </div>
                          <div>
                          <p className="payemntsTitle">Total Repayment</p>
                          <h5 className="paymentsContent text-right mt-1">
                            <span>₹</span>{" "}
                            {item.Repayable_Amount__c || item.Repayable_Amount__c === 0 ? item.Repayable_Amount__c.toLocaleString('en-IN') : "--"}
                          </h5>
                          </div>
                      </div>
                      <div className="d-flex justify-content-between mt-4">
                          <div>
                          <p className="payemntsTitle">Due today</p>
                          <h5 className="paymentsContent text-right mt-1">
                            <span>₹</span>{" "}
                            {item.EMI_Amount__c || item.EMI_Amount__c === 0 
                              ? item.EMI_Amount__c.toLocaleString('en-IN')
                              : "--"}
                          </h5>
                          </div>
                          
                      </div>
                    
                      <div className="text-right mt-4">
                        <button className="d-inline-flex im_btn includeMoratorium fw-400">
                          Includes Moratorium{" "}
                          <span className="d-inline-block ml-1">
                            <img
                              src={infoIcon}
                              alt="icon-ind"
                              className="img-fluid"
                              width="12px"
                              height="12px"
                              style={{marginBottom:"-2px"}}
                            />
                          </span>
                        </button>
                      </div>
                      <div className="text-center mt-1">
                        {paymentPlansData.selectedPlan === item.Id ?<button className="d-inline-flex selectedBtn">
                          Selected Plan 
                          <img src={selectedPlanIcon}/>
                        </button>: <button onClick={(e) => handleSelectPlan(e,item.Id)} className="d-inline-flex im_btn selectBtn justify-content-center">
                          Select Now
                        </button>}
                       
                        
                      </div>
                      </div>
                    </div>))
                    : showNoPaymentPlanMessage &&
                    <div className=' col-sm-12 fznormal font-family-medium mt-1 mb-4 ml-1 text-center'>No payment Plans</div>
                }
              </div>
           
            <div className="row mt-4">
              <div className="col-sm-12 d-flex justify-content-end align-items-center">
                <button
                  onClick={updatePaymentPlan}
                  disabled={
                    paymentPlansData.selectedPlan ? false : true
                  }
                  className="lead-blue-btn"
                  
                >
                  SAVE & NEXT
                </button>
              </div>
            </div>
          </>
    </form>
  )
}

export default LeadPaymentPlans