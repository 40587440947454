import { useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import Navbar from "../Navbar/Navbar";

const Dashboard = () => {
    const [apiLoader,setApiLoader] = useState(false);
    return (
        <>
        <Navbar />
    <div className="side-main-container light-background">
      <div className="main-content">
        {apiLoader && <ApiCallLoader />}
        <div  className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
                    <div className="d-flex">
                    <div className="d-flex">
                       <img src="https://d1idiaqkpcnv43.cloudfront.net/website1.0/images/basic-det.png" height={50} alt="addnote" loading="lazy"/>
                       <div className="d-flex ml-3 flex-column">
                        <p className="start-newapp-text">Start a new application</p>
                        <p className="app-newapp-text"> Apply for a new loan application!</p>
                       </div>
                     </div>
                     <button className="">

                     </button>
                    </div>
                     </div>
          </div>
          </div>
        </>
    )
}

export default Dashboard;