import React, { useEffect, useState } from "react";
import "../../AgreementSignning/AgreementSignning.css";
import OtpInput from 'react-otp-input';
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { VerifyAgreementOtp, getUserStatus, sendAgreementOtp } from "../../../action/document";
import AgreeImg from '../../../images/agreeImg.jpg'
import {BsFillEyeFill,} from 'react-icons/bs'
import {BiSolidDownload} from 'react-icons/bi'
import { useNavigate } from "react-router-dom";

let interval;
const LeadAgreementFlow = ({redirectScreen,getLeadApplicationDetail,handleSuccEroorToastMessage,setApiLoader,id,limitId,apiLoader}) => {
 
      
  const [otp,setOtp] = useState("");
  const [isOtpSend,setIsOtpSend] = useState(false);
  const [resendStart,setResendStart] = useState("");
  const [pdfBase64,setPdfBase64] = useState("");
  const [err,setErr] = useState("")
  const [mobile,setMobile] = useState();
  const [userName,setUserName] = useState();
  const [redirectUrl,setRedirectUrl] = useState("");
  const [agreementsignSuccess,setAgreementsignSuccess] = useState(false);
  const [showResendOtpBtn,setShowResendOtpBtn] = useState(false);
  const [showPaymentDetails,setShowPaymentDetails] = useState("");
  const [paymentDetails,setPaymentDetails] = useState({
    advanceEmi:'',
    processingFee:'',
    downPayment:'',
    totalUpfrontAmount:'',
  });
  const [stage,setStage] = useState("");
  const [fileName,setFileName] = useState("");
  const [succMessBelowSignAgree,setSuccMessBelowSignAgree] = useState("");
   const navigate = useNavigate();

 
//  to get the initial stage and agrrement base64
  useEffect(() => {
    
    getStatus();
   }, [])
   
   const getStatus = async () => { 
    setApiLoader(true);
    const res = await getUserStatus(limitId,id);
    setApiLoader(false)
    if(res.status === "success"){
      setErr("")
      setPdfBase64(res.metadata.agreementBase64);
      setFileName(res?.metadata?.fileName || "");
     if(res.stage === "agreement_pending"){
       setStage("Agreement Signing");
     }
    }
    else{
      setErr(res.message)
      toast.error(res.message)
    }
 }


  // clear the interval when timer ends
  useEffect(()=>{
    if(resendStart === 0){
     clearInterval(interval);
    }
  },[resendStart])

  //  function execute when the enter key is hit
  const hadleEnterKeyDown = () => {

  }

  const handleAgreementSign = async (e) => {
    e.preventDefault();
    const sendData = {
      app_id:limitId,
      trans_id:id,
    }
    clearInterval(interval)
    setApiLoader(true);
    const res = await sendAgreementOtp(sendData);
    setApiLoader(false)
    if(res.status === "success"){
    toast.success(res.message)
    setIsOtpSend(true);
    setResendStart(59);
    setMobile(res.mobile_no);
    setUserName(res.customer_name)
    setShowResendOtpBtn(true)
    interval = setInterval(() => {
      setResendStart(prev =>prev === 0 ? 0 :prev-1)
   }, 1000);
  }else{
    toast.error(res.message);
    setErr(res.message)
  }
}

const handleverifyOtp = async(e) => {
  e.preventDefault();
  const sendData = {
    app_id:limitId,
    trans_id:id,
    otp:otp,
    mobile_no:mobile,
    agreement_base64:pdfBase64,
    customer_name:userName,
    fileName
    // client_ip_address:"111.154.31.247"
  }
    setApiLoader(true)
  const res = await VerifyAgreementOtp(sendData);
  setApiLoader(false)
  if(res.status === "success"){
    toast.success(res.message)
    clearInterval(interval);
    setPdfBase64(res.data.agreementBase64);
    setRedirectUrl(res.data.url)
    setAgreementsignSuccess(true);

    
    if(res.data.stage === "payment_pending"){
      setStage("Payment Collection");
      setShowPaymentDetails("short");
      setPaymentDetails({...paymentDetails,advanceEmi:res.data.advance_emi,
        processingFee:res.data.processing_fee,
        downPayment:res.data.downpayment,
        totalUpfrontAmount: res.data.total_upfront_payment,
      })
    }
    // sessionStorage.setItem("agreement",true);
    // navigate("/success")
  }else{
    toast.error(res.message);
    // setErr(res.message)
  }
}

const handleRedirectToPaymentCollection = async(e) => {
  e.preventDefault();
  // const res = await getPaymentUrl();
 
  setApiLoader(true);
  const res = await getUserStatus(limitId,id);
  setApiLoader(false)
  if(res.status === "success"){
    // setErr("")
    // setPdfBase64(res.metadata.agreementBase64)
   if(res.stage === "agreement_pending"){
     setStage("Agreement Signing");
   }else if(res.stage === "payment_pending"){
    setStage("Payment Collection");
    // setRedirectUrl(res.url)
    // setShowPaymentDetails("short");
    // setPaymentDetails({...paymentDetails,advanceEmi:res.metadata.advance_emi,
    //   processingFee:res.metadata.processing_fee,
    //   downPayment:res.metadata.downpayment,
    //   totalUpfrontAmount: res.metadata.total_upfront_payment,
    //   // totalUpfrontAmount: "77488",
    // })

    toast.success(`Redirecting to Payment Collection stage`);
    setTimeout(() => {
      window.location.href = res.url;
    }, 1000);
  
   }
   else if(res.stage && res.url){
     let localStage = ""
    if(res.stage === "nach_pending"){
      // setStage("Nach Register");
      localStage = "Nach Register";
    }
    // setRedirectUrl(res.url)
     toast(`Redirecting to ${localStage?localStage: res.stage} stage.`)
      setTimeout(() => {
        window.location.href = res.url;
      }, 1000);
   }
  }else{
    // setErr(res.message)
    toast.error(res.message)
  }
}

 
   
  return (
    <form onSubmit={(e) => {e.preventDefault()}} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb" >
      <div className="row mb-3">
            <h5 className="main-header">Sign Agreement</h5>
          </div>
     <>
     
      <div>
        <div className={showPaymentDetails === "short" ?"mainbody dividescreen min-500" : "mainbody"}>
        <div className="agreement-section">
          {/* for url  */}
        {/* <iframe src={file} height="500" width="700" title="Iframe Example" ></iframe> */}
        {/* for base64 */}
        {/* desktop view */}
       {(!apiLoader || pdfBase64 ) && <iframe src={"data:application/pdf;base64,"+pdfBase64} height="500" width="700" title="Iframe Example" ></iframe>}

         {/* Mobile view */}
         <div className="agree-cont">
             <img src={AgreeImg} className="agree-img"/>
             <div className="icons-cont">
            <a href={"data:application/pdf;base64,"+pdfBase64} target="_blank" className="agree-icons">
            <BsFillEyeFill size={32} color="#2596be"/>
            </a>
            <a href={"data:application/pdf;base64,"+pdfBase64} className="agree-icons">
             <BiSolidDownload size={32} color="#2596be"/>
            </a>
             </div>
         </div>

        {/* agreement is success then hide all sign and otp screen */}
         {agreementsignSuccess ?  
         <div className="sign-btn-cont">
             {
              showPaymentDetails === "short" && 
              <><h2 className='pd-header'>Payment Details</h2>
        <div className='payment-detail mb-3'>

  
        {paymentDetails?.advanceEmi && <div className='pd-key-value'>
    <span className='pd-key'>Advance EMI </span> 
    <span className='pd-value'>₹ {paymentDetails.advanceEmi}</span>
   </div>
}
  {paymentDetails?.processingFee  && <div className='pd-key-value'>
    <span className='pd-key'>Processing Fee</span> 
   <span className='pd-value'>₹ {paymentDetails.processingFee}</span>
   </div>}
   
  {paymentDetails?.downPayment && <div className='pd-key-value'>
    <span className='pd-key'>Down Payment</span>
    <span className='pd-value'>₹ {paymentDetails.downPayment}</span>
    </div>}

  {paymentDetails?.totalUpfrontAmount &&  <div className='pd-key-value'>
    <span className='pd-key'>Total Upfront Payment</span>
    <span className='pd-value'>₹ {paymentDetails.totalUpfrontAmount}</span>
    </div>
}
</div>
</>
}
         { succMessBelowSignAgree ? <h3 className="process-succ-mes">{succMessBelowSignAgree}</h3>  : <button 
         className={"lead-blue-btn fs-14 w-max-content"}
          onClick={handleRedirectToPaymentCollection}
          // disabled={ true : false }
          >Continue to {stage == "Nach Register" ? "NACH Registration" : stage }</button>}
          </div>
         
         :<div className="sign-btn-cont">
       { !showResendOtpBtn ?   <button 
         className={"lead-blue-btn fs-14 w-max-content"}
          onClick={handleAgreementSign}
          disabled={(resendStart > 0 && resendStart <=59 ) ? true : false }
          >Sign Agreement</button>
          :  
          <p className='resend resendotpsec' ><button className={`resendoTp ${(resendStart > 0 && resendStart <=59) ?"disable-resend":''}`} onClick={handleAgreementSign} disabled={(resendStart > 0 && resendStart <=59)  ? true: false}>Resend</button> OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>
          }
         {/* {resendStart > 0 && resendStart <=59 && <p className='resend'>Resend OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>} */}
          </div>}
         {agreementsignSuccess === false && isOtpSend && <div className="otp-cont">
                 <h4 className="otp-sent-text">Enter OTP</h4>
                 <div className="optverify">
                   <OTPInput
                     value={otp}
                     // onChange={(e) => this.setState({otp:e.target.value })}
                     onChange={(value) => setOtp(value)}
                     numInputs={6}
                     shouldAutoFocus
                     inputType="number"
                     renderSeparator={<span></span>}
                     renderInput={(props) => (
                       <input {...props} onKeyDown={hadleEnterKeyDown} />
                     )}
                   />
              
                 </div>
                
                 <button
                 disabled={otp.length < 6 ? true : false}
                 className={"lead-blue-btn fs-14 w-max-content"}
                   onClick={handleverifyOtp}
                   >
                 Verify OTP
               </button>
   
               </div>}

    </div>
        </div>
      </div>
    </>
 
 
   
  





      
       
    
      

          </form>
  )
}

export default LeadAgreementFlow