import React, { useState } from 'react'
import './SuccessKyc.css'
import Navbar from '../Navbar/Navbar'
import successLogo from '../../images/success.svg'
import { toast } from 'react-toastify'
import { getUserStatus } from '../../action/document'
import ApiCallLoader from '../ApiCallLoader/ApiCallLoader'

const SuccessKyc = ({message,showNextStageButton,rediectUrl,stage,setNachStatus,appId,transId,setStageMessage}) => {
  const [apiLoading,setApiLoading] = useState(false)
  let localStage = stage === "Nach Register" || "Payment Collection" ? "NACH Registration" : stage;

  const handleRedirectToNach = async() => {
    setApiLoading(true);
        let res = await getUserStatus(appId,transId);
        setApiLoading(false)
        if(res.status === "success"){
          // res.metadata.Status_NACH = "confirmed"
          if(res?.metadata?.Status_NACH === "confirmed"){
            setNachStatus("confirmed");
              return;
          }
          if(res.stage === "payment_pending" || res.stage === "nach_pending"){
            let local = res.stage === "payment_pending" ? "Payment Collection" : res.stage === "nach_pending" ? "NACH Registration" : res.stage;
            if(!res.url){
              toast.error("Failed to load url");
              return;
            }
            toast(`Redirecting to ${local} stage.`)
               setTimeout(() => {
                 window.location.href = res.url;
               }, 1000);

         }
         else if(res.stage === "Product Selection" || res.stage === "Select Plan" || res.stage === "KYC Verification" || res.stage === "Select lender"  || res.stage === "Lender's Approval Pending"){
           setStageMessage(`Application is on ${res.stage} stage`)
         }else{
              setStageMessage("Thank you for completing process successfully. ")
         }
         
        }else{
         //  setErr(res.message)
          toast.error(res.message);
        }

       }
       
    //    else if(stage && rediectUrl){
      
    //   toast(`Redirecting to ${localStage} stage.`)
    //            setTimeout(() => {
    //              window.location.href = rediectUrl;
    //            }, 1000);
    // }else{
    //   toast.error("Failed to load url");
    // }
        // }
  return (
    <>
    <Navbar />
    <div className='side-main-container'>
      {/* <Sidebar/> */}
     <div className="main-content">
     <>
     { apiLoading && <ApiCallLoader/>}
      {/* <Navbar/> */}
      <div className='kyc-success-cont'>
        <img src={successLogo} alt='success'/>
        <p>{message ? message :"OKYC done successfully and redirecting to VKYC"}</p>
       { showNextStageButton &&  
       <button 
       className={apiLoading ? "disable-btn verify-btn":"enable-btn verify-btn"}
        onClick={handleRedirectToNach}
        disabled ={apiLoading? true : false}
        // disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
        >Continue to {localStage}</button>}
    </div>
    </>
     </div>
    </div>
    </>
  )
}

export default SuccessKyc