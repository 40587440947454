import axios from "axios";
import { handleApiError } from "../utils/customMethods";
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_URL})


export const getAllProducts = async (merchantId) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getProductList/${merchantId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const createLeads = async (leadData) => {
    try {
     const {data} = await axiosInstance.post("merchant/createLead",leadData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getCreateLeadJobDetails = async (jobId) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getJobStatus/${jobId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getComapanyNamList = async () => {
    try {
     const {data} = await axiosInstance.get(`merchant/getCompanyNames?search_key=a`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getLeadProfile = async (getData) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getApplicationDetails/${getData.leadId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getLeadProfileDocuemnt = async (id) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getApplicationDetails/${id}/kyc`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const createTransactionApplication = async (transData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/createTransaction`,transData,{
        headers:{
                "Content-Type": "application/json",
                "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getAddressList = async (lead_id) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getApplicationDetails/${lead_id}/address`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getKycDocumentUploadDetail = async (lead_id,doc_id) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getApplicationDetails/${lead_id}/kyc/${doc_id}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getKycAadharDocumentUploadDetail = async (lead_id,doc__type) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getApplicationDetails/${lead_id}/getDocumentStatus?doc_type=${doc__type}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getAllBankList = async () => {
    try {
     const {data} = await axiosInstance.get(`merchant/getBankNames`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getPaymentPlan = async (trans_id,account_id) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getPaymentPlan/a1sC30000005CWoIAM?trans_id=${trans_id}&account_id=${account_id}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const sendOkycLink = async (leadData) => {
    try {
     const {data} = await axiosInstance.post("merchant/intiate",leadData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const sendVkycLink = async(accId,LimitId) => {
    try {
     const {data} = await axiosInstance.get(`merchant/vkycLink?account_id=${accId}&limit_app_id=${LimitId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getProducts = async() => {
    try {
     const {data} = await axiosInstance.get(`merchant/productList`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getBrands = async(prodId) => {
    try {
     const {data} = await axiosInstance.get(`merchant/brandList/${prodId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const getLocations = async(locData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/getLocations`,locData, {
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
    })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getProductOffering = async(prodOffData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/getProductOffering`,prodOffData, {
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
    })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getMerchants = async(prodOffData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/getMerchantList`,prodOffData, {
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
    })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const getBranchs = async(locId) => {
    try {
     const {data} = await axiosInstance.get(`merchant/getBranchList?parentId=${locId}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
    })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}



export const updateBankDetails = async (lead_id,leadData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/addBankDetails/${lead_id}`,leadData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const updateQdeDetails = async (lead_id,leadData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/updateQde2Details/${lead_id}`,leadData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const uploadKycDocument = async (formData,lead_id) => {
    try {
     const {data} = await axiosInstance.post(`merchant/uploadDocuments/${lead_id}/kyc`,formData,{
        headers:{
            "Content-Type": "multipart/form-data",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const updatePaymentPlanDetails = async (planData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/paymentPlan`,planData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const panUpdate = async (panData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/panProfile`,panData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const hardpullApi = async (hardpullData) => {
    try {
     const {data} = await axiosInstance.post(`merchant/intiateHardPull`,hardpullData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}

export const sendMobileVerificationLink = async (accId) => {
    try {
     const {data} = await axiosInstance.post(`merchant/phoneVerification/${accId}`,{},{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
export const sendEmailVerificationLink = async (accId) => {
    try {
     const {data} = await axiosInstance.post(`merchant/emailVerification/${accId}`,{},{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return handleApiError(error);
    }
}
