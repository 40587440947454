import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import ApiCallLoader from '../ApiCallLoader/ApiCallLoader'
import ProfessionalDetails from './SubComponents/ProfessionalDetails'
import { useNavigate, useParams } from 'react-router-dom'
import { createTransactionApplication, getLeadProfile, getLeadProfileDocuemnt, sendEmailVerificationLink, sendMobileVerificationLink, sendOkycLink, sendVkycLink } from '../../action/leadjourney'
import LeadBasicDetails from './SubComponents/LeadBasicDetails'
import LeadAddressVerification from './SubComponents/LeadAddressVerification'
import { toast } from 'react-toastify'
import LeadKycDocument from './SubComponents/LeadKycDocument'
import LeadBankDetails from './SubComponents/LeadBankDetails'
import LeadPaymentPlans from './SubComponents/LeadPaymentPlans'
import LeadAgreementFlow from './SubComponents/LeadAgreementFlow'
import LeadPaymentCollection from './SubComponents/LeadPaymentCollection'
import { getUserStatus } from '../../action/document'
import LeadVerifyPan from './SubComponents/LeadVerifyPan'
import LeadHigherLimit from './SubComponents/LeadHigherLimit'
import LeadAccountActive from './SubComponents/LeadAccountActive'
import { MdOutlineDoneOutline } from "react-icons/md";
import { Margin } from '@mui/icons-material'
import LeadVkycRedirect from './SubComponents/LeadVkycRedirect'


const initial = {
  onBoarding: null,
  leadDetail: "",
  username: "",
  firstname: '',
  lastname: '',
  stageName: "",
  product: "",
  sellingPrice: "",
  mobile: "",
  email: "",
  panNo: "",
  AccountId: "",
  basicDetails: { dob: "", postal_code: "", gender: "" },
  professionalDetails: { occupation: "", employer_name: "", monthly_income: "", employer_type: "", marital_status: "" },
  addressDetails: { country: "", postal_code: "", state: "", street: "", city: "", address_type: "", resident_type: "", rent_amount: "" },
  bankDetails: { accNumber: "", ifsc: "", bankName: "", AccHolderName: "", },
  photoDoc: {},
  panDoc: {},
  aadharDoc: {},
  aadharBackDoc: {},
  passportDoc: {},
  passportBackDoc: {},
  drivingLiDoc: {},
  drivingLiBackDoc: {},
  voterIdDoc: {},
  voterIdBackDoc: {},
  bankStatementDoc: {},
  bankPassbookDoc: {},
  bankCancelCheckDoc: {},
  leadSuccMessage: "",
  Errmessage: "",
  applicationErr: "",
  SanctionedLimit: "",
  isEmailVerified: "",
  isMobileVerified: "",
  isVkycDone: "",
}


const LeadJourney = () => {
  const [apiLoader, setApiLoader] = useState(false);
  const [leadData, setLeadData] = useState(initial);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getLeadApplicationDetail();
    }
  }, [id]);

  useEffect(() => {
    // setTimeout(() => {
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    // }, 1000);
  }, [])

  useEffect(() => {
    if (leadData.onBoarding === 4) {
      getKycDocument();
    }
  }, [leadData.onBoarding])


  const getLeadApplicationDetail = async ({ transId, failedTransaction, stage, vkycdone } = {}) => {
    let data = {
      leadId: transId ? transId : id,
    };
    setApiLoader(true);
    const response = await getLeadProfile(data)
    setApiLoader(false)
    if (response.status === "success") {
      const leadDetail = response?.data[0] ? response.data[0] : {};

      setLeadData(prev => {
        return {
          ...prev,
          leadDetail: leadDetail,
          username: leadDetail.Name ? leadDetail.Name : "",
          firstname: leadDetail.Applicant_s_First_Name__c ? leadDetail.Applicant_s_First_Name__c : "",
          lastname: leadDetail.Applicant_s_Last_Name__c ? leadDetail.Applicant_s_Last_Name__c : "",
          userstatus: "Approved",
          sfid: leadDetail.Id ? leadDetail.Id : "",
          stageName: leadDetail.StageName,
          product: leadDetail?.Merchant_Product__r?.Name ? leadDetail.Merchant_Product__r.Name : "",
          sellingPrice: leadDetail.Product_Selling_Price__c ? leadDetail.Product_Selling_Price__c : "",
          mobile: leadDetail.Mobile_Number__c ? leadDetail.Mobile_Number__c : "",
          email: leadDetail.Email__c ? leadDetail.Email__c : "",
          panNo: leadDetail.PAN__c ? leadDetail.PAN__c : "",
          SanctionedLimit: leadDetail.Sanctioned_Limit__c || leadDetail.Sanctioned_Limit__c === 0 ? leadDetail.Sanctioned_Limit__c : "",
          AccountId: leadDetail.AccountId ? leadDetail.AccountId : "",
          isEmailVerified: leadDetail?.Account?.Email_Verified1__c || leadDetail?.Account?.Email_Verified1__c === false ? leadDetail?.Account?.Email_Verified1__c : "",
          isMobileVerified: leadDetail?.Account?.Phone_Verification_Completed__c || leadDetail?.Account?.Phone_Verification_Completed__c === false ? leadDetail?.Account?.Phone_Verification_Completed__c : "",
          // isEmailVerified:true,
          // isEmailVerified:false,
          // isMobileVerified:true,
          isVkycDone: leadDetail?.Account?.Video_Verification_Done__c || leadDetail?.Account?.Video_Verification_Done__c === false ? leadDetail?.Account?.Video_Verification_Done__c : "",
          basicDetails: {
            ...leadData.basicDetails,
            dob: leadDetail?.Account?.Date_Of_Birth_Applicant__c ? leadDetail.Account.Date_Of_Birth_Applicant__c : "",
            postal_code: leadDetail?.Account?.BillingPostalCode ? leadDetail?.Account?.BillingPostalCode : "",
            gender: leadDetail?.Account?.Gender__c ? leadDetail?.Account?.Gender__c : "",
          },
          professionalDetails: {
            ...leadData.professionalDetails,
            monthly_income: leadDetail?.Account?.Monthly_Income__c ? String(leadDetail?.Account?.Monthly_Income__c) : "",
            occupation: leadDetail?.Account?.Occupation__c ? leadDetail.Account.Occupation__c : "",
            employer_name: leadDetail?.Account?.Employer_Name__c ? leadDetail.Account.Employer_Name__c : "",
            employer_type: leadDetail?.Account?.Employer_Type__c ? leadDetail.Account.Employer_Type__c : "",
            marital_status: leadDetail?.Account?.Marital_Status__c ? leadDetail.Account.Marital_Status__c : ""
          },
          addressDetails: {
            country: leadDetail?.Account?.BillingCountry ? leadDetail?.Account?.BillingCountry : "",
            postal_code: leadDetail?.Account?.BillingPostalCode ? leadDetail?.Account?.BillingPostalCode : "",
            state: leadDetail?.Account?.BillingState ? leadDetail?.Account?.BillingState : "",
            street: leadDetail?.Account?.BillingStreet ? leadDetail?.Account?.BillingStreet : "",
            city: leadDetail?.Account?.BillingCity ? leadDetail?.Account?.BillingCity : "",
            address_type: "New",
            resident_type: leadDetail?.Account?.Resident_Type__c === "Rented" || leadDetail?.Account?.Resident_Type__c === "Owned" ? leadDetail.Account.Resident_Type__c : "",
            rent_amount: leadDetail?.Account?.Rent_Amount__c ? String(leadDetail?.Account?.Rent_Amount__c) : "",
          },
          bankDetails: {
            accNumber: leadDetail.Account_Number__c ? leadDetail.Account_Number__c : "",
            ifsc: leadDetail.IFSC__c ? leadDetail.IFSC__c : "",
            bankName: leadDetail.Bank_Name__c ? leadDetail.Bank_Name__c : "",
            AccHolderName: leadDetail.Name ? leadDetail.Name : "",
          }
        }
      })

      let isQde1FormDone = true;
      if (!leadDetail.Account.Date_Of_Birth_Applicant__c || !leadDetail.Account.Gender__c || !leadDetail.Account.BillingPostalCode) {
        isQde1FormDone = false;
      }

      redirectScreen({
        stage: stage ? stage : "",
        stageName: leadDetail.StageName,
        isQde2FormDone: leadDetail.Is_QDE_2_form_done__c,
        isPanPulled: leadDetail.PAN__c ? true : false,
        isQde1FormDone,
        failedTransaction,
        limitId: leadDetail.Account.Application__c,
        AccountId: leadDetail?.AccountId || "",
        isVkycDone: vkycdone ? vkycdone : (leadDetail?.Account?.Video_Verification_Done__c || leadDetail?.Account?.Video_Verification_Done__c === false) ? leadDetail?.Account?.Video_Verification_Done__c : "",
      })

      // redirectScreen({
      //   stage:5,
      //   stageName:"Pre-Account Activation",
      //   isQde2FormDone:false,
      //   isPanPulled:true,
      //   isQde1FormDone:true,
      //   isVkycDone:false
      // })


    } else {
      setLeadData(prev => {
        return { ...prev, applicationErr: response.message }
      })
    }
  }

  const getKycDocument = async () => {
    setApiLoader(true)
    const response = await getLeadProfileDocuemnt(id);
    setApiLoader(false)
    if (response.status === "success") {
      const files = response?.data[0]?.files ? response.data[0].files : [];
      if (files.length > 0) {
        files.forEach(ele => {
          if (ele.Verified_by_Service__c) {
            if (ele.Document_Category__c === "Photo" && ele.Document_Type__c === "Photo") {
              const type = getDocTypeString(ele.FileType)
              setLeadData(prev => { return { ...prev, photoDoc: { ...ele, type, verified: true } } })
            } else if (ele.Document_Category__c === "PAN" && (ele.Document_Type__c === "PAN" || ele.Document_Type__c === "FRONT_BOTTOM")) {
              const type = getDocTypeString(ele.FileType)
              setLeadData(prev => { return { ...prev, panDoc: { ...ele, type, verified: true } } })
            } else if (ele.Document_Category__c === "ID Proof" && (ele.Document_Type__c === "FRONT_BOTTOM" || ele.Document_Type__c === "Aadhar Front")) {
              const type = getDocTypeString(ele.FileType)
              setLeadData(prev => { return { ...prev, aadharDoc: { ...ele, type, verified: true } } })
            } else if (ele.Document_Category__c === "ID Proof" && (ele.Document_Type__c === "BACK" || ele.Document_Type__c === "Aadhar Back")) {
              const type = getDocTypeString(ele.FileType)
              setLeadData(prev => { return { ...prev, aadharBackDoc: { ...ele, type, verified: true } } })
            } else if (ele.Document_Category__c === "Income Proof" && ele.Document_Type__c === "Bank Statement") {
              const type = getDocTypeString(ele.FileType)
              setLeadData(prev => { return { ...prev, bankStatementDoc: { ...ele, type, verified: true } } })
            }
          }
        })
      }

    } else {
      // handle error 
    }
  }

  const getDocTypeString = (type) => {
    let ty = type.toLowerCase();
    return `data:application/${ty};base64,`
  }

  const redirectScreen = ({ stage, stageName, isQde2FormDone, isPanPulled, isQde1FormDone, failedTransaction, limitId, AccountId, isVkycDone }) => {
    setLeadData(prev => { return { ...prev, stageName } })
    if (stage || stage === 0) {
      setLeadData(prev => { return { ...prev, onBoarding: stage } })
    } else {
      if (!isPanPulled) {
        setLeadData(prev => { return { ...prev, onBoarding: 0 } })
      }
      // else if(!isQde1FormDone){
      // setLeadData(prev => { return {...prev,onBoarding:1}})
      // }
      else if (stageName === "KYC Pending") {
        if (!isQde2FormDone) {
          setLeadData(prev => { return { ...prev, onBoarding: 2 } })
        }
        else if(isVkycDone === false || !isVkycDone){
          // sendVkycScreen(id);
          setLeadData(prev => { return { ...prev, onBoarding: 10 } })
        } 
        else {
          setLeadData(prev => { return { ...prev, onBoarding: 4 } })
        }
      }
      else if (stageName === "Application Pending") {
        setLeadData(prev => { return { ...prev, onBoarding: 1 } })
      }
      else if (stageName === "Bank Statement Pending") {
        setLeadData(prev => { return { ...prev, onBoarding: 7 } })
      }
      else if (stageName === "Bank Account Details/ Penny Drop Pending") {
        setLeadData(prev => { return { ...prev, onBoarding: 5 } })
      }
      else if (stageName === "Account Active") {
        // if(failedTransaction){
        //   setLeadData(prev => { return {...prev,onBoarding:null, showStageNameList:true}})
        // }else{
        //   initiateTransactionFlow(AccountId)
        // }
        setLeadData(prev => { return { ...prev, onBoarding: 11 } })
      }
      else if (stageName === "Select Plan") {
        setLeadData(prev => { return { ...prev, onBoarding: 6 } })
      }
      else if (stageName === "Agreement generation/Signoff") {
        setLeadData(prev => { return { ...prev, onBoarding: 8 } })
        // navigate(`/cis/${limitId}/${id}`)
      }
      else if (stageName === "Payment Collection") {
        setLeadData(prev => { return { ...prev, onBoarding: 9 } })
        // navigate(`/cis/${limitId}/${id}`)
      }
      else if (stageName === "NACH Registration") {
        // setLeadData(prev => { return {...prev,onBoarding:10}})
        // handle 
        redirectToNachScreen();
        // navigate(`/cis/${limitId}/${id}`);
      }

      else {
        setLeadData(prev => { return { ...prev, onBoarding: null, showStageNameList: true } })
      }
    }

    // setLeadData(prev => { return { ...prev, onBoarding: null,stageName:null } })
  }

  const redirectToNachScreen = async (limitId) => {
    setApiLoader(true);
    const res = await getUserStatus(limitId, id);
    setApiLoader(false)
    if (res.status === "success") {
      if (res.stage && res.url) {
        let localStage = ""
        if (res.stage === "nach_pending") {
          localStage = "Nach Register";
        }
        toast(`Redirecting to ${localStage} stage.`)
        setTimeout(() => {
          window.location.href = res.url;
        }, 1000);
      } else {
        toast.error("Failed to fetch url.")
      }
    } else {
      toast.error(res.message)
    }
  }

  const handleQdeFormDetail = ({ basicDetails, professionalDetails, addressDetails }) => {
    if (basicDetails) {
      setLeadData(prev => { return { ...prev, basicDetails } })
    }
    if (professionalDetails) {
      setLeadData(prev => { return { ...prev, professionalDetails } })
    }
    if (addressDetails) {
      setLeadData(prev => { return { ...prev, addressDetails } })
    }
  }

  const handleSuccEroorToastMessage = ({ succ, err }) => {
    if (succ) {
      // setLeadData(prev => {return {...prev,leadSuccMessage:succ}})
      // setTimeout(() => {
      //   setLeadData(prev => {return {...prev,leadSuccMessage:""}})
      // }, 5000);
      toast.success(succ)
    }
    if (err) {
      // setLeadData(prev => {return {...prev,Errmessage:err}})
      // setTimeout(() => {
      //   setLeadData(prev => {return {...prev,Errmessage:""}})
      // }, 5000);
      toast.error(err)
    }
  }

  const handleDocUpdate = ({ doc_type, base64, isVerified, uploadErr }) => {
    const verified = isVerified ? isVerified : false;
    // const {photoDoc,panDoc,aadharDoc,aadharBackDoc,passportDoc,passportBackDoc,drivingLiDoc,drivingLiBackDoc,voterIdDoc,voterIdBackDoc,bankStatementDoc} = leadData;
    if (doc_type === "Photo") {
      setLeadData(prev => { return { ...prev, photoDoc: { ...leadData.photoDoc, inputBase64: base64, verified, uploadErr } } })
    } else if (doc_type === "PAN") {
      // setLeadData(prev => {return {...prev,panDoc:{...leadData.panDoc,inputBase64:base64,verified,uploadErr}})
      setLeadData(prev => { return { ...prev, panDoc: { ...leadData.panDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Aadhar Front") {
      setLeadData(prev => { return { ...prev, aadharDoc: { ...prev.aadharDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Aadhar Back") {
      setLeadData(prev => { return { ...prev, aadharBackDoc: { ...prev.aadharBackDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Passport Front") {
      setLeadData(prev => { return { ...prev, passportDoc: { ...leadData.passportDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Passport Back") {
      setLeadData(prev => { return { ...prev, passportBackDoc: { ...leadData.passportBackDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Driving Licence") {
      setLeadData(prev => { return { ...prev, drivingLiDoc: { ...leadData.drivingLiDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "DL Back") {
      setLeadData(prev => { return { ...prev, drivingLiBackDoc: { ...leadData.drivingLiBackDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Voter Id Front") {
      setLeadData(prev => { return { ...prev, voterIdDoc: { ...leadData.voterIdDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Voter Id Back") {
      setLeadData(prev => { return { ...prev, voterIdBackDoc: { ...leadData.voterIdBackDoc, inputBase64: base64, verified, uploadErr } } })
    }
    else if (doc_type === "Bank Statement") {
      setLeadData(prev => { return { ...prev, bankStatementDoc: { ...leadData.bankStatementDoc, inputBase64: base64, verified, uploadErr } } })
    }

  }

  const sendLink = async (stage) => {
    const { username, mobile, email, leadDetail } = leadData;
    let data = {
      full_name: username,
      phone: mobile,
      email: email,
      stage: stage,
    }
    if (stage === "agreement") {
      data = { ...data, transaction_application_id: id, limit_application_id: leadDetail.Account.Application__c }
    } else {
      data = { ...data, limit_application_id: id }
    }

    setApiLoader(true);
    const response = await sendOkycLink(data);
    setApiLoader(false)
    if (response.status === "success") {
      if (stage === "okyc" || stage === "vkyc") {
        // if(stage === "vkyc"){
        //   // window.open("https://link-kyc.idv.hyperverge.co/?identifier=e4744e1f-8142-4067-856d-35bcf3208734_0339_13", '_blank');
        //   window.location.href = "https://link-kyc.idv.hyperverge.co/?identifier=e4744e1f-8142-4067-856d-35bcf3208734_0339_13"

        // }else{
        window.open(response.data.link, '_blank');
        // }
        // window.open(response.data.link, '_blank');
      } else {
        handleSuccEroorToastMessage({ succ: response.message })
      }
    } else {
      handleSuccEroorToastMessage({ err: response.message })
    }
  }


  const sendVkycScreen = async () => {
    setApiLoader(true);
    const response = await sendVkycLink(leadData.AccountId,id);
    setApiLoader(false)
    if (response.status === "success") {
      window.location.href = response.url;
    } else {
      handleSuccEroorToastMessage({ succ: response.message })
    }
  }

  const initiateTransactionFlow = async (transData) => {
    setApiLoader(true)
    const response = await createTransactionApplication(id);
    //  const response = await createTransactionApplication("001Ff00000AYvKfIAL");
    setApiLoader(false);
    if (response.status === "success") {
      const transactionId = response.data.id;
      if (transactionId) {
        getLeadApplicationDetail({ transId: transactionId });
        navigate(`/lead-journey/${transactionId}`)
      } else {
        getLeadApplicationDetail({ failedTransaction: true });
      }
    } else {
      getLeadApplicationDetail({ failedTransaction: true });
    }
  }


  const handleEmailVerify  = async() => {
    if(leadData.isEmailVerified){
      return;
    }
    setApiLoader(true);
      const res = await sendEmailVerificationLink(leadData.AccountId);
      setApiLoader(false);
      if(res.status === "success"){
       handleSuccEroorToastMessage({succ:res.message})
      }else{
        // handle error here
      }
  }


  const handleMobileVerify  = async() => {
    if(leadData.isMobileVerified){
      return;
    }
    setApiLoader(true);
    const res = await sendMobileVerificationLink(leadData.AccountId);
    setApiLoader(false);
    if(res.status === "success"){
     handleSuccEroorToastMessage({succ:res.message})
    }else{
      // handle error here
    }
  }


  return (
    <>
      <Navbar />
      <div className="side-main-container light-background">
        <Sidebar
          stage={leadData.stageName}
          isVkycDone={leadData.isVkycDone}
          // isMobileVerified={true}
          // isEmailVerified={true}
          isMobileVerified={leadData.isMobileVerified}
          isEmailVerified={leadData.isEmailVerified}
          onBoarding={leadData.onBoarding}
        />

        <div className="main-content">
          {/* Modal content*/}
          {apiLoader && <ApiCallLoader />}


          {
            leadData.onBoarding == 0 ? (
              <LeadVerifyPan
                apiLoader={apiLoader}
                setApiLoader={setApiLoader}
                redirectScreen={redirectScreen}
                panNo={leadData.panNo}
                getLeadApplicationDetail={getLeadApplicationDetail}
                name={{ firstname: leadData.firstname, lastname: leadData.lastname }}
                AccountId={leadData.AccountId}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
              />
            ) :
              leadData.onBoarding == 1 ? (
                <LeadBasicDetails
                  redirectScreen={redirectScreen}
                  handleQdeFormDetail={handleQdeFormDetail}
                  basicDetails={leadData.basicDetails}
                  getLeadApplicationDetail={getLeadApplicationDetail}
                  panNo={leadData.panNo}
                  AccountId={leadData.AccountId}
                  handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                  apiLoader={apiLoader}
                  setApiLoader={setApiLoader}
                />
              ) : leadData.onBoarding == 2 ? (
                <ProfessionalDetails
                  apiLoader={apiLoader}
                  setApiLoader={setApiLoader}
                  professionalDetails={leadData.professionalDetails}
                  redirectScreen={redirectScreen}
                  handleQdeFormDetail={handleQdeFormDetail}
                  getLeadApplicationDetail={getLeadApplicationDetail}
                  name={{ firstname: leadData.firstname, lastname: leadData.lastname }}
                />
              ) : leadData.onBoarding == 3 ? (
                < LeadAddressVerification
                  setApiLoader={setApiLoader}
                  redirectScreen={redirectScreen}
                  basicDetails={leadData.basicDetails}
                  professionalDetails={leadData.professionalDetails}
                  addressDetails={leadData.addressDetails}
                  handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                  getLeadApplicationDetail={getLeadApplicationDetail}
                  isQde2FormDone={leadData.leadDetail.Is_QDE_2_form_done__c}
                  id={id}
                />
              )
                : leadData.onBoarding == 4 ? (
                  < LeadKycDocument
                    id={id}
                    setApiLoader={setApiLoader}
                    redirectScreen={redirectScreen}
                    photoDoc={leadData.photoDoc}
                    panDoc={leadData.panDoc}
                    aadharDoc={leadData.aadharDoc}
                    aadharBackDoc={leadData.aadharBackDoc}
                    passportDoc={leadData.passportDoc}
                    passportBackDoc={leadData.passportBackDoc}
                    drivingLiDoc={leadData.drivingLiDoc}
                    drivingLiBackDoc={leadData.drivingLiBackDoc}
                    voterIdDoc={leadData.voterIdDoc}
                    voterIdBackDoc={leadData.voterIdBackDoc}
                    bankStatementDoc={leadData.bankStatementDoc}
                    handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                    handleDocUpdate={handleDocUpdate}
                    getLeadApplicationDetail={getLeadApplicationDetail}
                    sendLink={sendLink}
                    sendVkycScreen={sendVkycScreen}
                  />

                )

                  : leadData.onBoarding == 5 ? (
                    <>
                      <LeadBankDetails
                        id={id}
                        setApiLoader={setApiLoader}
                        redirectScreen={redirectScreen}
                        bankDetails={leadData.bankDetails}
                        getLeadApplicationDetail={getLeadApplicationDetail}
                        handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                        bankStatementDoc={leadData.bankStatementDoc}
                        bankPassbookDoc={leadData.bankPassbookDoc}
                        bankCancelCheckDoc={leadData.bankCancelCheckDoc}
                        handleDocUpdate={handleDocUpdate}
                        getKycDocument={getKycDocument}
                      />
                    </>
                  )

                    : leadData.onBoarding == 6 ? (
                      <>
                        <LeadPaymentPlans
                          id={id}
                          setApiLoader={setApiLoader}
                          redirectScreen={redirectScreen}
                          getLeadApplicationDetail={getLeadApplicationDetail}
                          handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                          AccountId={leadData.AccountId}
                        />
                      </>
                    )
                      : leadData.onBoarding == 7 ? (
                        <>
                          < LeadHigherLimit
                            id={id}
                            setApiLoader={setApiLoader}
                            bankStatementDoc={leadData.bankStatementDoc}
                            redirectScreen={redirectScreen}
                            getLeadApplicationDetail={getLeadApplicationDetail}
                            handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                            handleDocUpdate={handleDocUpdate}
                            getKycDocument={getKycDocument}
                          />
                        </>)


                        : leadData.onBoarding == 8 ? (
                          <>
                            < LeadAgreementFlow
                              id={id}
                              setApiLoader={setApiLoader}
                              apiLoader={apiLoader}
                              limitId={leadData?.leadDetail?.Account?.Application__c || ""}
                              redirectScreen={redirectScreen}
                              getLeadApplicationDetail={getLeadApplicationDetail}
                              handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                              sendLink={sendLink}
                            />
                          </>)

                          : leadData.onBoarding == 9 ? (
                            <>
                              < LeadPaymentCollection
                                id={id}
                                setApiLoader={setApiLoader}
                                limitId={leadData?.leadDetail?.Account?.Application__c || ""}
                                redirectScreen={redirectScreen}
                                getLeadApplicationDetail={getLeadApplicationDetail}
                                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                                sendLink={sendLink}
                              />
                            </>)

                          : leadData.onBoarding == 10 ? (
                            <>
                            < LeadVkycRedirect
                                id={id}
                                AccountId={leadData.AccountId}
                                setApiLoader={setApiLoader}
                                limitId={leadData?.leadDetail?.Account?.Application__c || ""}
                                redirectScreen={redirectScreen}
                                getLeadApplicationDetail={getLeadApplicationDetail}
                                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                              />
                              
                            </>)

                            : leadData.onBoarding == 11 ? (
                              <>
                                < LeadAccountActive
                                  id={id}
                                  setApiLoader={setApiLoader}
                                  limitId={leadData?.leadDetail?.Account?.Application__c || ""}
                                  redirectScreen={redirectScreen}
                                  getLeadApplicationDetail={getLeadApplicationDetail}
                                  handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                                  SanctionedLimit={leadData.SanctionedLimit}
                                  leadBasic = {{first_name:leadData.firstname,last_name:leadData.lastname,mobile:leadData.mobile,email:leadData.email}}
                                />
                              </>)

// : (leadData?.leadDetail?.Account?.Application__c === id  && (leadData.isEmailVerified === false || leadData.isMobileVerified === false)) ? (
: leadData.stageName === "Pre-Account Activation" ? (
  <>
    <form onSubmit={(e) => { e.preventDefault() }} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
      <div className="row ">
        <div className="col-md-12 font-family-medium fznormal mb-3">Please finish the following process in order to continue.</div>
        <div className='col-md-12 d-flex flex-column gap-20'>
          <div className='d-flex justify-content-between align-items-center'>
            <span className='fs-18 d-flex justify-content-between align-items-center'>
              <MdOutlineDoneOutline style={leadData.isMobileVerified === false ? {marginRight: "12px"} : { color:"green",marginRight: "12px"}} />
               Please verify your Mobile </span>  
               <span onClick={handleMobileVerify} className={leadData.isMobileVerified === false ?'text-blue cur-pointer':'text-blue cur-notallow'}>send link</span>
               </div>

          <div className='d-flex justify-content-between align-items-center'><span className='fs-18 d-flex justify-content-between align-items-center'>
            <MdOutlineDoneOutline style={leadData.isEmailVerified === false ? {marginRight: "12px"} : { color:"green",marginRight: "12px"}}/> 
            Please verify your Email </span> 
            <span className={leadData.isEmailVerified === false ?'text-blue cur-pointer':'text-blue cur-notallow'} 
            onClick={handleEmailVerify}>send link</span>
            </div>

          <div className='d-flex justify-content-between align-items-center'><span className='fs-18 d-flex justify-content-between align-items-center'>
            <MdOutlineDoneOutline style={leadData.isVkycDone === false ? {marginRight: "12px"} : { color:"green",marginRight: "12px"}}/> 
            Please complete Video Verification </span> 
            <span className={leadData.isVkycDone === false ?'text-blue cur-pointer':'text-blue cur-notallow'} 
            onClick={sendVkycScreen}>Initiate VKYC</span>
            </div>
        </div>
      </div>
    </form>
  </>
)


                                : (
                                  leadData.stageName === "Other Document Pending" ||
                                  leadData.stageName === "Additional Document Requested" ||
                                  leadData.stageName === "Bureau Pull Failed" ||
                                  leadData.stageName === "Higher Limit Application Initiated" ||
                                  leadData.stageName === "Document Verification Pending" ||
                                  leadData.stageName === "Pre-Assessment FI Initiated" ||
                                  leadData.stageName === "Pre-Assessment FI Pending" ||
                                  leadData.stageName === "Approval Pending - Pre-Assessment FI Successful" ||
                                  leadData.stageName === "Approval Pending - Pre-Assessment FI Failed" ||
                                  leadData.stageName === "Approval Pending" ||
                                  leadData.stageName === "Under Referral" ||
                                  leadData.stageName === "Post Approval Deviation Pending" ||
                                  leadData.stageName === "Higher Limit Approval Pending" ||
                                  leadData.stageName === "Pre-Approval FI Initiated" ||
                                  leadData.stageName === "Pre-Approval FI Pending" ||
                                  leadData.stageName === "Whitelisting Pending" 
                                ) ? (
                                  <form onSubmit={(e) => { e.preventDefault() }} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
                                    <div className="font-family-medium fznormal">
                                      Your application is being processed. Please check back after some time.
                                    </div>
                                  </form>
                                )

                                  : leadData.stageName ? (

                                    leadData.showStageNameList &&
                                    <form onSubmit={(e) => { e.preventDefault() }} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
                                      <div className="font-family-medium fznormal">
                                        Your application is on {leadData.stageName} stage.
                                      </div>
                                    </form>
                                  )
                                    : leadData.applicationErr && (
                                      <form onSubmit={(e) => { e.preventDefault() }} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
                                        <div className="font-family-medium fznormal">
                                          {leadData.applicationErr}
                                        </div>
                                      </form>
                                    )

          }



        </div>
      </div>
    </>
  )
}

export default LeadJourney