import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import completeImg from "../../images/complete.png" 
import pendingImg from "../../images/pending-icon.svg" 
import { getActiveStageIndex, getStageListWithIndex } from '../../utils/customMethods'
import { MdOutlinePending } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
const userStageList = [
  {stage:"Application Pending",label:"Basic"},
  {stage:"Professional",label:"Professional"},
  {stage:"Residential",label:"Residential"},
  
  {stage:"Video Verification",label:"Video Verification"},

  {stage:"KYC Pending",label:"KYC"},
  {stage:"Bank Account Details/ Penny Drop Pending",label:"Bank Verification"},
  {stage:"Account Active",label:"Account Activation"},
  {stage:"Select Plan",label:"Select Plan"},
  {stage:"Agreement generation/Signoff",label:"Agreement"},
  {stage:"Payment Collection",label:"Payment"},
  {stage:"NACH Registration",label:"Nach"},
  {stage:"Loan Disbursed",label:"Disbursed"},
]



const Sidebar = ({stage,onBoarding,isVkycDone,isMobileVerified,isEmailVerified}) => {
  const [stageList,setStageList] = useState(getStageListWithIndex(userStageList));
  const [activeStageIndex,setActiveStageIndex] = useState(-1);
  const [showSidebar,setShowSidebar] = useState(false);
  // const showSidebar = document?.getElementById("hamber-check")?.checked;
  useEffect(() => {
    // if(onBoarding == 1){
    //   const index = getActiveStageIndex("Basic");
    //   setActiveStageIndex(index)
    // }else 
    if(onBoarding == 2){
      const index = getActiveStageIndex("Professional");
      setActiveStageIndex(index)
    }else if (onBoarding == 3){
      const index = getActiveStageIndex("Residential");
      setActiveStageIndex(index)
    }
    else if(stage){
      const index = getActiveStageIndex(stage);
     setActiveStageIndex(index)
    }
    
    //  const index = getActiveStageIndex("KYC Pending");
    //  setActiveStageIndex(index)
   
  },[stage])

  
  return (
    <>
     <div className='mb-hamberger'>
        <input type='checkbox' id='hamber-check' value={showSidebar} onChange={(e) => setShowSidebar(e.target.checked)}/>
       <label className="mb-hamberger-label" htmlFor='hamber-check'>
        <GiHamburgerMenu fontSize={30}/>
      </label>
        </div>
    <div className={showSidebar ? 'sidebar mb-sidebar': "sidebar"} onClick={() =>setShowSidebar(!showSidebar)}>
       
      <div className='stepWrapper'>
    {
      stageList.map((ele,i) => {
        if(ele.stage === "Video Verification"){
          return (
            <div key={`multistepper${i}`} className={isVkycDone ? "stepBlock selected" : "stepBlock"}>
            <div className="circleWrapper" >
                {isVkycDone ? <img src={completeImg}/>
                :<div className="circle"></div>}
            </div>
            <span className='stage-label'>{ele.label}</span>
            </div>
          )
        }

        return(
          <div key={`multistepper${i}`} className={"stepBlock" + (activeStageIndex > ele.index ? " selected" : "")+(activeStageIndex === ele.index ? " "+"activestage":"")+(ele.stage === "Application Pending" ? " mestages":"")}>
          <div className="circleWrapper" >
              {activeStageIndex > ele.index ? <img src={completeImg}/>
              :<div className="circle"></div>}
          </div>
          <span className='stage-label'>{ele.label}</span>
         { ele.stage === "Application Pending" && <div className='sd-sub-st-cont'>
             <div className='d-flex gap-5 align-items-center'>
            
             {isMobileVerified ? <img className='sd-sub-icon' src={completeImg}/>
            :  <img className='sd-sub-icon' src={pendingImg}/>
            }
              
             <span className='stage-label fs-12'>Verify Mobile</span>
             </div>
             <div className='d-flex gap-5 align-items-center'>
             
             {isEmailVerified ? <img className='sd-sub-icon' src={completeImg}/>
            :  <img className='sd-sub-icon' src={pendingImg}/>
            }
             <span className='stage-label fs-12'>Verify Email</span>
             </div>
          </div>}
          </div>
        )
      })
    } 
    


      
        
        
        </div>
    </div>
    </>
  )
}

export default Sidebar