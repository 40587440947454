import React, { useEffect, useState } from 'react'
import PaymentDetails from '../../PaymentDetails/PaymentDetails'
import { getUserStatus } from '../../../action/document';
import { toast } from 'react-toastify';

const LeadPaymentCollection = ({setApiLoader,limitId,id}) => {
    const [redirectUrl,setRedirectUrl] = useState("");
    const [stage,setStage] = useState("");
    const [paymentDetails,setPaymentDetails] = useState({
        advanceEmi:'',
        processingFee:'',
        downPayment:'',
        totalUpfrontAmount:'',
      });

    useEffect(() => {
    
        getStatus();
       }, [])
       
       const getStatus = async () => { 
        setApiLoader(true);
        const res = await getUserStatus(limitId,id);
        setApiLoader(false)
        if(res.status === "success"){
          if(res.stage === "payment_pending"){
          setStage("Payment Collection");
          setRedirectUrl(res.url)
          setPaymentDetails({...paymentDetails,advanceEmi:res.metadata.advance_emi,
            processingFee:res.metadata.processing_fee,
            downPayment:res.metadata.downpayment,
            totalUpfrontAmount: res.metadata.total_upfront_payment,
            // totalUpfrontAmount: "77488",
          })
         }
     }
    }

    const handleRedirect = (e) => {
        e.preventDefault()
        toast(`Redirecting to ${stage} stage.`)
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 1000);
      }
    const {advanceEmi,processingFee,downPayment,totalUpfrontAmount} = paymentDetails;
  return (
    <form onSubmit={(e) => {e.preventDefault()}} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
    <div className="row mb-3">
          <h5 className="main-header">Payment Collection</h5>
        </div>
    {/* <PaymentDetails 
    paymentDetails={paymentDetails} 
    showPaymentBtn={true} 
    redirectUrl = {redirectUrl} 
    stage={stage}
    />          */}
       
        <div className='row payment-detail'>

   {advanceEmi && <div className='pd-key-value'>
    <span className='pd-key'>Advance EMI </span> 
    <span className='pd-value'>₹ {advanceEmi}</span>
   </div>
}
  {processingFee  && <div className='pd-key-value'>
    <span className='pd-key'>Processing Fee</span> 
   <span className='pd-value'>₹ {processingFee}</span>
   </div>}
   
  {downPayment && <div className='pd-key-value'>
    <span className='pd-key'>Down Payment</span>
    <span className='pd-value'>₹ {downPayment}</span>
    </div>}

  {totalUpfrontAmount &&  <div className='pd-key-value'>
    <span className='pd-key'>Total Upfront Payment</span>
    <span className='pd-value'>₹ {totalUpfrontAmount}</span>
    </div>
}
</div>


<button 
className="enable-btn verify-btn pd-payment-btn lead-blue-btn fs-14"
onClick={handleRedirect}
//   disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
>Continue to Payment Collection</button>
        
      
        </form>
  )
}

export default LeadPaymentCollection