import React from 'react'
import "./Navbar.css"
import logo from "../../images/eduvanzlogo.png"

const Navbar = () => {
  return (
    <div className='navbar'>
    <div className='logo-container'>
       <img src={logo} alt="logo" className='wizr-logo'/>
    </div>  
    {/* <div className='right-nav'>
      <div>
        <img src="/img/help.svg" alt="helpicon" />
        <span>Help Center</span>
      </div>
      <div>
        <img src="/img/logout.svg" alt="logouticon" />
        <span>Logout</span>
      </div>
    </div> */} 
  </div>
  )
}

export default Navbar