import { useEffect, useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import CustmerSidebar from "../CustmerSidebar/CustmerSidebar";
import Navbar from "../Navbar/Navbar";
import '../LoanDetails/LoanDetails.css'
import { FiDownload } from "react-icons/fi";
import { IoIosEye } from "react-icons/io";
import { useParams } from "react-router-dom";
import {getApplicationDetails} from "../../action/CustmerPortalJourney"

function ApplicationDetails() {
    const [apiLoader,setApiLoader] = useState(false);
    const [activeTab,setActiveTab] = useState("Details");
    const [appDetails,setAppDetails] = useState({});
     const {id} = useParams();

    useEffect(() => {
      const getData = async () => {
            const res = await getApplicationDetails(id);
           if(res.status === "success"){
            setAppDetails(res.data);
           }else{
            // handle error
           }
      }

      getData();
    },[])

    return (
     <>
      <Navbar />
      <div className="side-main-container light-background">
        <CustmerSidebar />

        <div className="main-content">
          {apiLoader && <ApiCallLoader />}
          <div className="p-4 w-100 min-h-100 mx-auto white-background">
            <div className="row ld-tab-cont">
                  <span className={activeTab === "Details" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Details")}>
                    Details
                  </span>
                  <span className={activeTab === "Payment Plan" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Payment Plan")}>
                    Payment Plan
                  </span>
                </div>
           { activeTab === "Details" &&
            <div className="row ldt-container application-detail" >
                <div className="col-md-4 ld-siglef">
                      <div>Application ID</div>
                      <div>{appDetails?.Id || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Application Stage</div>
                      <div>{appDetails?.StageName || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Merchant Name</div>
                      <div>{appDetails?.merchant_name || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Product Name</div>
                      <div>{appDetails?.product_name || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Product Price</div>
                      <div>{appDetails?.Amount?.toLocaleString('en-IN') || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Product Offering</div>
                      <div>{appDetails?.product_offerning || "-"}</div>
                </div>
                <div className="col-md-4 ld-siglef">
                      <div>Loan Amount</div>
                      <div>{appDetails?.Applied_Loan_Amount__c || "-"}</div>
                </div>        
                </div>}
           { activeTab === "Payment Plan" && 
           <div className="row ldt-container application-detail" >
           <div className="col-md-4 ld-siglef">
                 <div>Loan Amount</div>
                 <div>{appDetails?.Applied_Loan_Amount__c?.toLocaleString('en-IN')|| "-"}</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>Tenure</div>
                 <div>1,000</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>EMI Amount</div>
                 <div>25,000</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>Fixed rate</div>
                 <div>10</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>Down Payment</div>
                 <div>4,00,000</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>Upfront Payment</div>
                 <div>2-3 Product Offering</div>
           </div>
           <div className="col-md-4 ld-siglef">
                 <div>Total Advance EMI</div>
                 <div>2,00,000</div>
           </div>        
           <div className="col-md-4 ld-siglef">
                 <div>Processing Fee</div>
                 <div>2,000</div>
           </div>        
           </div>}
           





                </div>
                </div>
                </div>

    
    </>
    );
  }
  
  export default ApplicationDetails;
  