import { useEffect, useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import CustmerSidebar from "../CustmerSidebar/CustmerSidebar";
import Navbar from "../Navbar/Navbar";
import '../Loans/Loans.css'
import { getAllApplications } from "../../action/CustmerPortalJourney";
import { useNavigate } from "react-router-dom";

function Applications() {
    const [apiLoader,setApiLoader] = useState(false);
    const [applicaionList,setApplicationList] = useState([])
    const navigate = useNavigate();
    const handleAppDetail = () => {

    }


    useEffect(() => {
      getData();
    },[])

    const getData = async() => {
       const res = await getAllApplications();
       if(res.status === "success"){
        setApplicationList(res.data)
       }
    }

    return (
     <>
      <Navbar />
      <div className="side-main-container light-background">
        <CustmerSidebar />

        <div className="main-content">
          {apiLoader && <ApiCallLoader />}
          <div className="p-4 w-100 min-h-100 mx-auto white-background">
            <div className="row">
                  <span className="loans-tab text-black">
                  Applications
                  </span>
                  {/* <span className={activeTab === "Closed Loans" ? "loans-tab active" : "loans-tab"} onClick={() => setActiveTab("Closed Loans")}>
                    Closed Loans
                  </span> */}
                </div>
            <div className="row">
                  <table className="loans-table">
                    <thead>
                       <tr>
                       <th>Application ID</th>
                        <th>Applicant Name</th>
                        <th>Mobile Number</th>
                        <th>Loan Amount</th>
                        <th>Status</th>
                       </tr>
                    </thead>
                    <tbody>
                       {applicaionList.length > 0  && applicaionList.map(ele => {
                        return(
                          <tr key={ele.Id}>
                          <td className="cur-pointer" onClick={() => navigate(`/application/${ele.Id}`)}>{ele.Id}</td>
                          <td>{ele.Name}</td>
                          <td>1234567890</td>
                          <td>{ele.Applied_Loan_Amount__c}</td>
                          <td>{ele.StageName}</td>
                          </tr>
                        )
                       })}
                    </tbody>
                  </table>
                </div>
                </div>
                </div>
                </div>
    </>
    );
  }
  
  export default Applications;
  