import React from 'react'
import './LeadLimitModal.css'
import successLogo from '../../images/complete.png'

const LeadLimitModal = ({closeModal,getHigherLimit,continueWithCurrentLimit,btn2Text,limit}) => {

  return (
    <div className='lead-limit-popup modal'>
       <div className="lead-limit-popup-content">
       <span onClick={closeModal} class="llp-close-btn">&times;</span>
           <div className='llp-top-box'>
           <img src={successLogo} alt='success' width={50} height={50}/>
           <h2 className='text-blue'>Congratulations!</h2>
           <h3 className='text-center'>You have successfully received a limit of ₹ <span className='fw-600'>{limit ? limit.toLocaleString('en-IN'):0}</span></h3>
           </div>
           <div className='llp-bottom-box'>
             <button onClick={continueWithCurrentLimit} className='lead-blue-btn'>CONTINUE WITH CURRENT LIMIT</button>
             <button onClick={getHigherLimit} className='lead-blue-btn'>{btn2Text}</button>
           </div>
       </div>
    </div>
  )
}

export default LeadLimitModal;
