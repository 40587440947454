import { useEffect, useState } from "react";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import CustmerSidebar from "../CustmerSidebar/CustmerSidebar";
import Navbar from "../Navbar/Navbar";
import './Custmer.css'
import { getAllApplications } from "../../action/CustmerPortalJourney";
import { useLocation } from "react-router-dom";

function Custmer() {
    const [apiLoader,setApiLoader] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const accId = queryParams.get('id');

    return (
     <>
      <Navbar />
      <div className="side-main-container light-background">
        {/* <CustmerSidebar /> */}

        <div className="main-content">
          {apiLoader && <ApiCallLoader />}
          <div className="p-4 w-60 mx-auto white-background mt-5 brad-10">
            <div className="row">
                <div className="col-md-12 main-header mb-4">Application Details</div>
            <div className="col-md-5 ld-siglef mb-3">
                      <div>First Name</div>
                      <div>Mayur</div>
                </div>
            <div className="col-md-5 ld-siglef mb-3 ml-5">
                      <div>Last Name</div>
                      <div>Kale</div>
                </div>
            <div className="col-md-5 ld-siglef mb-3">
                      <div>Mobile</div>
                      <div>1234567890</div>
                </div>
            <div className="col-md-5 ld-siglef mb-3 ml-5">
                      <div>Limit</div>
                      <div>1,00,000</div>
                </div>

                <div className="col-md-5 ld-siglef mb-3">
                      <div>Email</div>
                      <div>mayurkale2207@gmail.com</div>
                </div>
               

              <div className="col-md-12 custmer-btns mt-3">
              <button  className="button-43">
                Finish
               </button>
               <button className="button-62">
                Drop
               </button>
              </div>
                    
                </div>


                </div>
                </div>
                </div>
    </>
    );
  }
  
  export default Custmer;
  
