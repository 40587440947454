import React, { useEffect, useState } from 'react'
import Select,{components} from 'react-select';
import { getComapanyNamList } from '../../../action/leadjourney';
import dropDownArrow from "../../../images/dropdown-indicator.svg"
import searchIcon from "../../../images/search.svg"

const ProfessionalDetails = ({professionalDetails,name,setApiLoader,getLeadApplicationDetail,handleQdeFormDetail,redirectScreen}) => {
    const [professionalDetailsData,setProfessionalDetailData] = useState({
      companyType:"",
      monthlyIncome: "",
      familyIncome: "",
      selectedProfession: "",
      allSalariedData: [
        { value: 1, label: "Salaried"},
        { value: 2, label: "Self-Employed-Professional" },
        { value: 3, label: "Self-Employed-Non Professional" },
        { value: 4, label: "Retired"},
      ],
      MaritalOptions: [
        { value: "Married", label: "Married"},
        { value: "Single", label: "Single" },
        { value: "Other", label: "Other" },
      ],
      companyList :[{name:"Nike"},{name:"Tata"},{name:"Mahindra"},{name:"Udemy"},{name:"Byjus"},{name:"Addidas"}],
      isSearching:false,
      isCompanySelected:false,
      marital_status:""
    });
  

useEffect(() => {
    
        const {occupation,employer_name,monthly_income,employer_type,marital_status} = professionalDetails;
        if(occupation){
          const profIndex = professionalDetailsData.allSalariedData.findIndex(ele => ele.label.includes(occupation))
          if(profIndex || profIndex === 0){
            setProfessionalDetailData(prev => {return {...prev,selectedProfession:professionalDetailsData.allSalariedData[profIndex]}});
          }
        }
        setProfessionalDetailData(prev =>{ return{...prev,
          companyName:employer_name,
          isCompanySelected:employer_name ? true:false,
          monthlyIncome:monthly_income,
          companyType:employer_type,
          username:name?.firstname || "",
          marital_status:{value:marital_status,label:marital_status}
        }})
      const getCompanyData = async() => {
        setApiLoader(true);
        const response = await getComapanyNamList();
        setApiLoader(false);
        if(response.status === "success"){
          const data = response.data?.length > 0 ? response.data.map(ele =>  {return {name:ele.Company_Name__c,type:ele.Company_Type__c}}):[];
          setProfessionalDetailData(prev => {return {...prev,companyList:data}})
        }else{
        }
       }
       getCompanyData();
},[])


  const handleSelectProfession = (value) => {
    setProfessionalDetailData({...professionalDetailsData,
      selectedProfession: value,
      familyIncome: "",
      monthlyIncome: "",
      companyName: "",
    });
  };

  const handleSelectMaritalStatus = (value) => {
    setProfessionalDetailData({...professionalDetailsData,
      marital_status:value
    });
  };



  const handleFamilyIncomeChange = (e) => {
    if (e.target.value !== "") {
      if (/^(?!(0))\d+$/.test(e.target.value?.replace(/,/g, ''))) {
        setProfessionalDetailData({...professionalDetailsData, familyIncome: e.target.value });
      }
    } else {
      setProfessionalDetailData({...professionalDetailsData, familyIncome: e.target.value });
    }
  };

  const handleMonthlyIncomeChange = (e) => {
    if (e.target.value !== "") {
      if (/^(?!(0))\d+$/.test(e.target.value?.replace(/,/g, ''))) {
        setProfessionalDetailData({...professionalDetailsData, monthlyIncome: e.target.value });
      }
    } else {
      setProfessionalDetailData({...professionalDetailsData, monthlyIncome: e.target.value });
    }
  };

  const handleCompanyName = (e) => {
    setProfessionalDetailData({...professionalDetailsData, companyName: e.target.value,isSearching:true });
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropDownArrow} alt='dropdown' className='mx-2'/> 
      </components.DropdownIndicator>
    );
  };

  const handleCompanySelect = (value,type) => {
    setProfessionalDetailData({...professionalDetailsData,companyName:value,isSearching:false,companyType:type,isCompanySelected:true})
  }

 const submitProfession = (e) => {
  e.preventDefault();
    const {selectedProfession,companyName,monthlyIncome,companyType,marital_status} = professionalDetailsData;
    const data = {
      occupation:selectedProfession.label,
      employer_name:companyName,
      monthly_income:monthlyIncome,
      employer_type:companyType,
      marital_status:marital_status.label,
    };
    handleQdeFormDetail({professionalDetails:data})
    redirectScreen({stage:3})
  }

    return (
      <>
        <form onSubmit={submitProfession} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
        <div className="row">
              <h5 className="main-header mb-3">Professional Details</h5>
            </div>


            <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="professional" className="form-label mb-2">
                    { `${professionalDetailsData.username || "sneha"} is a`}
                  </label>
                  <Select
                    classNamePrefix="professional_details_lead_select"
                    // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}

                    components={{
                      IndicatorSeparator: () => null,DropdownIndicator:DropdownIndicator 
                    }}
                    styles={{
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                        fontWeight:state.isSelected ?'500':'400',
                        color: "#525252",
                        borderBottom:'1px solid #F2F3F8',
                        "&:hover": {
                          backgroundColor: "#F2F3F8",
                          fontWeight:'500'
                        },
                      }),
                      ValueContainer:(baseStyles, state) => ({
                        ...baseStyles,
                        padding:'none'
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        padding:'none'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        padding:'none'
                      }),

                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        border: "1px solid #c2c2c2",
                        borderRadius: "6px",
                        padding: '0rem 1rem'
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "all .2s ease",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(180deg)"
                          : null,
                        color: "black",
                      }),
                      menu:(base,state)=>({
                        ...base,
                        width:'130%',
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        paddingTop: 0,
                        paddingBottom: 0,
                     }),
                    }}
                    options={professionalDetailsData.allSalariedData}
                    placeholder="Select Any"
                    name="profession"
                    onChange={handleSelectProfession}
                    value={professionalDetailsData.selectedProfession}
                  />
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="professional" className="form-label mb-2">
                  Marital Status
                  </label>
                  <Select
                    classNamePrefix="professional_details_lead_select"
                    // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}

                    components={{
                      IndicatorSeparator: () => null,DropdownIndicator:DropdownIndicator 
                    }}
                    styles={{
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                        fontWeight:state.isSelected ?'500':'400',
                        color: "#525252",
                        borderBottom:'1px solid #F2F3F8',
                        "&:hover": {
                          backgroundColor: "#F2F3F8",
                          fontWeight:'500'
                        },
                      }),
                      ValueContainer:(baseStyles, state) => ({
                        ...baseStyles,
                        padding:'none'
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        padding:'none'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        padding:'none'
                      }),

                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        border: "1px solid #c2c2c2",
                        borderRadius: "6px",
                        padding: '0rem 1rem'
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "all .2s ease",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(180deg)"
                          : null,
                        color: "black",
                      }),
                      menu:(base,state)=>({
                        ...base,
                        width:'100%',
                      })
                    }}
                    options={professionalDetailsData.MaritalOptions}
                    placeholder="Select Any"
                    name="profession"
                    onChange={handleSelectMaritalStatus}
                    value={professionalDetailsData.marital_status}
                  />
                </div>
             
              {professionalDetailsData.selectedProfession?.value &&
                  professionalDetailsData.selectedProfession?.value == 4 && (
                  
                       <div className="col-md-6 form-group ">
                      <label
                        htmlFor="monthly_income"
                        className="form-label mb-2"
                      >
                        Family Income
                      </label>
                      <div className="d-flex justify-content-between align-items-center form_input_border">
                        <p className="rupeesArrow">₹</p>
                        <input
                          type="text"
                          className="border-none"
                          name="company_name"
                          onChange={handleFamilyIncomeChange}
                          value={professionalDetailsData.familyIncome?Number(professionalDetailsData.familyIncome?.replace(/,/g, '')).toLocaleString('en-IN'):""}
                        />
                      </div>
                    </div>
                     
                  )}
              {professionalDetailsData.selectedProfession?.value &&
                (professionalDetailsData.selectedProfession?.value === 1 || professionalDetailsData.selectedProfession?.value === 2 || professionalDetailsData.selectedProfession?.value === 3) && (      
                      <>
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="company_name"
                            className="form-label mb-2"
                          >
                            Company Name
                          </label>
                          <div className="d-flex justify-content-between align-items-center form_input_border px-3">
                            <input
                              type="text"
                              className="border-none p-0"
                              placeholder="Search company"
                              name="company_name"
                              onChange={handleCompanyName}
                              value ={professionalDetailsData.companyName}
                            />
                          <img src={searchIcon} className='searchIcon ml-1' alt='search icon'/>
                          </div>
                          {professionalDetailsData.isSearching && professionalDetailsData.companyName.length > 0 &&<div className="customAutoComplete">
                            {
                              professionalDetailsData.companyList?(professionalDetailsData.companyList.filter(ele => ele.name.toLowerCase().includes(professionalDetailsData.companyName.toLocaleLowerCase()))?.map((ele)=>{return <div onClick={()=>handleCompanySelect(ele.name,ele.type)}>
                                {ele.name}
                              </div>})):"Searching.."
                            }
                          </div>}
                        </div>
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="monthly_income"
                            className="form-label mb-2"
                          >
                            Monthly Income
                          </label>
                          <div className="d-flex justify-content-between align-items-center form_input_border px-3">
                            <p className="rupeesArrow">₹</p>
                            <input
                              type="text"
                              className="border-none"
                              name="company_name p-0"
                              onChange={handleMonthlyIncomeChange}
                              value={professionalDetailsData.monthlyIncome?Number(professionalDetailsData.monthlyIncome?.replace(/,/g, '')).toLocaleString('en-IN'):""}
                              /> 
                          </div>
                        </div>
                      </>
                )}

<div className="col-md-12 my-2 d-flex justify-content-end">
              {/* <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                          id="close-create"
                        >
                          Cancel
                        </button> */}
              <button
                type="submit"
                className="lead-blue-btn"
              >
                SAVE & NEXT
              </button>
            </div>
        </div>
   </form>
      </>
    );

}

export default ProfessionalDetails