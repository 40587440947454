import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { getAddressList, updateQdeDetails } from '../../../action/leadjourney';
import LeadSingleDocument from './LeadSingleDocument';
import uploadIcon from '../../../images/upload.svg'

const LeadAddressVerification = ({redirectScreen,basicDetails,professionalDetails,addressDetails,handleSuccEroorToastMessage,getLeadApplicationDetail,isQde2FormDone,id,setApiLoader}) => {
  const [addressDetailsData,setAddressVerificationData] = useState({
    addressList:[],
    city:'',
    address:'',
    state:'',
    pincode:'',
    showAddAddress:false,
    isAutoAdd:false, 
    house:1,
    gmapsLoaded:true,
    rent_amount:"",
    country:'',
    addressType:"Existing",
    selectedAddress : "",
    noAddressFound:false,
    showNoExistingAddress:false,
               
}) 

useEffect(() => {
  initMap();
      const {country,postal_code,state,street,city,address_type,resident_type,rent_amount} = addressDetails;
      setAddressVerificationData(prev => {return {...prev,
            city,
            address:street,
            state,
            pincode:postal_code,
            house:resident_type === "Rented" ? 2 : 1,
            rent_amount:rent_amount,
            country
      }})
      getAdresses()
}, [])

const getAdresses = async () => {
  setApiLoader(true)
  const response = await getAddressList(id);
  setApiLoader(false)
    if(response.status === "success"){
      setAddressVerificationData({...addressDetailsData,addressList:response.data})
    }else{
      setAddressVerificationData({...addressDetailsData,addressList:[],showNoExistingAddress:true})
    }

}

const handlePlacesChange = (address) => {
  setAddressVerificationData(prev => {return {...addressDetailsData, address }});
}; 

const handleSelect = (address) => {
  geocodeByAddress(address)
    .then((results) => results[0])
    .then((getData) => {
      // const address = getData.formatted_address;
      let addressArray = getData.address_components;
      let city = getCity(addressArray);
      let state = getState(addressArray);
      let pincode = getPincode(addressArray);
      let country = getCountry(addressArray)
      setAddressVerificationData({...addressDetailsData,
        address: address ? address : "",
        city: city ? city : "",
        state: state ? state : "",
        address_pin: pincode ? pincode : "",
        pincode: pincode ? pincode : "",
        country:country? country :""
      });
    })
    .catch((error) => console.error("Error", error));
};

const getCity = (addressArray) => {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "administrative_area_level_3" === addressArray[i].types[0]
    ) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

const getState = (addressArray) => {
  let state = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_1" === addressArray[i].types[0]
      ) {
        state = addressArray[i].long_name;
        return state;
      }
    }
  }
};

const getCountry = (addressArray) => {
  let country = "";
   addressArray.forEach(ele => {
     if(ele.types.includes('country')){
      country = ele.long_name;
     }
   });
   return country;
  
};

const getPincode = (addressArray) => {
  let pincode = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        pincode = addressArray[i].long_name;
        return pincode;
      }
    }
  }
};


const initMap = () => {
  setAddressVerificationData(prev => {return{...prev,
    gmapsLoaded: true,
  }});
};


const handleHouse = (value) => {
  setAddressVerificationData({...addressDetailsData,house:value})
}

const handleStateCityChange =(e) => {
setAddressVerificationData({...addressDetailsData,[e.target.name]:e.target.value})
}

const onlyPincode = (e) => {
var pattern = new RegExp(/^[0-9\b]+$/);
if (e.target.value !== "") {
  if (!pattern.test(e.target.value)) {
    setAddressVerificationData({...addressDetailsData,
      errpincode: "Please enter correct Pincode",
      isValidpincode: true,
    });
  } else if (e.target.value.length < 6) {
    setAddressVerificationData({...addressDetailsData,
      [e.target.name]: e.target.value,
      errpincode: "Please enter correct Pincode",
      isValidpincode: true,
    });
  } else {
    setAddressVerificationData({...addressDetailsData,
      [e.target.name]: e.target.value,
      errpincode: " ",
      isValidpincode: false,
    });
  }
} else {
  setAddressVerificationData({...addressDetailsData,
    [e.target.name]: e.target.value,
    errpincode: " ",
    isValidpincode: false,
  });
}
};

const handleRentAmount = (e) => {
 let value = e.target.value;
 if(value){
  value = value.replace("₹","").trim();
 }
if (value !== "") {
  if (/^(?!(0))\d+$/.test(value?.replace(/,/g, ''))) {
    setAddressVerificationData({...addressDetailsData, [e.target.name]: value });
  }
} else {
  setAddressVerificationData({...addressDetailsData,[e.target.name]: value });
}
}

const updateResident = async() => {
// const {dob,postal_code,gender} = basicDetails;
if(isQde2FormDone){
  redirectScreen({stage:4});
  return;
}
const {occupation,employer_name,monthly_income,employer_type,marital_status} = professionalDetails;
const {address,state,city,pincode,house,rent_amount,country,addressType,addressList,selectedAddress } = addressDetailsData;
const resident_type = house === "Rented" || house === "Owned" ? house : house == 1 ? "Owned" : "Rented";

let data = {
  monthly_income:monthly_income ? monthly_income.replace(/,/g,"") : "",
  occupation,
  employer_name,
  employer_type,
  marital_status,
  // date_of_birth:dob,
  // gender:gender,
  monthly_income_consent:true,
  address_type:addressType,
  resident_type,
  rent_amount:rent_amount?rent_amount.replace(/,/g,"") : "",
}
if(addressType === "New"){
  data = {
    ...data,
  address:{
    street:address,
      city,
      state,
      country,
      postal_code:pincode,
  }
}
}else{
  const add = addressList.find(ele => ele.Id === selectedAddress);
  data = {
    ...data,
  address:{
    street:add.FlatNo_PlotNo_HouseNo__c ? add.FlatNo_PlotNo_HouseNo__c : "",  
      city:add.City__c ? add.City__c : "",
      state:add.State__c ? add.State__c : "",
      country:add.Country__c ? add.Country__c : "",
      postal_code:add.PinCode__c ? add.PinCode__c : "",
  }
}
}
setApiLoader(true)
const response = await updateQdeDetails(id,data);
setApiLoader(false);
if(response.status === "success"){
  if(response.message){
    handleSuccEroorToastMessage({succ:response.message})
    setTimeout(() => {
      getLeadApplicationDetail({});
    }, 1000);
  //  setTimeout(() => {
  //   redirectScreen({stage:4})
  //  }, 500);
  }
}else{
  handleSuccEroorToastMessage({err:response.message})
}

}

const handleAddressSelect = (Id) => {
const {addressList} = addressDetailsData;
setAddressVerificationData({...addressDetailsData,
  selectedAddress:Id
})
}   

const handleSelectAddressType = (value) => {
setAddressVerificationData({...addressDetailsData,addressType:value})
}

const handlePreviousClick = () => {
redirectScreen({stage:2})
}

const disableButton = () => {
 return  (addressType === "New" &&
                   address != "" &&
                    state != "" &&
                    city &&
                    pincode != "" &&
                    pincode.length == 6 &&
                    (addressDetailsData.house === 1 ||
                      (addressDetailsData.house === 2 &&
                        addressDetailsData.rent_amount !== ""))) ||
                       addressType=== "Existing" &&  addressDetailsData.selectedAddress && (addressDetailsData.house === 1 ||
                        (addressDetailsData.house === 2 &&
                          addressDetailsData.rent_amount !== "")) || isQde2FormDone
                    ? false
                    : true
}

const {addressList,state,city,pincode,address,house,rent_amount,country,addressType,selectedAddress,noAddressFound,showNoExistingAddress} = addressDetailsData;
  return (
    <form onSubmit={(e) => {e.preventDefault()}} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
    <div className="row">
          <h5 className="main-header mb-3">Select Communication Address</h5>
        </div>

        <>
          <div className="row">
            <div className="col-md-6 mb-3">
                  <label className='form-label'>Address Type</label>
                  <div className='d-flex'>
                  {/* <div onClick={() => handleSelectAddressType("New")} className={addressDetailsData.addressType === "New"?'lead-radio-btn selected':"lead-radio-btn"}>New</div>
                    <div onClick={() => handleSelectAddressType("Existing")} className={addressDetailsData.addressType === "Existing"?'lead-radio-btn selected ml-2':"lead-radio-btn ml-2"}>Existing</div> */}
                    <div className='lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleSelectAddressType("New")}
                      checked={addressDetailsData.addressType === "New" ? true :false}
                      />
                      <label className='ml-2'>New</label>
                    </div>
                    <div className='ml-3 lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleSelectAddressType("Existing")}
                      checked={addressDetailsData.addressType === "Existing" ? true : false}
                      />
                      <label className='ml-2'>Existing</label>
                    </div>
                  </div>
                  </div>
       

                

              {
                 addressType === "New" ?
                 <>
                   {/* <div className="col-md-7 d-flex align-items-center mt-3">
                  <label className="radio-slider-switch">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        setAddressVerificationData({...addressDetailsData,
                          showAddAddress: e.target.checked,
                        })
                      }
                      checked={setAddressVerificationData.showAddAddress}
                    />
                    <span className="slider-round"></span>
                  </label>{" "}
                  <p 
                  className={`ml-2 ${addressDetailsData.showAddAddress ? 'text-black':"text777"}`}
                  >Same as Aadhaar address</p>
                </div> */}
                {addressDetailsData.showAddAddress && (
                  <div
                  className='col-md-5 d-flex align-items-center'
                  >
                    {!addressDetailsData.isAutoAdd && (
                      <>
                      <img className="mr-2" src={uploadIcon}/>
                      <p
                        onClick={() =>
                          setAddressVerificationData({...addressDetailsData, isAutoAdd: true })
                        }
                
                        className=''
                      >
                        Upload aadhaar to autofill
                      </p>
                      </>
                    )}
                    {addressDetailsData.isAutoAdd && (
                      <p
                        onClick={() =>
                          setAddressVerificationData({...addressDetailsData, isAutoAdd: false })
                        }
                        className=''
                      >
                        Enter manually instead
                      </p>
                    )}
                  </div>
                )}
                {!addressDetailsData.isAutoAdd ? (
                  <div className="row w-100">
                    <div className="col-md-12 form-group mt-3">
                      <label htmlFor="" className="form-label mb-1">
                        Address Line 1
                      </label>
                      {addressDetailsData.gmapsLoaded && (
                        <PlacesAutocomplete
                          value={addressDetailsData.address}
                          style={{ width: "100%" }}
                          onChange={handlePlacesChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="form-group position-relative">
                              <input
                                {...getInputProps({
                                  placeholder: "Flat No. / Building Name / Society / Locality / Street Name",
                                  className:
                                    "form-control location-search-input locationinput",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(
                                  (suggestion, index) => {
                                    const className =
                                      suggestion.active
                                        ? "suggestion-item"
                                        : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#eaeaea",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#f5f5f5",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        key={"item" + index}
                                        {...getSuggestionItemProps(
                                          suggestion,
                                          {
                                            className,
                                            style,
                                          }
                                        )}
                                      >
                                        <span key={index}>
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      )}
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="" className="form-label mb-1">
                      Address Line 2
                      </label>
                      <input
                        type="text"
                        className="form-control locationinput"
                        placeholder="City / State"
                        onChange={handleStateCityChange}
                        value={city}
                        name="city"
                      />
                    </div>
                    <div className="col-md-6 form-group clearfix">
                      <label htmlFor="" className="form-label mb-1">
                        Pincode
                      </label>
                      <input
                        type="text"
                        className="form-control locationinput"
                        placeholder="000 000"
                        maxLength={"6"}
                        name="pincode"
                        id="address_pin"
                        value={addressDetailsData.pincode}
                        onChange={onlyPincode}
                      />
                    </div>
                    
                    {/* <div className="col-sm-6 form-group">
                      <label htmlFor="" className="form-label">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        name="state"
                        value={state}
                        onChange={handleChange}
                      />
                    </div> */}
                    
                  </div>
                ) : (
                  <>
                  <div
                      className="row my-3 w-100"
                    >
                   <p className="aadharUpload mb-2 col-md-12">Upload Aadhar</p>
                    
                     
                      <div className='col-md-6 documentpright'>
                      < LeadSingleDocument 
                         btnTitle = {"Upload Aadhar Front"}
                         doc = {"AADHAR"}
                         type={"FRONT"}
                       />
                      </div>
                      <div className='col-md-6 documentpleft'>
                      < LeadSingleDocument 
                         btnTitle = {"Upload Aadhar Back"}
                         doc = {"AADHAR"}
                         type={"BACK"}
                       />
                      </div>
                       
                    </div>
                  </>
                )}

                <div className="row w-100 mt-2">
                <div className="col-md-6">
                  <label className='form-label'>Address Type</label>
                  <div className='d-flex'>
                  {/* <div onClick={() => handleHouse(1)} className={addressDetailsData.house === 1 ?'lead-radio-btn selected':"lead-radio-btn"}>Owned</div>
                    <div onClick={() => handleHouse(2)} className={addressDetailsData.house === 2?'lead-radio-btn selected ml-2':"lead-radio-btn ml-2"}>Rented</div> */}
                     <div className='lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleHouse(1)}
                      checked={addressDetailsData.house === 1 ? true :false}
                      />
                      <label className='ml-2'>Owned</label>
                    </div>
                    <div className='ml-3 lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleHouse(2)}
                      checked={addressDetailsData.house === 2 ? true : false}
                      />
                      <label className='ml-2'>Rented</label>
                    </div>
                  </div>
                  </div>
                  {addressDetailsData.house === 2 && (
                    <div className="col-md-6 form-group mb-mt-3">
                      <label
                        htmlFor="rent_amount"
                        className="form-label mb-1"
                      >
                        Rent Amount
                      </label>
                      <input
                        type="text"
                        className="form-control locationinput"
                        name="rent_amount"
                        id="rent_amount"
                        placeholder="₹ 000"
                        // value={addressDetailsData.rent_amount}
                        value={addressDetailsData.rent_amount?`₹  ${Number(addressDetailsData.rent_amount?.replace(/,/g, '')).toLocaleString('en-IN')}`:""}
                        onChange={handleRentAmount}
                      />
                    </div>
                  )}
                </div>
                 </>
                 :
                //  addressType === "Existing" && addressList.length > 0 
                  <>
                    <div className="col-md-6 mb-3">
                  <label className='form-label'>Resident Type</label>
                  <div className='d-flex'>
                  {/* <div onClick={() => handleHouse(1)} className={addressDetailsData.house === 1 ?'lead-radio-btn selected':"lead-radio-btn"}>Owned</div>
                    <div onClick={() => handleHouse(2)} className={addressDetailsData.house === 2?'lead-radio-btn selected ml-2':"lead-radio-btn ml-2"}>Rented</div> */}
                     <div className='lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleHouse(1)}
                      checked={addressDetailsData.house === 1  ? true :false}
                      />
                      <label className='ml-2'>Owned</label>
                    </div>
                    <div className='ml-3 lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleHouse(2)}
                      checked={addressDetailsData.house === 2 ? true : false}
                      />
                      <label className='ml-2'>Rented</label>
                    </div>
                  </div>
                  </div>
                 
                  {addressDetailsData.house === 2 && (
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="rent_amount"
                        className="form-label mb-1 text-black font-family-medium fznormal"
                      >
                        Rent Amount
                      </label>
                      <input
                        type="text"
                        className="form-control locationinput"
                        name="rent_amount"
                        id="rent_amount"
                        placeholder="₹ 000"
                        // value={addressDetailsData.rent_amount}
                        value={addressDetailsData.rent_amount?`₹  ${Number(addressDetailsData.rent_amount?.replace(/,/g, '')).toLocaleString('en-IN')}`:""}
                        onChange={handleRentAmount}
                      />
                    </div>
                  )}
                
                      <div className='col-md-12 address-list-container'>
                    {
                     addressList.length > 0 ?
                      addressList.map((ele,i) => {

                        return(
                          <div key={`addlist${i+223}`} className='d-flex mb-3 align-items-baseline'>
                          <input style={{accentColor:'#000'}} type="radio" id={`add${i}`} name="addlist" checked={selectedAddress === ele.Id ? true:false} onChange={() => handleAddressSelect(ele.Id)}/>
                           <label for={`add${i}`} className='ml-2 fs-14'>{`${ele.Consolidated_Address__c} (${ele.Type_of_Address__c})`}</label>
                          </div>
                        )
                      })
                      : showNoExistingAddress && 
                       <div className='mt-1 mb-2 ml-1 text-center'> No existing addresses</div>
                    }
                
                 </div>  
                 </>


              }



             
            </div>
          
          <div className="row">
       

            <div className="col-sm-12 d-flex justify-content-end">
            {/* <button
                  onClick={handlePreviousClick}
                  className='btn lead-basic-prev-btn-dis mr-2'
                >
                  Previous
                </button> */}
              <button
                type="button"
                onClick={updateResident}
                disabled={
                 disableButton() ? true : false
                }
                // className={disableButton() ? "lead-blue-btn cur-notallow" : "lead-blue-btn"}
                className={"lead-blue-btn"}
              >
                SAVE & NEXT
              </button>
            </div>
          </div>
        </>
        </form>
  )
}

export default LeadAddressVerification








  
    
   





    
   





