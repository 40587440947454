import axios from "axios";
import { handleApiError } from "../utils/customMethods";
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_URL})

const headers = { 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiIwMDFDMzAwMDAwRXJJeTVJQUYiLCJpYXQiOjE3MTI3NDA4NDB9.ga8OCOwu8lsWk1_7XcnqW6RWEwzibVGUtc4Jh1x7Mqs'
  }


export const getAllApplications = async (merchantId) => {
    try {
     const {data} = await axiosInstance.get(`cis/customer-portal/applications`,{
        headers
     })
     return data;
    } catch (error) {
        return handleApiError(error); 
    }
}

export const getApplicationDetails = async (appId) => {
    try {
     const {data} = await axiosInstance.get(`cis/customer-portal/applications/${appId}`,{
        headers
     })
     return data;
    } catch (error) {
        return handleApiError(error); 
    }
}