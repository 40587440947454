// import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
// import Navbar from './components/Navbar/Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SuccessKyc from './components/SucessKyc/SuccessKyc';
import AgreementSignning from './components/AgreementSignning/AgreementSignning';
import Vkyc from './components/Vkyc/Vkyc';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';
import NachSuccess from './components/NachSuccess/NachSuccess';
import VerifyMobile from './components/VerifyMobile/VerifyMobile';
import CreateLead from './components/CreateLead/CreateLead';
import LeadJourney from './components/LeadJourney/LeadJourney';
import Dashboard from './components/Dashboard/Dashboard';
import Loans from './components/Loans/Loans';
import LoanDetails from './components/LoanDetails/LoanDetails';
import Login from './components/Login';
import Applications from './components/Applications/Applications';
import Custmer from './components/Custmer/Custmer';
import ApplicationDetails from './components/ApplicationDetails/ApplicationDetails';

const router = createBrowserRouter([
  {
    path: "/cis/:id",
    element: <Home/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/success",
    element: <SuccessKyc/>,
  },
  {
    path: "/cis/:id/:transId",
    element: <AgreementSignning/>,
  },
  {
    path: "/vkyc/:id",
    element: <Vkyc/>,
  },
  {
    path: "/payment/:id/:transId",
    element: <PaymentSuccess/>,
  },
  {
    path: "/nach/:id",
    element: <NachSuccess/>,
  },
  {
    path: "/verifymob/:id",
    element: <VerifyMobile/>,
  },
  {
    path: "/create-lead",
    element: <CreateLead/>,
  },
  {
    path: "/lead-journey/:id",
    element: <LeadJourney/>,
  },
  {
    path: "/loans",
    element: <Loans/>,
  },
  {
    path: "/applications",
    element: <Applications/>,
  },
  {
    path: "/loans/:id",
    element: <LoanDetails/>,
  },
  {
    path: "/application/:id",
    element: <ApplicationDetails/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/custmer",
    element: <Custmer/>,
  },
]);


function App() {
  return (
    <div className="App">
       <RouterProvider router={router} />
      <ToastContainer/>
    </div>
  );
}

export default App;
