import React, { useState } from 'react'
import "./Login.css"
import ApiCallLoader from './ApiCallLoader/ApiCallLoader';
import { toast } from "react-toastify";
import OTPInput from 'react-otp-input'

const Login = () => {
    const [mobile,setMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [isOtpSend, setIsOtpSend] = useState(true);
    const [apiLoader,setApiLoader] = useState(false);


    const  handleSendOtp = () => {
        setApiLoader(true);
        setTimeout(() => {
            setIsOtpSend(true);
            setApiLoader(false);
            toast.success("OTP was successfully sent to your phone number.")
        }, 1000);
    }

    const hadleEnterKeyDown = () => {

    }

    
  return (
    <div className='row login-cont'>
        {apiLoader && <ApiCallLoader/>}
       <div className="col-md-7 login-left" style={{backgroundImage:"url('./img/education.png')",
    backgroundSize: "cover"}}>
        {/* <img src="/img/education.png" alt="" /> */}
        </div>      
       <div className="col-md-5 login-right">
             <div className="login-box">
                <h3 className='fs-20'>Login</h3>

               {isOtpSend ? 
            //    enter otp UI
                <div>
                    <label className='fs-12 fw-lighter mb-1 d-block'>Enter OTP</label>
                    <div className='lb-otp-cont'>
                    <OTPInput
                     value={otp}
                     onChange={(value) => setOtp(value)}
                     numInputs={6}
                     shouldAutoFocus
                     inputType="number"
                     renderSeparator={<span></span>}
                     renderInput={(props) => (
                       <input {...props} onKeyDown={hadleEnterKeyDown} />
                     )}
                   />
                </div>
                </div> :
                <div>
                    <label className='fs-12 fw-lighter'>Mobile Number</label>
                    <div className='lb-input-cont'>
                    <span className='text-gray'>+91</span>
                  <input type="text" maxLength={10} />
                </div>
                </div>
                }

               {isOtpSend
               ? <button className='lb-get-otp-btn' onClick={handleSendOtp}>Get OTP</button>
               : <button className='lb-get-otp-btn' onClick={handleSendOtp}>Continue</button>}
             </div>
        </div>      
    </div>
  )
}

export default Login
