import React, { useEffect, useState } from 'react'
// import './Sidebar.css'
import completeImg from "../../images/complete.png" 
import { getActiveStageIndex, getStageListWithIndex } from '../../utils/customMethods'
import { MdOutlineDashboard } from "react-icons/md";
import { RiBodyScanLine } from "react-icons/ri";
import { FaRegFileAlt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation } from 'react-router-dom';

const CustmerSidebar = ({stage,onBoarding}) => {
  const [activeStageIndex,setActiveStageIndex] = useState(-1);
  const location = useLocation();
  const [path,setPath] = useState(location.pathname);
  useEffect(() => {
    // if(onBoarding == 1){
    //   const index = getActiveStageIndex("Basic");
    //   setActiveStageIndex(index)
    // }else 
    if(onBoarding == 2){
      const index = getActiveStageIndex("Professional");
      setActiveStageIndex(index)
    }else if (onBoarding == 3){
      const index = getActiveStageIndex("Residential");
      setActiveStageIndex(index)
    }
    else if(stage){
      const index = getActiveStageIndex(stage);
     setActiveStageIndex(index)
    }

    //  const index = getActiveStageIndex("KYC Pending");
    //  setActiveStageIndex(index)
   
  },[stage])
 
  return (
    <div className='customer-sidebar'>
      <div className='stepWrapper'>
           <ul className='side-list'>
            <Link to="/home" className={`side-list-item d-flex align-items-center gap-6 ${path === "/home"?"active":""}`}><MdOutlineDashboard /> Dashboard</Link>
            <Link to="/applications" className={`side-list-item d-flex align-items-center gap-6 ${path === "/applications"?"active":""}`}> <RiBodyScanLine /> Applications</Link>
            <Link to="/loans" className={`side-list-item d-flex align-items-center gap-6 ${path === "/loans"?"active":""}`}> <FaRegFileAlt /> Loans</Link>
            <Link  to="/profile" className={`side-list-item d-flex align-items-center gap-6 ${path === "/profile"?"active":""}`}>  <CgProfile/> Profile</Link>
           </ul>
        </div>
    </div>
  )
}

export default CustmerSidebar