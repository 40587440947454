import React, { useState } from 'react'
import { getLeadProfile, sendVkycLink } from '../../../action/leadjourney'
import LeadSingleDocument from './LeadSingleDocument'

const LeadKycDocument = ({ photoDoc, panDoc, aadharDoc, aadharBackDoc, passportDoc, passportBackDoc, drivingLiDoc, drivingLiBackDoc, voterIdDoc, voterIdBackDoc, bankStatementDoc, sendLink, getLeadApplicationDetail, handleDocUpdate, handleSuccEroorToastMessage, redirectScreen, setApiLoader, id,sendVkycScreen }) => {
  const [leadKycDocumentData, setLeadKycDocumentData] = useState({
    showDocListModel: false,
    selectedDoc: {},
    selectedDocType: "PAN",
    isCropEnable: false,
    showOkycButton: false,
    apiCount: 0,
    identityProofType: "Aadhar"
  })


  const OpenDocModel = () => {
    setLeadKycDocumentData(prev => { return { ...prev, showDocListModel: true, selectedDoc: panDoc ? panDoc : {}, selectedDocType: "PAN" } })
  }

  const handleRedirectScreenPolling = async () => {
    if (photoDoc.fileContent && panDoc.fileContent && aadharDoc.fileContent && aadharBackDoc.fileContent) {
      redirectScreen({ stage: 5 });
      return;
    }
    // getLeadApplicationDetail({stage:5});
    if (leadKycDocumentData.apiCount === 2) {
      // stage updation after 3 attempts
      setApiLoader(false);
    } else {
      let data = {
        leadId: id,
      };
      setLeadKycDocumentData(prev => { return { ...prev, apiCount: leadKycDocumentData.apiCount + 1 } });
      const response = await getLeadProfile(data);

      setApiLoader(true)
      if (response.status === "success") {
        const leadDetail = response?.data[0] ? response.data[0] : {};

        if (leadDetail.StageName === "KYC Pending") {

          setTimeout(() => {
            handleRedirectScreenPolling();
          }, 2000);
        } else {


          // setTimeout(() => {
          //   handleRedirectScreenPolling();
          // }, 2000);
          setApiLoader(false)
          redirectScreen({
            stage: 5,
            stageName: leadDetail.StageName,
            isQde2FormDone: leadDetail.Is_QDE_2_form_done__c,
            isPanPulled: leadDetail.PAN__c ? true : false,
          })
        }
      } else {
        setTimeout(() => {
          handleRedirectScreenPolling();
        }, 2000);
      }

    }

  }

  const isKycNextBtnEnable = () => {
    let isButtonEnable = false;
    if (((photoDoc.inputBase64 || photoDoc.fileContent) && photoDoc.verified) &&
      ((panDoc.inputBase64 || panDoc.fileContent) && panDoc.verified) &&
      ((aadharDoc.inputBase64 || aadharDoc.fileContent) && aadharDoc.verified) &&
      ((aadharBackDoc.inputBase64 || aadharBackDoc.fileContent) && aadharBackDoc.verified)
    ) {
      isButtonEnable = true;
    }


    return isButtonEnable;
  }

  const handleAddarUIShow = (value) => {
    setLeadKycDocumentData(prev => {
      return {
        ...prev,
        showOkycButton: value
      }
    })
  }

  const handleIdentityProofType = (type) => {
    setLeadKycDocumentData(prev => { return { ...prev, identityProofType: type } })
  }

  const handleInitiateVkyc = async () => {
    sendVkycScreen(id)
  }


const { selectedDocType, selectedDoc, isCropEnable, showOkycButton } = leadKycDocumentData;

return (
  <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
    <div className="row flex-column">
      <h5 className="main-header">KYC Documents</h5>
      <p className='mb-3 fs-11'>Required PNG, JPEG. Max file size 5 MB</p>
    </div>

    <>
      <div className="row">
        <div className='col-md-6 documentpright'>
          <div className='text-black fznormal font-family-semibold mt-3 mb-2'>Photo</div>
          < LeadSingleDocument
            id={id}
            setApiLoader={setApiLoader}
            btnTitle={"Drop your files here or Browse"}
            doc_category={"Photo"}
            doc_type={"Photo"}
            base64={photoDoc.fileContent ? photoDoc.type + photoDoc.fileContent : ""}
            handleSuccEroorToastMessage={handleSuccEroorToastMessage}
            handleDocUpdate={handleDocUpdate}
            OpenDocModel={OpenDocModel}
          />
        </div>
        <div className='col-md-6'>
          <div className='mt-3 mb-2'>PAN Card</div>
          < LeadSingleDocument
            id={id}
            setApiLoader={setApiLoader}
            btnTitle={"Drop your files here or Browse"}
            doc_category={"PAN"}
            doc_type={"PAN"}
            base64={panDoc.fileContent ? panDoc.type + panDoc.fileContent : ""}
            handleSuccEroorToastMessage={handleSuccEroorToastMessage}
            handleDocUpdate={handleDocUpdate}
            OpenDocModel={OpenDocModel}
            isTif={panDoc.FileType === "TIF" ? true : false}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12 form-group sub-header">
          Identity Proof (Upload atleast one)
        </div>
        <div className='col-md-12 d-flex flex-wrap'>
          {/* <div onClick={() => handleIdentityProofType("Aadhar")} className={leadKycDocumentData.identityProofType === "Aadhar"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Aadhar</div>
                        <div onClick={() => handleIdentityProofType("Passport")} className={leadKycDocumentData.identityProofType === "Passport"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Passport</div>
                        <div onClick={() => handleIdentityProofType("Driving Licence")} className={leadKycDocumentData.identityProofType === "Driving Licence"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Driving Licence</div>
                        <div onClick={() => handleIdentityProofType("Voter ID")} className={leadKycDocumentData.identityProofType === "Voter ID"?'lead-radio-btn selected mb-2':"lead-radio-btn mb-2"}>Voter ID</div> */}

          <div className='lead-radio-item'>
            <input
              type='radio'
              onChange={() => handleIdentityProofType("Aadhar")}
              checked={leadKycDocumentData.identityProofType === "Aadhar" ? true : false}
            />
            <label className='ml-2'>Aadhar</label>
          </div>
          <div className='ml-4 lead-radio-item'>
            <input
              type='radio'
              onChange={() => handleIdentityProofType("Passport")}
              checked={leadKycDocumentData.identityProofType === "Passport" ? true : false}
            />
            <label className='ml-2'>Passport</label>
          </div>

          <div className='ml-4 lead-radio-item'>
            <input
              type='radio'
              onChange={() => handleIdentityProofType("Driving Licence")}
              checked={leadKycDocumentData.identityProofType === "Driving Licence" ? true : false}
            />
            <label className='ml-2'>Driving Licence</label>
          </div>
          <div className='ml-4 lead-radio-item'>
            <input
              type='radio'
              onChange={() => handleIdentityProofType("Voter ID")}
              checked={leadKycDocumentData.identityProofType === "Voter ID" ? true : false}
            />
            <label className='ml-2'>Voter ID</label>
          </div>


        </div>

        {leadKycDocumentData.identityProofType === "Aadhar" && <div className='col-sm-12 mt-3'>
          <div className='d-flex mb-1'>
            <input style={{ accentColor: '#000' }} type="radio" id="adhardoc1" name="adhardoc" checked={showOkycButton === false ? true : false} onChange={() => handleAddarUIShow(false)} />
            <label for="adhardoc1" className='ml-2'>Mobile No not Linked with Aadhaar</label>
          </div>
          <div className='d-flex'>
            <input style={{ accentColor: '#000' }} type="radio" id="adhardoc2" name="adhardoc" checked={showOkycButton ? true : false} onChange={() => handleAddarUIShow(true)} />
            <label for="adhardoc2" className='ml-2'>Aadhaar Digital Verification</label>
          </div>
        </div>}

        {leadKycDocumentData.identityProofType === "Aadhar" && showOkycButton === false &&
          <>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Aadhar Front</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Aadhar Front"}
                isPdf={aadharDoc.FileType === "PDF" ? true : false}
                isTif={aadharDoc.FileType === "TIF" ? true : false}
                base64={aadharDoc.fileContent ? aadharDoc.type + aadharDoc.fileContent : aadharDoc.inputBase64 ? aadharDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Aadhar Back</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Aadhar Back"}
                isPdf={aadharBackDoc.FileType === "PDF" ? true : false}
                isTif={aadharBackDoc.FileType === "TIF" ? true : false}
                base64={aadharBackDoc.fileContent ? aadharBackDoc.type + aadharBackDoc.fileContent : aadharBackDoc.inputBase64 ? aadharBackDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
          </>}

        {leadKycDocumentData.identityProofType === "Aadhar" && showOkycButton && <div className="col-sm-12 mt-3">
          <div className='vkyc-link-btn fs-14' onClick={(e) => sendLink("okyc")}>
            {/* Send Okyc Link */}
            Initiate OKYC
          </div>
        </div>
        }



        {leadKycDocumentData.identityProofType === "Passport" &&
          <>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Passport Front</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Passport Front"}
                isPdf={passportDoc.FileType === "PDF" ? true : false}
                isTif={passportDoc.FileType === "TIF" ? true : false}
                base64={passportDoc.fileContent ? passportDoc.type + passportDoc.fileContent : passportDoc.inputBase64 ? passportDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Passport Back</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Passport Back"}
                isPdf={passportBackDoc.FileType === "PDF" ? true : false}
                isTif={passportBackDoc.FileType === "TIF" ? true : false}
                base64={passportBackDoc.fileContent ? passportBackDoc.type + passportBackDoc.fileContent : passportBackDoc.inputBase64 ? passportBackDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
          </>}


        {leadKycDocumentData.identityProofType === "Driving Licence" &&
          <>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Driving Licence</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Driving Licence"}
                isPdf={drivingLiDoc.FileType === "PDF" ? true : false}
                isTif={drivingLiDoc.FileType === "TIF" ? true : false}
                base64={drivingLiDoc.fileContent ? drivingLiDoc.type + drivingLiDoc.fileContent : drivingLiDoc.inputBase64 ? drivingLiDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Driving Licence Back</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"DL Back"}
                isPdf={drivingLiBackDoc.FileType === "PDF" ? true : false}
                isTif={drivingLiBackDoc.FileType === "TIF" ? true : false}
                base64={drivingLiBackDoc.fileContent ? drivingLiBackDoc.type + drivingLiBackDoc.fileContent : drivingLiBackDoc.inputBase64 ? drivingLiBackDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
          </>}

        {leadKycDocumentData.identityProofType === "Voter ID" &&
          <>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Voter Id Front</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Voter Id Front"}
                isPdf={voterIdDoc.FileType === "PDF" ? true : false}
                isTif={voterIdDoc.FileType === "TIF" ? true : false}
                base64={voterIdDoc.fileContent ? voterIdDoc.type + voterIdDoc.fileContent : voterIdDoc.inputBase64 ? voterIdDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
            <div className='col-md-6'>
              <div className='mt-3 mb-2'>Voter Id Back</div>
              < LeadSingleDocument
                id={id}
                setApiLoader={setApiLoader}
                btnTitle={"Drop your files here or Browse"}
                doc_category={"ID Proof"}
                doc_type={"Voter Id Back"}
                isPdf={voterIdBackDoc.FileType === "PDF" ? true : false}
                isTif={voterIdBackDoc.FileType === "TIF" ? true : false}
                base64={voterIdBackDoc.fileContent ? voterIdBackDoc.type + voterIdBackDoc.fileContent : voterIdBackDoc.inputBase64 ? voterIdBackDoc.inputBase64 : ""}
                handleSuccEroorToastMessage={handleSuccEroorToastMessage}
                handleDocUpdate={handleDocUpdate}
                OpenDocModel={OpenDocModel}
              />
            </div>
          </>}

      </div>


      <>
        {/* <div className="v-scroll_lead">
                  <div className="boxed max_images">
                    <div className="row">
                      <div className="col-sm-12 form-group">
                      <h5 className="mb-1 kycdocuments mt-3">Financial Documents</h5>
                      <p className="listinputs fzavgtext text999 font-family-regular">
                      Required PDF or JPEG. Max file size 5 MB
                    </p>
                      </div>
                      
                      <div className="col-md-12 form-group">
                          <h6 className="kyc-sub-head mt-3">Bank Statement</h6>
                        </div>
                        <div className='col-md-6 documentpright'>
                      < LeadSingleDocument 
                         btnTitle = {"Drop your files here or Browse"}
                         doc_category = {"Bank Account Proof"}
                         doc_type={"Bank Statement - 3 Months"}
                        //  isPdf = {bankStatementDoc.FileType === "PDF" ? true :false}
                         isPdf ={true}
                         accept={"pdf"}
                         base64 = {bankStatementDoc.fileContent ? bankStatementDoc.type + bankStatementDoc.fileContent : ""}
                         handleSuccEroorToastMessage = {handleSuccEroorToastMessage}
                         handleDocUpdate = {handleDocUpdate}
                         OpenDocModel = {OpenDocModel}
                       />
                </div>
                     
                      
                      
                    
                    </div>
                  </div>
                </div> */}

        {/* <div className="row">
          <div className="col-sm-12 mt-3">
            <div className='vkyc-link-btn fs-14 w-max-content' onClick={handleInitiateVkyc}>
              Initiate VKYC
            </div>
          </div>
        </div> */}

      </>

      <div className="row">
        <div className="col-sm-12 d-flex  justify-content-end align-items-center mt-3">
          {/* <Button
                  onClick={() => redirectScreen({stage:3})}
                  className='btn lead-basic-prev-btn-dis mr-2 mt-3'
                >
                  Previous
                </Button> */}
          <button
            type="button"
            className="lead-blue-btn mb-mt-3"
            onClick={handleRedirectScreenPolling}
            disabled={
              isKycNextBtnEnable()
                ? false
                : true
            }

          >
            SAVE & NEXT
          </button>
        </div>
      </div>

      {/* document list model */}

    </>
  </form>
)
}

export default LeadKycDocument