import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Select from "react-select";
import "./CreateLead.css";
import CorrectImg from "../../images/correct.svg";
import {
  createLeads,
  getAllProducts,
  getBranchs,
  getBrands,
  getCreateLeadJobDetails,
  getLocations,
  getMerchants,
  getProductOffering,
  getProducts,
} from "../../action/leadjourney";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LeadLimitModal from "../../modals/LeadLimitModal/LeadLimitModal";
let interval;


const CreateLead = () => {
  const [createLeadData, setCreateLeadData] = useState({
    mobile: "+91 ",
    firstName: "",
    lastName: "",
    email: "",
    product: "",
    product_price: "",
    loan_amount: "",
    MobileErrorMsg: "",
    successMsg: "",
    selling_price: "",
    allProductData: [],
    allMerchantData:[],
    allBrandData:[],
    allProductOfferingData:[],
    allLocationData:[],
    allBranchData:[],
    selectedProduct: "",
    selectedMerchant:"",
    selectedBrand:"",
    selectedProductOffering:"",
    selectedLocation:"",
    selectedBranch:"",
    apiCount: 0,
    productId: "",
    isValidEmailId: true,
    isValidFirstName: true,
    isValidLastName: true,
    isProductSelected: true,
    isMerchantSelected:true,
    isBrandSelected:true,
    isProductOfferingSelected:true,
    isLocationSelected:true,
    isValidSellingPrice: true,
    CreateLeadApiErr: "",
  });
  const [apiLoader, setApiLoader] = useState(false);
  const navigate = useNavigate();
  const [showProductDetails,setShowProductDetails] = useState(false);
  const [showLeadLimitPopup,setShowLeadLimitPopup] = useState(false);
  const [limit,setLimit] = useState(null);
  const [LimitId,setLimitId] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setApiLoader(true);
      const response = await getProducts();
      setApiLoader(false);
      if (response.status === "success") {
        // const pro = response.data
        //   .map((ele) => {
        //     return {
        //       productId: ele.Id,
        //       value: ele?.ProductId__r?.Name || "",
        //       label: ele?.ProductId__r?.Name || "",
        //       price: ele?.ProductId__r?.Price__c || 30000,
        //     };
        //   })
        //   .filter((ele) => ele.price && ele.value);
        if(response?.data?.length > 0){
          setCreateLeadData({ ...createLeadData, allProductData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) });
        }
      } else {
        setCreateLeadData({ ...createLeadData, allProductData: [] });
      }
    };
    getData();
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleChange = (e) => {
    if (e.target.name == "email") {
      if (!isValidEmail(e.target.value)) {
        setCreateLeadData({
          ...createLeadData,
          isValidEmailId: false,
          [e.target.name]: e.target.value,
        });
      } else {
        setCreateLeadData({
          ...createLeadData,
          isValidEmailId: true,
          [e.target.name]: e.target.value,
        });
      }

      if (e.target.value == "") {
        setCreateLeadData({
          ...createLeadData,
          isValidEmailId: false,
          [e.target.name]: e.target.value,
        });
      }
      return;
    }

    setCreateLeadData({ ...createLeadData, [e.target.name]: e.target.value });
  };

  const handleMobile = (e) => {
    let mobno = e.target.value.slice(4);
    const reg = /^[0]?[6789]\d{9}$/;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (mobno !== "") {
      if (!pattern.test(mobno)) {
        setCreateLeadData({
          ...createLeadData,
          isValid: false,
          MobileErrorMsg: "Please enter only number.",
        });
        // document.getElementById('mobile').value = "";
      } else if (mobno.length < 10) {
        setCreateLeadData({
          ...createLeadData,
          MobileErrorMsg: "mobile number must be of 10 digit",
          mobile: `+91 ${mobno}`,
        });
      } else if (mobno.length === 10) {
        if (reg.test(mobno)) {
          setCreateLeadData({
            ...createLeadData,
            isValid: true,
            MobileErrorMsg: "",
            mobile: `+91 ${mobno}`,
          });
        } else {
          setCreateLeadData({
            ...createLeadData,
            isValid: false,
            MobileErrorMsg: "Please enter valid mobile number.",
            mobile: `+91 ${mobno}`,
          });
        }
      } else {
        setCreateLeadData({
          ...createLeadData,
          isValid: true,
          MobileErrorMsg: "",
          mobile: `+91 ${mobno}`,
        });
      }
    } else {
      setCreateLeadData({
        ...createLeadData,
        isValid: false,
        MobileErrorMsg: "",
        mobile: `+91 ${mobno}`,
      });
    }
  };

  const getLeadProfile = (lead_id) => {
    let data = {
      user_sfid: lead_id,
    };
    this.props.dispatch(getLeadProfile(data)).then((response) => {
      if (response.status === "success") {
        let getData = response.data;
        let limit = getData.ipa_basic_bureau__c
          ? getData.ipa_basic_bureau__c
          : null;
        if (limit) {
          setInterval(
            // this.props.dispatch(openLeadProfileModel(lead_id)),
            15000
          );
        } else {
          setInterval(
            // this.props.dispatch(openLeadProfileModel(lead_id)),
            18000
          );
        }
      } else {
        // setInterval(this.props.dispatch(openLeadProfileModel(lead_id)), 18000);
      }
    });
  };

  const onlyNumbers = (e) => {
    var pattern = new RegExp(/^(?!(0))\d+$/);
    if (e.target.value !== "") {
      if (!pattern.test(e.target.value)) {
        // setCreateLeadData({...createLeadData, [e.target.name]: "" });
      } else {
        setCreateLeadData({
          ...createLeadData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setCreateLeadData({ ...createLeadData, [e.target.name]: e.target.value });
    }
  };

  const scrollToBottom = () => {
    var objDiv = document.getElementById("create-lead");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const handleSubmitLead = async (e) => {
    e.preventDefault();

    setCreateLeadData((prev) => {
      return { ...prev, isValidFirstName: true,isValidLastName:true,isValidEmailId:true,MobileErrorMsg:"",isProductSelected:true,isBrandSelected:true,isLocationSelected:true,isProductOfferingSelected:true,isMerchantSelected:true,isValidSellingPrice:true,loanAmountErr:"",CreateLeadApiErr:""};
    });
   
    let allValueFilled = true;
    if (!createLeadData.firstName) {
      setCreateLeadData((prev) => {
        return { ...prev, isValidFirstName: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.lastName) {
      // setCreateLeadData({...createLeadData,isValidLastName:false});
      setCreateLeadData((prev) => {
        return { ...prev, isValidLastName: false };
      });
      allValueFilled = false;
    }
    if (
      !createLeadData.email ||
      (createLeadData.email && !createLeadData.isValidEmailId)
    ) {
      // setCreateLeadData({...createLeadData,isValidLastName:false});
      setCreateLeadData((prev) => {
        return { ...prev, isValidEmailId: false };
      });
      allValueFilled = false;
    }

    if (
      createLeadData.mobile?.length < 13 ||
      (createLeadData.mobile && createLeadData.MobileErrorMsg)
    ) {
      // setCreateLeadData({...createLeadData,isValidLastName:false});
      setCreateLeadData((prev) => {
        return { ...prev, MobileErrorMsg: "mobile number must be of 10 digit" };
      });
      allValueFilled = false;
    }

    if(showProductDetails){
      
    // commenting validation to make it oprional
    if (!createLeadData.selectedProduct?.value) {
      setCreateLeadData((prev) => {
        return { ...prev, isProductSelected: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.selectedBrand?.value) {
      setCreateLeadData((prev) => {
        return { ...prev, isBrandSelected: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.selectedLocation?.value) {
      setCreateLeadData((prev) => {
        return { ...prev, isLocationSelected: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.selectedProductOffering?.value) {
      setCreateLeadData((prev) => {
        return { ...prev, isProductOfferingSelected: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.selectedMerchant?.value) {
      setCreateLeadData((prev) => {
        return { ...prev, isMerchantSelected: false };
      });
      allValueFilled = false;
    }
    if (!createLeadData.selling_price) {
      setCreateLeadData((prev) => {
        return { ...prev, isValidSellingPrice: false };
      });

      allValueFilled = false;
    }
    if (!createLeadData.loan_amount) {
      setCreateLeadData((prev) => {
        return { ...prev, loanAmountErr: "Please fill the load amount" };
      });
      allValueFilled = false;
    }
    if (createLeadData.loan_amount > createLeadData.selling_price) {
        setCreateLeadData((prev) => {
        return {
          ...prev,
          loanAmountErr:
            "Loan amount must be less than the or equal to selling price",
        };
      });
      allValueFilled = false;
    }
    }
    
    if (!allValueFilled) {
      return;
    }

    const productData = {
      merchant_product_id: createLeadData.productId,
      brand: createLeadData?.selectedBrand?.Name ? createLeadData.selectedBrand.Name : "",
      selling_price: Number(createLeadData.selling_price) ? Number(createLeadData.selling_price) :"",
      loan_amount: Number(createLeadData.loan_amount) ? Number(createLeadData.loan_amount) : "",//optional
      merchant_id: createLeadData?.selectedMerchant?.Id ?  createLeadData.selectedMerchant.Id :"",
      product_offering: createLeadData?.selectedProductOffering?.Id ? createLeadData.selectedProductOffering.Id :"",
      branch_id: createLeadData?.selectedBranch?.Id ? createLeadData.selectedBranch.Id : "",
      location_id: createLeadData?.selectedLocation?.Id ? createLeadData.selectedLocation.Id : "",
    }

    let data = {
      first_name: createLeadData.firstName,
      last_name: createLeadData.lastName,
      email: createLeadData.email,
      mobile: createLeadData.mobile.slice(4),
    };

    if(showProductDetails){
      data = {...data,...productData};
    }

    setApiLoader(true);
    const response = await createLeads(data);
    if (response.status === "success") {
      let jobId = response?.data?.job_id || "";
      if (!jobId) {
        setApiLoader(false);
        setCreateLeadData({
          ...createLeadData,
          CreateLeadApiErr: "Failed to create the new lead",
        });
        return;
      }

      setTimeout(() => {
        getJobdetails(jobId);
      }, 15000);
    } else {
      setApiLoader(false);
      setCreateLeadData({
        ...createLeadData,
        CreateLeadApiErr: response?.message || "failed to create lead",
      });
    }
  };

  const getJobdetails = async (jobId) => {
    interval = setInterval(async () => {
      if (createLeadData.apiCount === 9) {
        clearInterval(interval);
        setApiLoader(false);
        setCreateLeadData({
          ...createLeadData,
          CreateLeadApiErr: "Failed to create the new lead",
        });
      } else {
        setCreateLeadData({
          ...createLeadData,
          apiCount: createLeadData.apiCount + 1,
        });
        const response = await getCreateLeadJobDetails(jobId);
        if (
          response?.status === "success" &&
          response.data?.status === "Completed"
        ) {
          clearInterval(interval);
          setApiLoader(false);
          // this.props.dispatch(OpenCreateLeadSuccess(response));
          const parsedData = JSON.parse(response.data.response);
          if (parsedData.status === "success") {
            setLimit(parsedData?.data?.softpullData?.data?.limit || 0);
            setLimitId(parsedData?.data?.accountData?.limitApplicationId || "");
            const data = {
              limit: parsedData?.data?.softpullData?.data?.limit || 0,
              name: parsedData?.data?.softpullData?.data?.first_name || "",
              lead_age: null,
              lead_id: parsedData?.data?.accountData?.limitApplicationId || "",
            };
            toast.success("Lead created successfully");
              if(data.limit){
                // toast.info(`You have successfully received a limit of ₹ ${data.limit.toLocaleString('en-IN')}`)
                setShowLeadLimitPopup(true);
                return;
              }

           
           if(parsedData?.data?.accountData?.limitApplicationId){
            setTimeout(() => {
              navigate(`/lead-journey/${LimitId}`);
            }, 500);
           }
          } else {
            // this.props.dispatch(softPullData({limt:0,name:""}))
            setCreateLeadData({
              ...createLeadData,
              CreateLeadApiErr: parsedData.message,
            });
          }
        }
      }
    }, 5000);
  };



  const handleSelectProduct = async(value) => {
    setCreateLeadData(prev => { return {
      ...prev,
      selectedProduct:value,
      // selling_price: value.price,
      productId: value.Id,
    }});

    fetchBrands(value.Id)
    fetchLocations(value.Id);
    fetchProductOffering(value.Id)
    // fetchMerchants(value.Id);
  };

  const handleSelectBrand = (value) => {
    setCreateLeadData({
      ...createLeadData,
      selectedBrand: value,
    });
    fetchLocations(createLeadData?.selectedProduct?.Id,value.Id);
    fetchBranch(value.Id)
  };

  const handleSelectProductOffering = (value) => {
    setCreateLeadData({
      ...createLeadData,
      selectedProductOffering: value,
      selling_price:value.Offering_Price__c
    });
  };

  const handleSelectLocation = (value) => {
    setCreateLeadData({
      ...createLeadData,
      selectedLocation: value,
    });

    fetchProductOffering(createLeadData?.selectedProduct?.Id,value.Id);
    fetchMerchants(createLeadData?.selectedProduct?.Id,value.Id);
    // fetchBranch(value.Id)
    // fetchBranch("001C300000EVMk9IAH")
  };

  const handleSelectMerchant = (value) => {
    setCreateLeadData({
      ...createLeadData,
      selectedMerchant: value,
    });
  };
  const handleSelectBranch = (value) => {
    setCreateLeadData({
      ...createLeadData,
      selectedBranch: value,
    });
  };

  const fetchBrands = async (id) => {
    setApiLoader(true);
    const response = await getBrands(id);
     setApiLoader(false)
    if (response.status === "success") {
      if(response?.data?.length > 0){
        setCreateLeadData(prev => { return { ...prev, allBrandData: response.data.map(ele => { return {...ele,label:ele.Brand_Name__c,value:ele.Brand_Name__c}}) }});
      }
    } else {
      setCreateLeadData(prev => { return { ...prev, allBrandData: [] }});
    }
  }
  const fetchLocations = async (prodId="",brandId="") => {
    setApiLoader(true);
    const data = {
        prodId,
        brandId
    }
    const response = await getLocations(data);
     setApiLoader(false)
    if (response.status === "success") {
      if(response?.data?.length > 0){
        setCreateLeadData(prev => { return { ...prev, allLocationData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
      }
    } else {
      setCreateLeadData(prev => { return { ...prev, allLocationData: [] }});
    }
  }

  const fetchProductOffering = async (prodId="",locId="") => {
    setApiLoader(true);
    const data = {
      prodId,//either we can pass prodId or locId or both prodId & locId
      locId,
    }
    const response = await getProductOffering(data);
     setApiLoader(false)
    if (response.status === "success") {
      if(response?.data?.length > 0){
        setCreateLeadData(prev => { return { ...prev, allProductOfferingData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
      }
    } else {
      setCreateLeadData(prev => { return { ...prev, allProductOfferingData: [] }});
    }
  }
  
  const fetchBranch= async (locId="") => {
    setApiLoader(true);
    const response = await getBranchs(locId);
     setApiLoader(false)
    if (response.status === "success") {
      if(response?.data?.length > 0){
        setCreateLeadData(prev => { return { ...prev, allBranchData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
      }
    } else {
      setCreateLeadData(prev => { return { ...prev, allBranchData: [] }});
    }
  }
  const fetchMerchants = async (prodId="",locId="") => {
    setApiLoader(true);
    const data = {
      prodId,
      locId,
    }
    const response = await getMerchants(data);
     setApiLoader(false)
    if (response.status === "success") {
      if(response?.data?.length > 0){
        setCreateLeadData(prev => { return { ...prev, allMerchantData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
      }
    } else {
      setCreateLeadData(prev => { return { ...prev, allMerchantData: [] }});
    }
  }
  const strideUrl = "";

  const closeModal = () => {
    setShowLeadLimitPopup(false);
  }

  const getHigherLimit = () => {
    setShowLeadLimitPopup(false);
    if(LimitId){
      navigate(`/lead-journey/${LimitId}?higherlimit=true`);
    }
  }

  const continueWithCurrentLimit = () => {
    setShowLeadLimitPopup(false);
    if(LimitId){
      navigate(`/lead-journey/${LimitId}`);
    }
  }

  return (
    <>
      <Navbar />
      <div className="side-main-container light-background">
        <Sidebar stage={""} />

        <div className="main-content">
          {/* Modal content*/}
          {apiLoader && <ApiCallLoader />}
        {showLeadLimitPopup &&  
        <LeadLimitModal
           closeModal={closeModal}
           continueWithCurrentLimit ={continueWithCurrentLimit}
           getHigherLimit={getHigherLimit}
           btn2Text={"GET HGHER LIMIT"}
           limit={limit}
          />}
          <form onSubmit={handleSubmitLead} className="p-4 w-80 mx-auto white-background my-5 brad-10 lead-journey-mb">
            <div className="row">
              <h5 className="main-header">Create New Lead</h5>
            </div>

            <div className="row px-3 pb-3">
              <div className="col-md-12 my-2">
                <h5 className="sub-header">Personal Details</h5>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label err">First Name</label>
                <input
                  type="text"
                  value={createLeadData.firstName}
                  onChange={handleChange}
                  className={!createLeadData.isValidFirstName ? "err" : ""}
                  placeholder="Name"
                  name="firstName"
                  maxLength="100"
                  minLength="1"
                />
                {!createLeadData.isValidFirstName && (
                  <div className="err-message">please fill the first name</div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  value={createLeadData.lastName}
                  onChange={handleChange}
                  className={!createLeadData.isValidLastName ? "err" : ""}
                  placeholder="Name"
                  name="lastName"
                  maxLength="100"
                  minLength="1"
                />
                {!createLeadData.isValidLastName && (
                  <div className="err-message">please fill the last name</div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Email ID</label>
                <input
                  type="email"
                  value={createLeadData.email}
                  onChange={handleChange}
                  className={!createLeadData.isValidEmailId ? "err" : ""}
                  placeholder="Enter email ID"
                  name="email"
                  id="email_id"
                />
                {!createLeadData.isValidEmailId && (
                  <div className="err-message">Invalid Email. Enter again.</div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Mobile Number</label>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  maxLength={"14"}
                  value={createLeadData.mobile}
                  onChange={handleMobile}
                  className={createLeadData.MobileErrorMsg ? "err": ""}
                  placeholder="10 Digit Mobile Number"
                  // onFocus={() => setCreateLeadData({...createLeadData, mobile: '+91' })}
                />
                {createLeadData.MobileErrorMsg && (
                  <div className="err-message">
                    {createLeadData.MobileErrorMsg}
                  </div>
                )}
              </div>


              <div className="col-md-12">
                <h5 className="ml-0 mb-3 d-flex align-items-center gap-7">
                <input type="checkbox" id="trans-det-check" 
                checked={showProductDetails} onChange={(e) => setShowProductDetails(prev => !prev)}/>Do you have merchant details?  
                {/* <span className="fs-12">(optional)</span> */}
                </h5>
              </div>
              { showProductDetails  && 
               <>
              <div className="col-md-12">
                <h5 className="sub-header ml-0 mb-3 d-flex align-items-center gap-7">
                 Product &amp; Loan Details  
                 {/* <span className="fs-12">(optional)</span> */}
                </h5>
              </div>

        
      
       <div className="col-md-6 form-group">
                <label className="form-label">Product</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allProductData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectProduct}
                  value={createLeadData.selectedProduct}
                />

                {!createLeadData.isProductSelected && (
                  <div className="err-message">please select the product </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Brand</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allBrandData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectBrand}
                  value={createLeadData.selectedBrand}
                />

                {!createLeadData.isBrandSelected && (
                  <div className="err-message">please select the brand </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Location</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allLocationData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectLocation}
                  value={createLeadData.selectedLocation}
                />

                {!createLeadData.isLocationSelected && (
                  <div className="err-message">please select the location</div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Product Offering</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allProductOfferingData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectProductOffering}
                  value={createLeadData.selectedProductOffering}
                />

                {!createLeadData.isProductOfferingSelected && (
                  <div className="err-message">please select the product offering </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Merchant</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allMerchantData}
                  placeholder="Select any Merchant"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectMerchant}
                  value={createLeadData.selectedMerchant}
                />

                {!createLeadData.isMerchantSelected && (
                  <div className="err-message">please select the Merchant </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Branch</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allBranchData}
                  placeholder="Select any Merchant"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectBranch}
                  value={createLeadData.selectedBranch}
                />

                {!createLeadData.isMerchantSelected && (
                  <div className="err-message">please select the Merchant </div>
                )}
              </div>
             
              <div className="col-md-6 form-group">
                <label className="form-label">Product Price</label>
                <input
                  type="text"
                  className={!createLeadData.isValidSellingPrice ? "err": ""}
                  placeholder="Enter Product Price"
                  // value={createLeadData.product_price ? createLeadData.product_price : ''}
                  disabled={true}
                  value={
                    createLeadData.selling_price ||
                    createLeadData.selling_price === 0
                      ? createLeadData.selling_price
                      : ""
                  }
                  onChange={onlyNumbers}
                  name="selling_price"
                  readOnly
                  //   readOnly={true}
                />
                {!createLeadData.isValidSellingPrice && (
                  <div className="err-message">
                    please select the product with selling price
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Loan Amount</label>
                <input
                  type="text"
                  className={createLeadData.loanAmountErr ? "err":""}
                  placeholder="Enter Loan Amount"
                  value={
                    createLeadData.loan_amount ? createLeadData.loan_amount : ""
                  }
                  onChange={onlyNumbers}
                  name="loan_amount"
                />
                {createLeadData.loanAmountErr && (
                                  <div className='err-message'>
                                    {createLeadData.loanAmountErr}
                                  </div>
                                )}
              </div>
              </>
              }
            </div>

            <div className="row">
              <div className="col-md-12 d-flex align-items-center">
                <img src={CorrectImg} className="tcp-icon" />
                <p className="fs-13 ml-2">
                  This will not affect the user’s Credit Score
                </p>
              </div>
              <div className="col-md-12 mt-2 fs-13">
                *By clicking "Create" applicant agrees to our
                <a
                  target="_blank"
                  href={strideUrl + "policy"}
                  className="font-family-semibold px-1"
                >
                  T&amp;C
                </a>{" "}
                and
                <a
                  target="_blank"
                  href={strideUrl + "policy?category11=privacy-policy"}
                  className="font-family-semibold px-1"
                >
                  Privacy Policy
                </a>
              </div>
            </div>

            {createLeadData.CreateLeadApiErr && (
              <div className="col-md-12 err-message fs-14">
                {createLeadData.CreateLeadApiErr}
              </div>
            )}
            <div className="col-md-12 mb-2 d-flex justify-content-end">
              {/* <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                          id="close-create"
                        >
                          Cancel
                        </button> */}
              <button
                type="submit"
                className="lead-blue-btn"
                //   disabled={
                //     createLeadData.selectedProduct?.value &&
                //     createLeadData.first_name  &&
                //     createLeadData.last_name &&
                //     createLeadData.email &&
                //     createLeadData.mobile.length === 14 &&
                //     !createLeadData.isValidEmailId &&
                //     createLeadData.loan_amount &&
                //     createLeadData.selling_price
                //       ? false
                //       : true
                //   }
                //   className={`btn createLeadButton ${
                //     createLeadData.selectedProduct?.value &&
                //     createLeadData.first_name  &&
                //     createLeadData.last_name &&
                //     createLeadData.email &&
                //     createLeadData.mobile.length === 14 &&
                //     !createLeadData.isValidEmailId &&
                //     createLeadData.loan_amount &&
                //     createLeadData.selling_price
                //       ? "btn-dark"
                //       : "btn-secondary"
                //   }`}
                // className="btn createLeadButton btn-dark"
              >
                CREATE LEAD
              </button>
            </div>
          </form>
        </div>
      </div>
      <></>
    </>
  );
};

export default CreateLead;


