import React, { useEffect, useState } from 'react'
import UploadBankStatement from './UploadBankStatement'
import { createTransactionApplication, getAllProducts, getBranchs, getBrands, getLocations, getMerchants, getProductOffering, getProducts } from '../../../action/leadjourney';
import Select from "react-select";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const LeadAccountActive = ({getLeadApplicationDetail,handleSuccEroorToastMessage,id,setApiLoader,limitId,redirectScreen,SanctionedLimit,leadBasic}) => {

    const [createLeadData, setCreateLeadData] = useState({
        product: "",
        product_price: "",
        loan_amount: "",
        MobileErrorMsg: "",
        selling_price: "",
        allProductData: [],
        allMerchantData:[],
        allBrandData:[],
        allProductOfferingData:[],
        allLocationData:[],
        allBranchData:[],
        selectedProduct: "",
        selectedMerchant:"",
        selectedBrand:"",
        selectedProductOffering:"",
        selectedLocation:"",
        selectedBranch:"",
        productId: "",
        isProductSelected: true,
        isMerchantSelected:true,
        isBrandSelected:true,
        isProductOfferingSelected:true,
        isLocationSelected:true,
        isBranchSelected:true,
        isValidSellingPrice: true,
        CreateLeadApiErr: "",
      });
      const navigate = useNavigate();

      useEffect(() => {
        const getData = async () => {
          setApiLoader(true);
          const response = await getProducts();
          setApiLoader(false);
          if (response.status === "success") {
            if(response?.data?.length > 0){
              setCreateLeadData({ ...createLeadData, allProductData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) });
            }
          } else {
            setCreateLeadData({ ...createLeadData, allProductData: [] });
          }
        };
        getData();
      }, []);
   

    const isNextButtonDisable = () => {
        return false
    }

    const handleProceedNext =  async (e) => {
        e.preventDefault();
        setCreateLeadData((prev) => {
          return { ...prev, MobileErrorMsg:"",isProductSelected:true,isBrandSelected:true,isLocationSelected:true,isBranchSelected:true,isProductOfferingSelected:true,isMerchantSelected:true,isValidSellingPrice:true,loanAmountErr:"",CreateLeadApiErr:""};
        });
        let allValueFilled = true;
   
        if (!createLeadData.selectedProduct?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isProductSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selectedBrand?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isBrandSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selectedLocation?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isLocationSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selectedBranch?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isBranchSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selectedProductOffering?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isProductOfferingSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selectedMerchant?.value) {
          // setCreateLeadData({...createLeadData,isProductSelected:false});
          setCreateLeadData((prev) => {
            return { ...prev, isMerchantSelected: false };
          });
          allValueFilled = false;
        }
        if (!createLeadData.selling_price) {
          // setCreateLeadData({...createLeadData,isValidSellingPrice:false});
          setCreateLeadData((prev) => {
            return { ...prev, isValidSellingPrice: false };
          });
    
          allValueFilled = false;
        }
        if (!createLeadData.loan_amount) {
          // setCreateLeadData({...createLeadData,loanAmountErr:"Please fill the load amount"});
          setCreateLeadData((prev) => {
            return { ...prev, loanAmountErr: "Please fill the load amount" };
          });
          allValueFilled = false;
        }
        if (createLeadData.loan_amount > createLeadData.selling_price) {
          // setCreateLeadData({...createLeadData,loanAmountErr:"Loan amount must be less than the or equal to selling price"});
          setCreateLeadData((prev) => {
            return {
              ...prev,
              loanAmountErr:
                "Loan amount must be less than the or equal to selling price",
            };
          });
          allValueFilled = false;
        }
   
    if (!allValueFilled) {
      return;
    }

    const transData = {
      // first_name: "Karan",
      // last_name: "Mehta",
      // email: "esha.gupta@eduvanz.com",
      // mobile: "7021294404",
      ...leadBasic,
      merchant_product_id: createLeadData.selectedProduct.Id,
      brand: createLeadData?.selectedBrand?.Brand_Name__c ? createLeadData.selectedBrand.Brand_Name__c : "",
      selling_price: Number(createLeadData.selling_price) ? Number(createLeadData.selling_price) :"",
      loan_amount: Number(createLeadData.loan_amount) ? Number(createLeadData.loan_amount) : "",//optional
      merchant_id: createLeadData?.selectedMerchant?.Id ?  createLeadData.selectedMerchant.Id :"",
      product_offering: createLeadData?.selectedProductOffering?.Id ? createLeadData.selectedProductOffering.Id :"",
      branch_id: createLeadData?.selectedBranch?.Id ? createLeadData.selectedBranch.Id : "",
      location_id: createLeadData?.selectedLocation?.Id ? createLeadData.selectedLocation.Id : "",
  } 
        
       setApiLoader(true);
       const res = await createTransactionApplication(transData);
       setApiLoader(false);
       if(res.status === "success"){
         const transactionId = res.data.id;
         toast.success(res.message);
         if (transactionId) {
           getLeadApplicationDetail({ transId: transactionId });
           navigate(`/lead-journey/${transactionId}`)
         } 
       }else{
        toast.error(res.message)
       }
    }

    const onlyNumbers = (e) => {
        var pattern = new RegExp(/^(?!(0))\d+$/);
        if (e.target.value !== "") {
          if (!pattern.test(e.target.value)) {
            // setCreateLeadData({...createLeadData, [e.target.name]: "" });
          } else {
            setCreateLeadData({
              ...createLeadData,
              [e.target.name]: e.target.value,
            });
          }
        } else {
          setCreateLeadData({ ...createLeadData, [e.target.name]: e.target.value });
        }
      };


      const handleSelectProduct = async(value) => {
        setCreateLeadData(prev => { return {
          ...prev,
          selectedProduct:value,
          // selling_price: value.price,
          productId: value.Id,
        }});
    
        fetchBrands(value.Id)
        fetchLocations(value.Id);
        fetchProductOffering(value.Id)
        // fetchMerchants(value.Id);
      };
    
    
      const handleSelectBrand = (value) => {
        setCreateLeadData({
          ...createLeadData,
          selectedBrand: value,
        });
        fetchLocations(createLeadData?.selectedProduct?.Id,value.Id);
        fetchBranch(value.Id)
      };
    
    
      const handleSelectProductOffering = (value) => {
        setCreateLeadData({
          ...createLeadData,
          selectedProductOffering: value,
          selling_price:value.Offering_Price__c
        });
      };
    
    
      const handleSelectLocation = (value) => {
        setCreateLeadData({
          ...createLeadData,
          selectedLocation: value,
        });
    
        fetchProductOffering(createLeadData?.selectedProduct?.Id,value.Id);
        fetchMerchants(createLeadData?.selectedProduct?.Id,value.Id);
        // fetchBranch(value.Id)
        // fetchBranch("001C300000EVMk9IAH")
      };
    
      const handleSelectMerchant = (value) => {
        setCreateLeadData({
          ...createLeadData,
          selectedMerchant: value,
        });
      };
      const handleSelectBranch = (value) => {
        setCreateLeadData({
          ...createLeadData,
          selectedBranch: value,
        });
      };
    
      const fetchBrands = async (id) => {
        setApiLoader(true);
        const response = await getBrands(id);
         setApiLoader(false)
        if (response.status === "success") {
          if(response?.data?.length > 0){
            setCreateLeadData(prev => { return { ...prev, allBrandData: response.data.map(ele => { return {...ele,label:ele.Brand_Name__c,value:ele.Brand_Name__c}}) }});
          }
        } else {
          setCreateLeadData(prev => { return { ...prev, allBrandData: [] }});
        }
      }
      const fetchLocations = async (prodId="",brandId="") => {
        setApiLoader(true);
        const data = {
            prodId,
            brandId
        }
        const response = await getLocations(data);
         setApiLoader(false)
        if (response.status === "success") {
          if(response?.data?.length > 0){
            setCreateLeadData(prev => { return { ...prev, allLocationData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
          }
        } else {
          setCreateLeadData(prev => { return { ...prev, allLocationData: [] }});
        }
      }
    
      const fetchProductOffering = async (prodId="",locId="") => {
        setApiLoader(true);
        const data = {
          prodId,//either we can pass prodId or locId or both prodId & locId
          locId,
        }
        const response = await getProductOffering(data);
         setApiLoader(false)
        if (response.status === "success") {
          if(response?.data?.length > 0){
            setCreateLeadData(prev => { return { ...prev, allProductOfferingData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
          }
        } else {
          setCreateLeadData(prev => { return { ...prev, allProductOfferingData: [] }});
        }
      }
      
      const fetchBranch= async (locId="") => {
        setApiLoader(true);
        const response = await getBranchs(locId);
         setApiLoader(false)
        if (response.status === "success") {
          if(response?.data?.length > 0){
            setCreateLeadData(prev => { return { ...prev, allBranchData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
          }
        } else {
          setCreateLeadData(prev => { return { ...prev, allBranchData: [] }});
        }
      }
      const fetchMerchants = async (prodId="",locId="") => {
        setApiLoader(true);
        const data = {
          prodId,
          locId,
        }
        const response = await getMerchants(data);
         setApiLoader(false)
        if (response.status === "success") {
          if(response?.data?.length > 0){
            setCreateLeadData(prev => { return { ...prev, allMerchantData: response.data.map(ele => { return {...ele,label:ele.Name,value:ele.Name}}) }});
          }
        } else {
          setCreateLeadData(prev => { return { ...prev, allMerchantData: [] }});
        }
      }
  return (
    <form onSubmit={(e) =>e.preventDefault() } className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
        <div className="row flex-column">
          <h5 className="col-md-12 account-active-toast mb-3 text-center fs-30 text-blue">
            Congratulations!
          </h5>
          <h5 className="col-md-12 account-active-toast text-center fs-15">
            Your account has beeen successfully Activated. You have received a limit of Rs {SanctionedLimit || SanctionedLimit === 0 ? SanctionedLimit:""}. To proceed with the loan, please fill out the information below.
          </h5>

        </div>
                <div className="row"> 

                      {/* <div className="col-md-12 form-group">
                          <h6 className="kyc-sub-head mt-3 fs-14">Bank Statement</h6>
                        </div> */}
                         <div className="col-md-12">
                <h5 className="sub-header ml-0 mb-3">
                  Product &amp; Loan Details
                </h5>
              </div>
           
              <div className="col-md-6 form-group">
                <label className="form-label">Product</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allProductData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectProduct}
                  value={createLeadData.selectedProduct}
                />

                {!createLeadData.isProductSelected && (
                  <div className="err-message">please select the product </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Brand</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allBrandData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectBrand}
                  value={createLeadData.selectedBrand}
                />

                {!createLeadData.isBrandSelected && (
                  <div className="err-message">please select the brand </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Location</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allLocationData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectLocation}
                  value={createLeadData.selectedLocation}
                />

                {!createLeadData.isLocationSelected && (
                  <div className="err-message">please select the location</div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Product Offering</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allProductOfferingData}
                  placeholder="Select any Product"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectProductOffering}
                  value={createLeadData.selectedProductOffering}
                />

                {!createLeadData.isProductOfferingSelected && (
                  <div className="err-message">please select the product offering </div>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label className="form-label">Merchant</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allMerchantData}
                  placeholder="Select any Merchant"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectMerchant}
                  value={createLeadData.selectedMerchant}
                />

                {!createLeadData.isMerchantSelected && (
                  <div className="err-message">please select the Merchant </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Branch</label>
                <Select
                  // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  classNamePrefix="professional_details_lead_select"

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "1rem",
                      backgroundColor: state.isSelected ? "#F2F3F8" : "white",
                      color: "#000000",
                      maxHeight: "300px",
                      "&:hover": {
                        backgroundColor: "#F2F3F8",
                      },
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),

                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      border: "1px solid gray",
                        borderRadius: "6px",
                        padding: '0rem 1rem',
                      // "&:hover": {
                      //   borderColor: "#3399f0",
                      // },
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                    }),
                  }}
                  options={createLeadData.allBranchData}
                  placeholder="Select any Merchant"
                  name="brand"
                  className="lead-product-select"
                  onChange={handleSelectBranch}
                  value={createLeadData.selectedBranch}
                />

                {!createLeadData.isBranchSelected && (
                  <div className="err-message">please select the Branch </div>
                )}
              </div>
             
              <div className="col-md-6 form-group">
                <label className="form-label">Product Price</label>
                <input
                  type="text"
                  className={!createLeadData.isValidSellingPrice ? "err": ""}
                  placeholder="Enter Product Price"
                  // value={createLeadData.product_price ? createLeadData.product_price : ''}
                  disabled={true}
                  value={
                    createLeadData.selling_price ||
                    createLeadData.selling_price === 0
                      ? createLeadData.selling_price
                      : ""
                  }
                  onChange={onlyNumbers}
                  name="selling_price"
                  readOnly
                  //   readOnly={true}
                />
                {!createLeadData.isValidSellingPrice && (
                  <div className="err-message">
                    please select the product with selling price
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Loan Amount</label>
                <input
                  type="text"
                  className={createLeadData.loanAmountErr ? "err":""}
                  placeholder="Enter Loan Amount"
                  value={
                    createLeadData.loan_amount ? createLeadData.loan_amount : ""
                  }
                  onChange={onlyNumbers}
                  name="loan_amount"
                />
                {createLeadData.loanAmountErr && (
                                  <div className='err-message'>
                                    {createLeadData.loanAmountErr}
                                  </div>
                                )}
              </div>

                   
                    </div>
            
            
            <div className="row">
                      <div className="col-md-12 mb-2 d-flex justify-content-end">
              <button
                className="lead-blue-btn"
                disabled={isNextButtonDisable()}
                onClick={handleProceedNext}
              >
                Proceed
              </button>
            </div>

            </div>
       
        </form>
  )
}

export default LeadAccountActive