import React from 'react'
import successLogo from '../../images/complete.png'

const SendCaseCreditModal =({closeModal,handleSendCaseToCredit,continueWithCurrentLimit,limit}) => {
    return (
      <div className='lead-limit-popup modal'>
         <div className="lead-limit-popup-content">
         <span onClick={closeModal} class="llp-close-btn">&times;</span>
             {limit ?
              <div className='llp-top-box'>
              <img src={successLogo} alt='success' width={50} height={50}/>
              <h2 className='text-blue'>Congratulations!</h2>
              <h3 className='text-center'>You have successfully received a limit of ₹ <span className='fw-600'>{limit ? limit.toLocaleString('en-IN'):0}</span></h3>
              </div>:
             <div className='llp-top-box'>
             <h2>Confirmation</h2>
             <h3 className='text-center'>You have received a limit of ₹ <span className='fw-600'>{limit ? limit.toLocaleString('en-IN'):0}</span></h3>
             <h4 className='text-center'>Credit user will take further action</h4>
             
              </div>}
             <div className='llp-bottom-box'>
               {limit ? <button onClick={continueWithCurrentLimit} className='lead-blue-btn'>CONTINUE WITH CURRENT LIMIT</button>:""}
               <button onClick={handleSendCaseToCredit} className='lead-blue-btn'>SEND CASE TO CREDIT</button>
             </div>
         </div>
      </div>
    )
  }
  

export default SendCaseCreditModal
