import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { hardpullApi } from '../../../action/leadjourney';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import moment from 'moment';



const LeadBasicDetails = ({ redirectScreen,handleQdeFormDetail,basicDetails,getLeadApplicationDetail,panNo,AccountId,handleSuccEroorToastMessage,apiLoader,setApiLoader}) => {
  const [leadBasicDetails,setLeadBasicDetail] = useState({
    dob:"",
    pincode:"",
    gender:"Male",
    profileSuccessMsg:"",
    isProfileSuccess:"",
    isDob:false,
 })
  
    useEffect(() => {
      const {dob,postal_code,gender} = basicDetails || {};
      let formattedDate = "";
      if(dob){
        formattedDate = moment(new Date(dob)).format('YYYY-MM-DD');
      }
      setLeadBasicDetail({...leadBasicDetails,
        dob:formattedDate ? formattedDate : "",
        pincode:postal_code,
        gender
      })
    },[])
    
  

   const handleGender = (value) => {
      setLeadBasicDetail({...leadBasicDetails,gender:value})
    }

  const onlyPincode = (e) => {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (e.target.value !== "") {
        if (!pattern.test(e.target.value)) {
          setLeadBasicDetail({...leadBasicDetails,
            errpincode: "Please enter correct Pincode",
            isValidpincode: true,
          });
        } else if (e.target.value.length < 6) {
          setLeadBasicDetail({...leadBasicDetails,
            [e.target.name]: e.target.value,
            errpincode: "Please enter correct Pincode",
            isValidpincode: true,
          });
        } else {
          setLeadBasicDetail({...leadBasicDetails,
            [e.target.name]: e.target.value,
            errpincode: " ",
            isValidpincode: false,
          });
        }
      } else {
        setLeadBasicDetail({...leadBasicDetails,
          [e.target.name]: e.target.value,
          errpincode: " ",
          isValidpincode: false,
        });
      }
    };


   const handleSelectDate  = (date,year) => { 
      if (moment(date.$d).format('YYYY-MM-DD') !== 'Invalid date') {
        setLeadBasicDetail(prev => {return {...prev,
            dob: moment(date.$d).format('YYYY-MM-DD')}})
        }
        // if(year){
        //   setLeadBasicDetail({...leadBasicDetails,isDob:!leadBasicDetails.isDob})
        // }
    }

   const updateProfile = async(e) => {
      e.preventDefault();
      const {dob,pincode,gender} = leadBasicDetails;
      // dispatch(openSuccessModel());
      const data = {
          account_id:AccountId,
          pan:panNo,
          dob,
          gender,
          pincode:pincode,
      }
      setApiLoader(true);
     const response = await hardpullApi(data);
     setApiLoader(false)
        if(response.status === "success"){
       
          handleSuccEroorToastMessage({succ:response?.message})
          getLeadApplicationDetail();
        }else{
             setLeadBasicDetail({...leadBasicDetails,isValidPan:false,errorMsg:response?.message || "Enter valid pan number!",})
             handleSuccEroorToastMessage({err:response?.message || "Enter valid pan number!"})
        }
      // handleQdeFormDetail({basicDetails:data})
      // redirectScreen({stage:2})
    }

   const handlePreviousClick = () => {
      redirectScreen({stage:0})
    }

   const isNextButtonDisable = () => {
      const {dob,gender,pincode,isValidpincode} = leadBasicDetails
      return  dob && gender && pincode && !isValidpincode ? false : true;
 
    }

    return (
      <form onSubmit={(e) => e.preventDefault()} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
          <div className="row">
                <h5 className="main-header mb-3">Basic Details</h5>
              </div>   
            <>
           
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label htmlFor="" className="form-label">
                        Date of Birth
                      </label>
                    
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
     
          <DatePicker
            sx={{ width: "100%" }}
            className="lead-dob-picker"
            disableFuture={true}
            slotProps={{
              textField: {
                  readOnly: true,
              },
          }}
          format='DD/MM/YYYY'
          value={leadBasicDetails.dob ? dayjs(leadBasicDetails.dob):undefined}
          onChange={handleSelectDate}
          />
           
      
    </LocalizationProvider>


                    </div>
                    <div
                      className="col-sm-6 form-group "
                    >
                      <label htmlFor="" className="form-label">
                        Pincode
                      </label>
                      <input
                        type="text"
                        className="form-control wc_input lead-basic-pin-ip"
                        placeholder="000 000"
                        maxLength={"6"}
                        name="pincode"
                        value={leadBasicDetails.pincode ? leadBasicDetails.pincode : ""}
                        onChange={onlyPincode}
                      />
                      {leadBasicDetails.isValidpincode == true && <div className="err-message">{leadBasicDetails.errpincode}</div>  
                      }
                    </div>

                    

                     <div className='col-md-12 d-flex flex-wrap mb-3'>
                     <div  className="form-label w-100">
                        Gender
                      </div>
                     <div className='lead-radio-item'>
                      <input 
                      type='radio'
                      onChange={() => handleGender("Male")}
                      checked={leadBasicDetails.gender == "Male" ? true :false}
                      />
                      <label className='ml-2'>Male</label>
                    </div>
                     <div className='lead-radio-item ml-4'>
                      <input 
                      type='radio'
                      onChange={() => handleGender("Female")}
                      checked={leadBasicDetails.gender == "Female" ? true :false}
                      />
                      <label className='ml-2'>Female</label>
                    </div>
                     <div className='lead-radio-item ml-4'>
                      <input 
                      type='radio'
                      onChange={() => handleGender("Others")}
                      checked={leadBasicDetails.gender == "Others" ? true :false}
                      />
                      <label className='ml-2'>Others</label>
                    </div>
                    </div>
                  </div>
              
              
              <div className="row error-msg">
                {leadBasicDetails.profileSuccessMsg != "" && leadBasicDetails.isProfileSuccess === 0 ? (
                  <span>{leadBasicDetails.profileSuccessMsg}</span>
                ) : (
                  ""
                )}
              </div>
          
            <div className="row">
                      <div className="col-md-12 mb-2 d-flex justify-content-end">
              <button
                className="lead-blue-btn"
                disabled={isNextButtonDisable()}
                onClick={updateProfile}
              >
                SAVE & NEXT
              </button>
            </div>

            </div>
            </>
        
             </form>
            )
}

export default LeadBasicDetails