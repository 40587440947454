import React, { useEffect, useState } from 'react'
import { sendVkycLink } from '../../../action/leadjourney';


// let interval;
const LeadVkycRedirect = ({id,setApiLoader,limitId,redirectScreen,getLeadApplicationDetail,handleSuccEroorToastMessage,SanctionedLimit,AccountId}) => {

const [timer,setTimer] = useState("");
const [vkycUrl,setVkycUrl] = useState("");

useEffect(() => {
   const getlink = async() => {
    const response = await sendVkycLink(AccountId,id);
    setApiLoader(false)
    if (response.status === "success") {
        setVkycUrl(response.url)
        // setTimer(10);
    // interval = setInterval(() => {
    //      setTimer(prev => prev === 0 ? 0 : prev-1)
    //  }, 1000);
    } else {
      // handleSuccEroorToastMessage({ err: response.message })
    }
   }
   getlink();

// // return () => {
// //    clearInterval(interval);
// // }
},[])

// useEffect(() => {
//     if(timer === 0){
//      clearInterval(interval);
//      window.location.href = vkycUrl;
//     }
// },[timer]);

const handleRedirectToVkyc = () => {
  if(vkycUrl){
    window.location.href = vkycUrl;
  } else{
    handleSuccEroorToastMessage({err:"Failed to fetch the vkyc url"})
  }
}

const handleSkipButton = () => {
  // alert("Skip clicked")
  getLeadApplicationDetail({vkycdone:true})
}

  return (
    <form onSubmit={(e) => { e.preventDefault() }} className="p-4 w-80 mx-auto white-background mt-5 brad-10 lead-journey-mb">
         <div className="row mb-3">
          <h5 className="col-md-12 main-header">Video Verification</h5>
        </div>
    <div className="row ">
      {/* <div className="col-md-12 font-family-medium fznormal mb-3">Redirecting to  VKYC in 00:{timer <= 9 ? `0${timer}` : timer} seconds</div> */}
      <div className="col-md-12 mb-2 d-flex justify-content-end">
              <button
                className="lead-blue-btn"
                // disabled={isNextButtonDisable()}
                onClick={handleSkipButton}
              >
                SKIP FOR NOW
              </button>
              <button
                className="lead-blue-btn ml-2"
                // disabled={isNextButtonDisable()}
                onClick={handleRedirectToVkyc}
              >
                CONTINUE TO VKYC
              </button>
            </div>
    </div>
  </form>
  )
}

export default LeadVkycRedirect
